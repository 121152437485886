import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { SubmissionError } from 'redux-form';

import * as accountService from '../../services/session/account';
import FormAccount from './FormAccount';

import { Row, Col } from 'reactstrap';

import PropTypes from 'prop-types';

const submit = async values => {
	try {
		await accountService.update(values);
		toast.success('Enregistrement réussi');
	} catch (error) {
		throw new SubmissionError(error);
	}
};

const Account = ({ user }) => (
	<div className="animated fadeIn">
		<Row>
			<Col xs="12" sm="6">
				<FormAccount onSubmit={submit} initialValues={user} />
			</Col>
		</Row>
	</div>
);
Account.propTypes = {
	user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({ user: state.user });

export default connect(mapStateToProps)(Account);
