import React from "react";

export default ({ color = "default", children }) => {
	const getColor = () => {
		switch (color) {
			case "default":
				return "orange";
			case "success":
				return "orange";
			case "danger":
				return "red";
			case "info":
				return "black";
			case "warning":
				return "grey";
		}
	};

	return (
		<span
			className={`inline-block bg-${getColor()}-100 text-${getColor()}-700 px-2 rounded relative`}
		>
			{children}
		</span>
	);
};
