import React from "react";
import classNames from "classnames";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import Label from "./Label";

export default ({
	valueField,
	labelField,
	className,
	required = true,
	help,
	loadOptions,
	input,
	label,
	type,
	multiple = false,
	inline,
	meta: { touched, error },
}) => {
	if (!valueField) valueField = "value";
	if (!labelField) labelField = "name";

	return (
		<div className={classNames({ flex: inline })}>
			<Label className={classNames({ "w-1/4": inline })}>{label}</Label>

			<div className={classNames({ "w-3/4": inline }, "flex")}>
				<div className="flex-1">
					<AsyncSelect
						isMulti={multiple}
						classNamePrefix="select"
						className="mb-2"
						name={input.name}
						cacheOptions
						value={input.value}
						defaultOptions
						onBlur={() => {
							if (multiple) {
								input.onBlur([...input.value]);
							}
						}}
						onChange={(value) => {
							input.onChange(value);
						}}
						loadOptions={loadOptions}
					/>
				</div>
			</div>
			{touched && error && (
				<div className="text-xs text-red-600">{error}</div>
			)}
			{help && <div className="text-xs ">{help}</div>}
		</div>
	);
};
