import React, {Component} from 'react';
import {Modal, ModalHeader} from 'reactstrap';
import {SubmissionError} from 'redux-form';
import {toast} from 'react-toastify';

import FormMessagePopup from '../Form'
import * as contractMessageService from "/wh/services/contract-message";

class TechMessagePopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            data: {},
            loaded: true
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    handleOpen(id = null) {
        this.setState({
            open: true
        });

        if (id) {
            this.setState({
                loaded: false
            });


        }
    }

    handleClose() {
        this.setState({
            open: false,
            data: {}
        });
    }

    sendMessage = (message) => {
        const {data} = this.props;
        const msg = {
            'message':{message},
            'id':data.id,
        }


        var result = contractMessageService.sendTech(msg);
    };


    onSubmit = async data => {
        try {
            this.sendMessage(data);
            toast.success('Votre Message a été envoyé');
            this.handleClose();


        } catch (error) {
            throw new SubmissionError(error);
        }
    };

    render() {
        const {className} = this.props;
        const {loaded} = this.state;

        return (

            <Modal isOpen={this.state.open} className={className} fade={false}>

                <ModalHeader toggle={() => this.handleClose()}>Envoyer un message au Technicien</ModalHeader>
                <FormMessagePopup  onSubmit={this.onSubmit} handleClose={this.handleClose}/>



            </Modal>

        );

    }
}

export default TechMessagePopup
