import React from "react";
import { Button } from "/wh/components/WhComponent";
import CreateUserForm from "/wh/modules/Customers/create/CreateUserForm";

const DisplayList = ({ list, rows, action, className }) => (
	<div className={className}>
		{list.map((l, index) => (
			<div className="flex mb-2 shadow border p-2" key={index}>
				<div className="flex-1">
					{rows.map((row, idx) => (
						<div key={idx}>
							{row.map((element) => {
								if (l.hasOwnProperty(element))
									return (
										<div>
											<div>{l[element]}</div>
										</div>
									);
							})}
						</div>
					))}
				</div>
				{action && (
					<div key={`CreateUserFormIndex${  index}`}>
						<CreateUserForm callback={action.onClick} user={l} isUpdate={true} />
						{/*<Button outline onClick={action.onClick}>*/}
						{/*	{action.name}*/}
						{/*</Button>*/}
					</div>
				)}
			</div>
		))}
	</div>
);

export default DisplayList;
