import { createTypes } from 'redux-friends';
// -------------reducer key ---------------- //
export const CONTRACTS_RK = 'contracts';
// ---------------- actions Types  ----------------- //
export const SET_CONTRACTS = 'SET_CONTRACTS';
export const REMOVE_CONTRACT = 'REMOVE_CONTRACT';
export const UPDATE_CONTRACT = 'UPDATE_CONTRACT';
export const REPLACE_CONTRACT = 'REPLACE_CONTRACT';
export const ADD_CONTRACT = 'ADD_CONTRACT';
export const SET_CONTRACT = 'SET_CONTRACT';
// ------------ keys -------------------- //
export const DATA = 'data';
export const PAGES = 'pages';
export const CURRENT_CONTRACT = 'currentContract';
// --------- types Array -------------- //
export const MyTypesArray = [
	SET_CONTRACTS,
	REMOVE_CONTRACT,
	UPDATE_CONTRACT,
	REPLACE_CONTRACT,
	ADD_CONTRACT,
	SET_CONTRACT,
];
// -------------- types ---------------- //
export const TYPES = createTypes(CONTRACTS_RK, MyTypesArray);
