import React from "react";
import { formatDate } from "/wh/utils/momentUtils";
import useModal from "/wh/common/modalHook/customModalHook";
import { Field } from "redux-form";
import * as validator from "/wh/components/Form/wh-validator";
import {
	Card,
	Modal,
	FormWrapper,
	CardHeader,
	FieldDatePicker,
	FieldTxt,
	FormActions,
	CardTitle,
	Value,
	Label,
	Button,
	H3,
} from "/wh/components/WhComponent/index";

const SAV = ({ sav, onUpdate }) => {
	const { isShowing, toggle } = useModal();

	const onSubmit = (data) => {
		if (sav) {
			onUpdate({
				sav: {
					["@id"]: sav["@id"],
					id: sav.id,
					...data,
				},
			});
		} else {
			onUpdate({
				sav: data,
			});
		}

		toggle();
	};

	const onCancel = (event) => {
		event.preventDefault();

		toggle();
	};

	return (
		<Card small>
			<CardHeader>
				<CardTitle>
					<H3>Service après vente</H3>
					<Button outline onClick={toggle}>
						<i className="fa fa-edit" /> Modifier
					</Button>
				</CardTitle>
			</CardHeader>

			<div>
				<div className="flex">
					<Label className="w-1/3">
						Date d'activation du contrat d'entretien :{" "}
					</Label>
					{sav && (
						<Value className="w-2/3">{` ${formatDate(
							sav.date,
							"/"
						)}`}</Value>
					)}
				</div>
				<div className="flex">
					<Label className="w-1/3">
						N° de contrat d'entretien :{" "}
					</Label>
					{sav && (
						<Value className="w-2/3">{sav.entretienNumber}</Value>
					)}
				</div>
				<div className="flex">
					<Label className="w-1/3">N° RUM : </Label>
					{sav && <Value className="w-2/3">{sav.rum}</Value>}
				</div>
			</div>

			<Modal
				visible={isShowing}
				toggle={toggle}
				onClose={toggle}
				title="Service après vente"
			>
				<FormWrapper
					onSubmit={onSubmit}
					form={"submitCommercial"}
					initialValues={sav}
				>
					<Field
						inline
						label="Date d'activation du contrat d'entretien : "
						name="date"
						component={FieldDatePicker}
						validate={[validator.required]}
					/>

					<Field
						inline
						name={"entretienNumber"}
						component={FieldTxt}
						label={"N° de contrat d'entretien : "}
					/>
					<Field
						inline
						name={"rum"}
						component={FieldTxt}
						label={"N° RUM : "}
					/>

					<FormActions>
						<Button onClick={onCancel} color="transparent">
							Annuler
						</Button>
						<Button type="submit">Valider</Button>
					</FormActions>
				</FormWrapper>
			</Modal>
		</Card>
	);
};

export default SAV;
