import React, { useState, useEffect } from "react";
import { APIENUM } from "/wh/services/ApiEnum";
import CustomRadio from "/wh/components/CustomRadio/CustomRadio";
import ApiService from "/wh/services/apiSearch/apiSearch";
import {
	Button,
	Page,
	Card,
	H3,
	PageHeader,
	PageTitle,
	H1,
	Text,
	PageActions,
} from "/wh/components/WhComponent/index";

const QuestionnaireView = ({ match, history }) => {
	const [questions, setQuestions] = useState([]);
	const [questionnaire, setQuestionnaire] = useState({});

	useEffect(() => {
		const qcmService = new ApiService(`/api/${APIENUM.QCM}`);
		qcmService.view(match.params.id).then((data) => {
			setQuestionnaire(data);
			if (data.questionnaire === "?") setQuestions([]);
			else {
				const formatedData = JSON.parse(data.questionnaire);
				setQuestions(formatedData);
			}
		});
	}, []);

	const goBack = () => {
		history.push("/questionnaires");
	};
	const getType = (type, options, withOtherField) => {
		if (type === 0)
			return (
				<div>
					<CustomRadio
						radioBtns={[{ value: "Oui" }, { value: "Non" }]}
					/>
				</div>
			);
		if (type === 1)
			return (
				<div className={"input"}>
					<span>Texte</span>
				</div>
			);
		if (type === 3)
			return (
				<div className={"input"}>
					<span>Valeur Numérique</span>
				</div>
			);
		if (type === 2)
			return (
				<div>
					<CustomRadio
						radioBtns={
							withOtherField
								? options.concat({ value: "Autre" })
								: options
						}
					/>
				</div>
			);
		if (type === 4)
			return (
				<div className={"input"}>
					<span>Commentaire</span>
				</div>
			);
		if (type === 5)
			return (
				<div className={"input"}>
					<span>Séléction de date</span>
				</div>
			);
	};

	console.log(questionnaire);
	return (
		<Page>
			<PageHeader>
				<PageTitle>
					<H1>{questionnaire.name}</H1>
					<PageActions>
						<Button to="./" outline>
							<i className="fa fa-edit" /> Modifier le
							questionnaire
						</Button>
						<Button onClick={() => goBack()} outline>
							<i className="fa fa-chevron-left" /> Retour à la
							liste
						</Button>
					</PageActions>
				</PageTitle>

				{questionnaire && questionnaire.interventionType && (
					<Text>
						Type d'intervention :{" "}
						<span className="font-bold">
							{questionnaire.interventionType.label}
						</span>
					</Text>
				)}
				{questionnaire && questionnaire.product && (
					<Text>
						Produit :{" "}
						<span className="font-bold">
							{questionnaire.product.name}
						</span>
					</Text>
				)}
			</PageHeader>

			{questions.map((q, idx) => {
				return (
					<Card key={`display-question-${idx}`}>
						<div className={"question-title"}>
							<H3>{q.text}</H3>
						</div>
						<div>
							{getType(
								q.type,
								q.select ? q.select.options : null,
								q.select ? q.select.withOtherField : null
							)}
						</div>
					</Card>
				);
			})}
		</Page>
	);
};

export default QuestionnaireView;
