import {
	SET_CONTRACTS,
	TYPES,
	DATA,
	PAGES,
	REMOVE_CONTRACT,
	REPLACE_CONTRACT,
	SET_CONTRACT,
	CURRENT_CONTRACT,
} from './constants';
import { createReducer } from 'redux-friends';

const defaultState = { [DATA]: [], [PAGES]: 1, [CURRENT_CONTRACT]: null };

const behaviors = {
	[TYPES[SET_CONTRACTS]]: (state, { payload }) => ({
		...state,
		[DATA]: payload[DATA],
		[PAGES]: payload[PAGES],
	}),
	[TYPES[REMOVE_CONTRACT]]: (state, { payload }) => ({
		...state,
		[DATA]: state[DATA].filter(x => x.id !== payload),
	}),
	[TYPES[REPLACE_CONTRACT]]: (state, { payload }) => ({
		...state,
		[DATA]: state[DATA].map(x => (x.id === payload.id ? payload : x)),
	}),
	[TYPES[SET_CONTRACT]]: (state, { payload }) => ({
		...state,
		[CURRENT_CONTRACT]: payload,
	}),
};

export default createReducer(behaviors, defaultState);
