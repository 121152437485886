import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import * as prospectService from "/wh/services/customer";
import UserHeader from "/wh/common/UserHeader/UserHeader";
import Loading from "@whatsonweb/loading";
import Intervention from "../Intervention/Contract";
import { APIENUM } from "/wh/services/ApiEnum";
import InterventionList from "./InterventionList";
import ContractList from "./ContractList";

import { Page, Button } from "../../components/WhComponent/index";

const CustomersContractList = ({ match, history }) => {
	const header = [
		[
			{ key: "lastname", label: "Nom" },
			{ key: "firstname", label: "Prénom" },
		],
		[
			{ key: "phone", label: "T. fixe" },
			{ key: "mobile", label: "Portable" },
		],
		[
			{ key: "street", label: "Adresse" },
			{ key: "zipcode", label: "C. Postal" },
		],
		[
			{ key: "city", label: "Ville" },
			{ key: "", label: "" },
		],
		[
			{ key: "extRef", label: "N° client" },
			{ key: "email", label: "Email" },
		],
	];

	const [isLoading, setIsLoading] = useState(true);
	const [currentUser, setCurrentUser] = useState(null);

	const loadUser = () => {
		setIsLoading(true);
		const customerId = match.params.id;
		prospectService
			.search({ filtered: [{ id: "id", value: customerId }] })
			.then((data) => {
				const user = data["hydra:member"];
				if (user.length > 0) setCurrentUser(user[0]);
			});
		setIsLoading(false);
	};

	console.log({ currentUser });

	useEffect(loadUser, []);

	const getColor = (url) => {
		if (location.hash == `#${url}`) return "success";

		return "white";
	};

	return (
		<Page>
			{isLoading ? (
				<Loading />
			) : (
				<div className="flex">
					<div className="w-1/4">
						<UserHeader
							user={currentUser}
							rows={header}
							loadUser={loadUser}
						/>
						<div className="flex flex-col">
							<Button
								block
								color={getColor(
									`/customer_contracts/${match.params.id}/interventions`
								)}
								className="mb-2"
								to={`/customer_contracts/${match.params.id}/interventions`}
							>
								Toutes ses interventions
							</Button>
							<Button
								block
								color={getColor(
									`/customer_contracts/${match.params.id}/contracts`
								)}
								className="mb-2"
								to={`/customer_contracts/${match.params.id}/contracts`}
							>
								Tous ses contrats
							</Button>
						</div>
					</div>
					<div className="w-3/4 ml-6">
						<Route
							path={`${match.path}/interventions`}
							exact
							name="interventions"
							component={(props) => (
								<InterventionList
									{...props}
									match={match}
									history={history}
									endPoint={APIENUM.INTERVENTION}
									filter={[
										{
											id: "contract.customer.id",
											value: match.params.id,
										},
									]}
								/>
							)}
						/>

						{/* <Route
							path={`${match.path}/intervention/:interventionId`}
							component={() => {
								return "Test intervention";
							}}
						/> */}

						<Route
							path={`${match.path}/contracts/:bdcId`}
							name="Intervention"
							component={(props) => <Intervention {...props} />}
						/>
						<Route
							path={`${match.path}/contracts`}
							exact
							name="commandes"
							component={(props) => (
								<ContractList
									id={match.params.id}
									match={match}
								/>
							)}
						/>
					</div>
				</div>
			)}
		</Page>
	);
};

export default CustomersContractList;
