import React from "react";
import {
	Form,
	Alert,
	FieldTxt,
	FieldSelect,
	FieldSelectAsync,
	Button,
} from "/wh/components/WhComponent/index";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import * as validator from "/wh/components/Form/wh-validator";

import * as productService from "../../../services/product";
import { useMemo } from "react";

let FormQuestionnaire = ({
	error,
	handleSubmit,
	types,
	submitting,
	toggle,
	type,
}) => {
	const productValidation = useMemo(() => {
		return type !== "/api/intervention_types/1" ? [] : [validator.required];
	}, [type]);

	return (
		<Form
			onSubmit={handleSubmit}
			className="form-horizontal form-questionnaire"
		>
			{error && <Alert color="danger">{error}</Alert>}

			<Field
				label="Nom du questionnaire"
				name="name"
				className="basic-single"
				classNamePrefix="select"
				component={FieldTxt}
				validate={[validator.required]}
				type="text"
			/>

			<Field
				label="Renseigner le type d'intervention"
				name="type"
				component={FieldSelect}
				options={types}
				validate={[validator.required]}
				type="text"
			/>

			<Field
				label="Sélection produit"
				name="product"
				component={FieldSelectAsync}
				options={types}
				validate={productValidation}
				loadOptions={(inputValue, callback) => {
					productService
						.search({
							filtered: [{ id: "name", value: inputValue }],
						})
						.then((result) =>
							callback(
								result["hydra:member"].map((item) => ({
									label: item.name,
									value: item["@id"],
								}))
							)
						);
				}}
			/>

			<div className="flex justify-center mt-5">
				<button className="btn px-2" onClick={toggle}>
					Annuler
				</button>
				<Button loading={submitting} type={"submit"}>
					Valider
				</Button>
			</div>
		</Form>
	);
};

FormQuestionnaire = reduxForm({
	form: "questionnaire_form",
})(FormQuestionnaire);

const selector = formValueSelector("questionnaire_form");

FormQuestionnaire = connect((state) => {
	// can select values individually
	const type = selector(state, "type");

	return {
		type,
	};
})(FormQuestionnaire);

export default FormQuestionnaire;
