import React, { Component, createRef } from "react";
import { SubmissionError } from "redux-form";
import { toast } from "react-toastify";
import Loading from "@whatsonweb/loading";
import { Card, CardHeader, Button } from "reactstrap";
import PropTypes from "prop-types";

import * as contractService from "/wh/services/contract";
import CreateEventPopup from "../../../CreateEventPopup";
import EditForm from "./Form";

class Installation extends Component {
	state = { loaded: true };
	eventPopup = createRef();

	submit = async data => {
		try {
			await contractService.update(data);
			toast.success("Enregistrement réussi");
		} catch (error) {
			throw new SubmissionError(error);
		}
	};

	render() {
		const { loaded } = this.state;
		return loaded ? (
			<Card>
				{/* TODO: fill proper status */}
				<CreateEventPopup
					ref={this.eventPopup}
					type="installation"
					status={[]}
				/>
				<CardHeader>
					<i className="fa fa-wrench" /> Installation
					<div className="card-actions">
						<Button
							onClick={() =>
								this.eventPopup.current
									// .getWrappedInstance()
									.handleOpen(this.props.data)
							}
						>
							<i className="fa fa-plus" /> Ajouter un évenement
						</Button>
					</div>
				</CardHeader>

				<EditForm
					onSubmit={this.submit}
					initialValues={this.props.data}
				/>
			</Card>
		) : (
			<Loading />
		);
	}
}

Installation.propTypes = {
	data: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
};

export default Installation;
