import React, { Component } from "react";
import Loading from "@whatsonweb/loading";
import { Switch, Route, Redirect } from "react-router-dom";
import { toast } from "react-toastify";

import {
	Card,
	CardBody,
	CardTitle,
	CardSubtitle,
	Jumbotron,
	ListGroup,
	ListGroupItem,
} from "reactstrap";

import * as memberService from "../../services/member";

class Member extends Component {
	state = {
		loaded: false,
		data: {},
	};

	componentDidMount() {
		this.load();
	}

	load = () => {
		memberService.view(this.props.match.params.id).then((data) => {
			this.setState({
				data,
				loaded: true,
			});
		});
	};

	disabled = () => {
		memberService
			.disabled(this.state.data.id)
			.then((data) => {
				this.setState({ data });
				if (!data.enabled) toast.success("Membre désactivé");
				else toast.success("Membre activé");
			})
			.catch((error) => {
				this.setState({ loaded: true });

				toast.error("Une erreur est survenue");
			});
	};

	sendPassword = () => {
		if (
			confirm(
				"Etes vous sûre de vouloir envoyer un nouveau mot de passe ? "
			)
		) {
			this.setState({ loaded: false });

			memberService
				.sendPassword(this.state.data.id)

				.then(() => {
					this.setState({ loaded: true });

					toast.success("Un nouveau mot de passe a été envoyé");
				})
				.catch(() => {
					this.setState({ loaded: true });

					toast.error("Une erreur est survenue");
				});
		}
	};

	render() {
		const { history, match } = this.props;

		if (!this.state.loaded) return <Loading />;

		return (
			<div className="animated fadeIn">
				<Jumbotron>
					<h1 className="display-4">{this.state.data.name}</h1>
				</Jumbotron>

				<div className="row">
					<div className="col-md-2">
						<Card>
							<CardBody>
								<CardTitle style={{ textAlign: "center" }}>
									<i
										className="fa fa-user"
										style={{
											fontSize: "40px",
											display: "block",
										}}
									/>

									{this.state.data.name}
								</CardTitle>
								<CardSubtitle>
									{this.state.data.job}
								</CardSubtitle>

								<hr />

								<p className="text-muted">
									<i className="fa fa-mobile fa-fw" />{" "}
									{this.state.data.mobile}
								</p>
								<p className="text-muted">
									<i className="fa fa-envelope fa-fw" />{" "}
									{this.state.data.email}
								</p>
								<p className="text-muted">
									<i className="fa fa-lock fa-fw" />{" "}
									{this.state.data.roles.join(", ")}
								</p>
							</CardBody>

							<ListGroup>
								<ListGroupItem tag="a" href="#" active>
									<i className="fa fa-edit fa-fw" /> Editer
								</ListGroupItem>
								<ListGroupItem
									tag="button"
									onClick={() => this.sendPassword()}
								>
									<i className="fa fa-send fa-fw" /> Envoyer
									un accès
								</ListGroupItem>
								{this.state.data.enabled && (
									<ListGroupItem
										tag="button"
										onClick={() => this.disabled()}
										color="success"
									>
										<i className="fa fa-thumbs-down fa-fw" />{" "}
										Désactiver
									</ListGroupItem>
								)}
								{!this.state.data.enabled && (
									<ListGroupItem
										tag="button"
										onClick={() => this.disabled()}
										color="danger"
									>
										<i className="fa fa-thumbs-up fa-fw" />{" "}
										Activer
									</ListGroupItem>
								)}
							</ListGroup>
						</Card>
					</div>
					<div className="col-md-10">
						<Switch>
							<Route
								path={`${match.url}/edit`}
								name="Edit"
								component={() => (
									<Edit
										data={this.state.data}
										{...this.props}
									/>
								)}
							/>
							<Redirect
								from={`${match.url}`}
								to={`${match.url}/edit`}
							/>
						</Switch>
					</div>
				</div>
			</div>
		);
	}
}

export default Member;
