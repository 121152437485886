import React, { useState, useMemo } from 'react'
import { View, Text, TextInput, StyleSheet } from 'react-native-web'
import { Input, ButtonGroup, Button } from 'reactstrap';
import DatePicker from '../../components/Form/DatePicker'
import ImageUploader from '../ImageUploader';
import moment from 'moment'
import { toast } from 'react-toastify';

const styles = StyleSheet.create({
  scrollContainer: {
    height: 730
  },
  questionContainer: {
    padding: 10
  },
  fieldContainer: {
    width: '30%'
  },
  questionTextContainer: {
    width: '50%',
    fontSize: 16,
    padding: 5
  },
  radioGroupLabel: {
    fontSize: 16
  },
  oneLineRow: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  multiLineRow: {
    flexDirection: 'column'
  },
  finishButtonContainer: {
    width: '100%',
    alignItems: 'center',
    paddingTop: 20
  },
  finishButton: {
    width: 200
  },
  closeButton: {
    backgroundColor: 'grey',
    width: 200
  },
  nextButtonContainer: {
    width: 200,
    paddingTop: 50,
    alignSelf: 'center'
  },
  input: {
    width: '100%',
    textAlign: 'center'
  }
})

const otherFieldValue = 'Autre'

export const QuestionType = {
  BOOLEAN: 0,
  TEXT: 1,
  SELECT: 2,
  NUMERIC: 3,
  IMAGE: 4,
  DATE: 5
}

const Survey = ({
  questions,
  defaultAnswers,
  onSave
}) => {

  const formatDefaultAnswers = () => {
    const result = {}

    defaultAnswers.forEach(defaultAnswer => {
      result[defaultAnswer.idx] = defaultAnswer.value
    })

    return result
  }

  const formatCustomAnswers = () => {
    const result = {}

    defaultAnswers.forEach(defaultAnswer => {
      if (defaultAnswer.value === otherFieldValue) {
        result[defaultAnswer.idx] = defaultAnswer.otherValue
      }
    })

    return result
  }

  const [answers, setAnswers] = useState(formatDefaultAnswers())

  // Answers saved when "Autre" is selected in a select type question
  const [customAnswers, setCustomAnswers] = useState(formatCustomAnswers())

  // If the survey has been completed = true
  const [surveyComplete, setSurveyComplete] = useState(false)

  // If the survey has been modified since open
  const [hasBeenModified, setHasBeenModified] = useState(false)

  const findQuestionByIdx = (idx) =>
    questions.find(({ idx: questionIdx }) => questionIdx === idx)

  const deletesCustomAnswer = (idx) => {
    // Deletes the custom answer to avoid persistence
    if (customAnswers[idx.toString()] !== undefined) {
      const newCustomAnswers = { ...customAnswers }

      delete customAnswers[idx.toString()]

      setCustomAnswers(newCustomAnswers)
    }
  }

  // Save answers in state
  const setAnswer = (idx, answer) => {
    if (!hasBeenModified) {
      setHasBeenModified(true)
    }

    setAnswers({
      ...answers,
      [idx.toString()]: answer
    })

    deletesCustomAnswer(idx)
  }

  // Remove answer from state
  const removeAnswer = (idx) => {
    const newAnswers = { ...answers }

    delete newAnswers[idx.toString()]

    setAnswers(newAnswers)
    deletesCustomAnswer(idx)
  }

  // Make up an array of question idxs which must be render
  const fieldIdxsToRender = useMemo(() => {
    const result = []
    let isLastQuestionAnswered = true

    const addQuestionToRenderList = (question) => {
      result.push(question.idx)
      const isAnswered = answers[question.idx.toString()] !== undefined

      // Image Types are optionals
      if (!isAnswered && question.type !== QuestionType.IMAGE) {
        isLastQuestionAnswered = false
      }
    }

    for (const [index, question] of questions.entries()) {
      if (question.isChild) {
        const { parentIdx } = question
        const parentQuestion = findQuestionByIdx(parentIdx)
        const parentAnswer = answers[parentIdx]
        const effectiveChild = parentQuestion.children[parentAnswer]

        if (effectiveChild !== undefined) {
          if (!effectiveChild.includes(question.idx)) {
            continue
          }
        } else {
          continue
        }
      }

      const previousQuestion =
        result.length > 0 ? findQuestionByIdx(result[result.length - 1]) : null

      const isPreviousQuestionImageType =
        previousQuestion !== null
          ? previousQuestion.type === QuestionType.IMAGE
          : false

      if (
        // If the question is the first one
        result.length === 0 ||
        // If the previous question has been answered
        answers[result[result.length - 1]] !== undefined ||
        // If the question has been answered
        answers[question.idx.toString()] !== undefined ||
        // If the previous question is an image type (image type is optional)
        isPreviousQuestionImageType
      ) {
        addQuestionToRenderList(question)
      } else {
        break
      }
    }

    setSurveyComplete(isLastQuestionAnswered)

    return result
  }, [questions, answers, customAnswers])

  // Render fields according to their types
  const renderField = (question) => {
    let field = null
    const answer = answers[question.idx.toString()]
    const customAnswer = customAnswers[question.idx.toString()]

    switch (question.type) {
      case QuestionType.TEXT:
        let value = answer === undefined ? '' : answer

        let onChange = (event) => {
          const newValue = event.target.value
          if (newValue === '') {
            removeAnswer(question.idx)
          } else {
            setAnswer(question.idx, newValue)
          }
        }

        field = <Input value={value} onChange={onChange} />

        break

      case QuestionType.BOOLEAN:
      case QuestionType.SELECT:
        value = answer === undefined ? null : answer
        onChange = (newValue) => {
          setAnswer(question.idx, newValue)
        }

        const options =
          question.type === QuestionType.BOOLEAN
            ? [
                { label: 'Oui', value: true },
                { label: 'Non', value: false }
              ]
            : question.select.options.map(item => ({
                value: item.value,
                label: item.label ? item.label : item.value
              }))

        if (
          question.type === QuestionType.SELECT &&
          question.select.withOtherField
        ) {
          options.push({
            label: 'Autre',
            value: otherFieldValue
          })
        }

        const isOtherFieldSelected = answer === otherFieldValue

        const customAnswerOnChange = (event) => {

          setHasBeenModified(true)

          setCustomAnswers({ ...customAnswers, [question.idx]: event.target.value })
        }

        field =
            <View>
            <ButtonGroup>
              { options.map((option, index) => (
                <Button key={index} color="primary" onClick={() => onChange(option.value)} active={option.value === value}>
                  {option.label ? option.label : option.value }
                </Button>
              )) }
            </ButtonGroup>

              {isOtherFieldSelected && (
                <Input value={customAnswer} onChange={customAnswerOnChange} />
              )}
            </View>

        break

      case QuestionType.NUMERIC:
        value = answer === undefined ? '' : answer

        onChange = (event) => {
          const newValue = event.target.value

          if (newValue === '') {
            removeAnswer(question.idx)
          } else {
            setAnswer(question.idx, parseInt(newValue, 10))
          }
        }

        field = (
          <Input
            value={value}
            onChange={onChange}
            type='number'
          />
        )

        break

      case QuestionType.IMAGE:
        onChange = (newValue) => {
          setHasBeenModified(true)
          if (newValue.length === 0) {
            removeAnswer(question.idx)
          } else {
            setAnswer(question.idx, newValue)
          }
        }

        field = (
          <View style={{ width: '100%' }}>
            <ImageUploader onImagesChange={onChange} defaultUris={answer} />
          </View>
        )

        break

      case QuestionType.DATE:
        const input = {
          onChange: (newDate) => {
            setAnswer(question.idx, newDate)
          },
          value: answer
        }

        field = (
          <View style={{ width: '100%' }}>
           <DatePicker
            input={input}
          />
          </View>
        )
    }

    // If the field type cannot be contained by a single row in the layout
    const isMultiLine = [QuestionType.IMAGE].includes(question.type)

    const questionContainerStyle = isMultiLine
      ? styles.multiLineRow
      : styles.oneLineRow

    const fieldStyle = isMultiLine ? {} : styles.fieldContainer

    return (
      <View style={[styles.questionContainer, questionContainerStyle]}>
        <Text style={styles.questionTextContainer}>{question.text}</Text>
        <View style={fieldStyle}>{field}</View>
      </View>
    )
  }

  // Render the questions according to the list fieldIdxsToRender
  const questionsToRender: any = useMemo(() => {
    const result = []

    for (const { question, index } of questions.map((item, i) => ({
      question: item,
      index: i
    }))) {
      if (!fieldIdxsToRender.includes(question.idx)) {
        // Removes the answer saved if it is not rendered anymore (avoid useless data persistance)
        if (answers[question.idx.toString()] !== undefined) {
          removeAnswer(question.idx)
        }

        continue
      }

      result.push(<View key={index}>{renderField(question)}</View>)
    }

    return result
  }, [fieldIdxsToRender])

  const onValidate = async () => {
    const responses = Object.keys(answers).map(key => {
      const result: Response = {
        idx: parseInt(key, 10),
        value: answers[key]
      }

      if (answers[key] === otherFieldValue) {
        result.otherValue = customAnswers[key]
      }

      return result
    })

    const result = await onSave(questions, responses)

    if (result) {
      setHasBeenModified(false)
      toast.success('Enregistrement réussi')
    } else {
      toast.error('Une erreur est survenue')
    }
  }

  const displayValidateButton = !(defaultAnswers.length > 0 && !hasBeenModified) && surveyComplete

  return (
    <View>
      <div className='survey-container'>
        {questionsToRender}
      </div>
      {displayValidateButton && 
        <Button className='validate-button' onClick={onValidate}>
          Valider les modifications
        </Button>}
    </View>
  )
}


export default Survey
