import React from "react";

export default ({
	type = "text",
	placeholder = null,
	onChange,
	grey = false,
	value,
}) => {
	return (
		<input
			className={`mb-2 appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
				grey ? "bg-gray-100" : ""
			}`}
			type={type}
			placeholder={placeholder}
			onChange={onChange}
			value={value}
		/>
	);
};
