import React, {Fragment, useState} from 'react';

const renderRadioBtns = ({radioBtns, onUpdate, isChecked}) => {
	const [elChecked, setElChecked] = useState(isChecked ? 0 : null)

	const onChecked = (value) => {
		if (value === elChecked) {
			if (onUpdate)
				onUpdate(false);
			setElChecked(null);
		}
		else {
			if (onUpdate)
				onUpdate(true);
			setElChecked(value);
		}
	}
	if (!radioBtns)
		return null
	return (
		<div className={'radiobtns-container'}>
			{radioBtns.map((r, idx) => {
				return (
					<Fragment key={idx}>
						{r !== 'AUTRE' ?
							(<div key={`radio-element-${idx}`} className={'radio-el-container'}>
								<div className={elChecked === idx ? 'radio-btn-selected' : 'radio-btn'}
									 onClick={() => onChecked(idx)}>
								</div>
								<span>{r.label ? r.label : r.value}</span>
							</div>) :
							(<div key={`radio-element-${idx}`} className={'radio-el-container'}>
								<div className={elChecked === idx ? 'radio-btn-selected' : 'radio-btn'}
									 onClick={() => onChecked(idx)}>
								</div>
								<div className={'input'}><span>AUTRE</span></div>
							</div>)
						}
					</Fragment>
				)
			})}
		</div>
	)
}

export default renderRadioBtns;
