import React, { Component, createRef } from "react";
import Loading from "@whatsonweb/loading";
import NumberFormat from "react-number-format";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Table, Card, CardHeader, Button } from "reactstrap";
import PropTypes from "prop-types";

import CreateEventPopup from "../../../CreateEventPopup";
import ApiConfig from "../../../../../../constants/ApiConfig";
import * as eventService from "/wh/services/event";

class Financement extends Component {
	state = {
		loaded: false,
		events: [],
	};

	eventPopup = createRef();

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const { data, status } = this.props;

		try {
			const eventServiceData = await eventService.search({
				"contract.id": data["id"],
				statusFamily: status.statusFamily["BANK"],
			});

			this.setState({
				events: eventServiceData["hydra:member"],
				loaded: true,
			});
		} catch (error) {
			console.error(error);
		}
	};

	submit = async () => {
		await this.load();
		await this.props.onReload();
	};

	render() {
		const { status } = this.props;
		const { loaded } = this.state;

		return loaded ? (
			<Card>
				<CreateEventPopup
					ref={this.eventPopup}
					type={status.statusFamily.BANK}
					status={status.bankStatus}
					onSubmit={this.submit}
				/>
				<CardHeader>
					<i className="fa fa-bank" /> Financement
					<div className="card-actions">
						<Button
							onClick={() =>
								this.eventPopup.current
									// .getWrappedInstance()
									.handleOpen(this.props.data)
							}
						>
							<i className="fa fa-plus" /> Ajouter un évenement
						</Button>
					</div>
				</CardHeader>

				<Table responsive striped hover>
					<thead className="thead-light">
						<tr>
							<th />
							<th>Date</th>
							<th>Nom</th>
							<th>Commentaire</th>
						</tr>
					</thead>
					<tbody>
						{this.state.events.map((el, index) => (
							<tr key={index}>
								<td>#{el.id}</td>
								<td>
									<Moment format="DD/MM/YYYY">
										{el.date}
									</Moment>
								</td>
								<td>{el.statusName}</td>
								<td>{el.comment}</td>
							</tr>
						))}
					</tbody>
				</Table>

				<Table responsive striped hover>
					<thead className="thead-light">
						<tr>
							<th className="text-center">Type</th>
							<th className="text-center">Financeur</th>
							<th className="text-center">Montant</th>
						</tr>
					</thead>
					<tbody>
						{this.props.data.payments &&
							this.props.data.payments.map((payment, index) => (
								<tr key={index}>
									<td className="text-center">
										{payment.typeName}
									</td>
									<td className="text-center">
										{payment.bankFolder &&
											payment.bankFolder.bankName}
									</td>
									<td className="text-center">
										<NumberFormat
											value={payment.amount}
											displayType="text"
											thousandSeparator
											prefix="€"
										/>
									</td>
								</tr>
							))}
					</tbody>
				</Table>

				{/*
					<div className="row">
						<div className="col-md-6">
							<Table responsive striped hover>
								<thead className="thead-light">
									<tr>
										<th>Pièce</th>
										<th className="text-right">Statut</th>
									</tr>
								</thead>
								<tbody>
									{this.state.bank.bankFolderFiles &&
										this.state.bank.bankFolderFiles.map((el, index) => (
											<tr key={index}>
												<td>{el.name}</td>
												<td className="text-right">
													<BtnStatusFile file={el} />
												</td>
											</tr>
										))}
								</tbody>
							</Table>
						</div>

						<div className="col-md-6">

						</div>
					</div>
					*/}
			</Card>
		) : (
			<Loading />
		);
	}
}

Financement.propTypes = {
	data: PropTypes.shape({
		payments: PropTypes.arrayOf(PropTypes.any.isRequired),
		"@id": PropTypes.string.isRequired,
	}).isRequired,
	onReload: PropTypes.func.isRequired,
	status: PropTypes.shape({
		statusFamily: PropTypes.shape({ BANK: PropTypes.number.isRequired }),
	}).isRequired,
};

const mapStateToProps = state => ({ status: state.status });

export default connect(mapStateToProps)(Financement);
