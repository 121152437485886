import React, { Component } from "react";
import { Nav, NavbarToggler } from "reactstrap";

import HeaderDropdownAccount from "./HeaderDropdownAccount";

class Header extends Component {
	refresh() {
		window.location.reload();
	}

	sidebarToggle(e) {
		e.preventDefault();
		document.body.classList.toggle("sidebar-hidden");
	}

	sidebarMinimize(e) {
		e.preventDefault();
		document.body.classList.toggle("sidebar-minimized");
	}

	mobileSidebarToggle(e) {
		e.preventDefault();
		document.body.classList.toggle("sidebar-mobile-show");
	}

	asideToggle(e) {
		e.preventDefault();
		document.body.classList.toggle("aside-menu-hidden");
	}

	render() {
		return (
			<header className="h-24 flex bg-white items-center justify-end">
				<NavbarToggler
					className="d-lg-none"
					onClick={this.mobileSidebarToggle}
				>
					<span className="navbar-toggler-icon"></span>
				</NavbarToggler>

				<NavbarToggler
					className="d-md-down-none sidebar-minimizer h-100 b-r-1"
					onClick={this.sidebarToggle}
				>
					<span className="navbar-toggler-icon"></span>
				</NavbarToggler>

				<HeaderDropdownAccount />
			</header>
		);
	}
}

export default Header;
