import { SET_CUSTOMERS, TYPES } from './constants';
import { createReducer } from 'redux-friends';

const defaultState = {};

const behaviors = {
	[TYPES[SET_CUSTOMERS]]: (state, { payload }) => payload,
};

export default createReducer(behaviors, defaultState);
