import React, { useEffect } from "react";
import { useDate } from "./useDate";
import { Button } from "/wh/components/WhComponent/index";

const DisplayDate = ({ type, onUpdate }) => {
	const { date, changeType, add, substract, dateFilters } = useDate();

	useEffect(() => {
		changeType(type);
	}, [type]);

	useEffect(() => {
		onUpdate(dateFilters);
	}, [dateFilters]);

	return (
		<div>
			<Button
				className="mr-5"
				small
				color="info"
				onClick={() => substract(type)}
			>
				<i className="fa fa-chevron-left" />
			</Button>
			<Button
				className="mr-5"
				small
				color="info"
				onClick={() => add(type)}
			>
				<i className="fa fa-chevron-right" />
			</Button>

			<span className="text-xl">{date ? date : ""}</span>
		</div>
	);
};

export default DisplayDate;
