import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import ApiService from "/wh/services/apiSearch/apiSearch";
import WHTable from "../../components/WHTable";
import useModal from "/wh/common/modalHook/customModalHook";
import useSearch from "../../services/apiSearch/searchHook";
import { APIENUM } from "/wh/services/ApiEnum";
import { toast } from "react-toastify";
import { getRoles } from "../../redux/status/selectors";
import UserForm from "./Form";
import {
	Page,
	PageHeader,
	PageTitle,
	Modal,
	H1,
	Badge,
	Button,
	Text,
} from "../../components/WhComponent/index";
import { SubmissionError } from "redux-form";
import * as memberService from "/wh/services/member";

export default connect((state) => ({
	roleGroups: getRoles(state),
}))(({ roleGroups }) => {
	const { isShowing, toggle } = useModal();
	const { data, load, setFilters } = useSearch(APIENUM.USER);
	const [selectedUser, setSelectedUser] = useState(null);

	const userService = new ApiService(`/api/${APIENUM.USER}`);

	const deleteUser = async (id) => {
		if (confirm("Etes vous sûre de vouloir supprimer ce membre ?")) {
			try {
				await userService.remove(id);

				toast.success("User supprimé");
				load();
			} catch (e) {
				toast.error("Une erreur est survenue");
			}
		}
	};

	const openEditModal = (user) => () => {
		setSelectedUser(user);
		toggle();
	};

	const onSubmit = async (data) => {
		const service = !data.id ? userService.create : userService.update;

		try {
			await service(data);

			toggle();
			load();

			toast.success("Membre créée");
		} catch (e) {
			toast.error("Une erreur est survenue");
			throw new SubmissionError(e);
		}
	};

	const getRoleLabel = (role) => {
		const roleGroupKeys = Object.keys(roleGroups);

		for (const key of roleGroupKeys) {
			const foundRole = roleGroups[key].find(
				(roleObject) => roleObject.value === role
			);

			if (foundRole) {
				return <Badge color="success">{foundRole.label}</Badge>;
			}
		}

		return <Badge color="danger">Role invalide</Badge>;
	};

	const openCreateModal = () => {
		setSelectedUser(null);
		toggle();
	};

	const sendPassword = (id) => {
		if (
			confirm(
				"Etes vous sûre de vouloir envoyer un nouveau mot de passe ? "
			)
		) {
			memberService
				.sendPassword(id)

				.then(() => {
					toast.success("Un nouveau mot de passe a été envoyé");
				})
				.catch(() => {
					toast.error("Une erreur est survenue");
				});
		}
	};

	const initialValues = useMemo(() => {
		return selectedUser
			? {
					...selectedUser,
					team: selectedUser.team ? selectedUser.team["@id"] : null,
			  }
			: { roles: [] };
	}, [selectedUser]);

	const columns = useMemo(() => [
		{
			Header: "",
			accessor: "id",
			filterable: false,
			Cell: (row, index) => (
				<div key={index}>
					<Button
						small
						color="info"
						className="mr-2"
						onClick={openEditModal(row.original)}
					>
						<i className="fa fa-edit" />
					</Button>
					<Button
						small
						color="success"
						className="mr-2"
						onClick={() => sendPassword(row.original.id)}
					>
						<i className="fa fa-send" />
					</Button>
					<Button
						small
						color="danger"
						onClick={() => deleteUser(row.value)}
					>
						<i className="fa fa-trash" />
					</Button>
				</div>
			),
		},
		{
			Header: "Nom",
			accessor: "lastname",
		},
		{
			Header: "Login",
			accessor: "username",
			filterable: false,
		},
		{
			Header: "Email",
			accessor: "email",
		},
		{
			Header: "Téléphone",
			accessor: "mobile",
			filterable: false,
		},
		{
			Header: "Rôle(s)",
			accessor: "roles",
			Cell: (row, index) => (
				<div key={index}>
					{row.value.map((item, key) => (
						<div key={key}>{getRoleLabel(item)}</div>
					))}
				</div>
			),
			filterable: false,
		},
		{
			Header: "Secteur",
			accessor: "team.sector.name",
		},
		{
			Header: "Responsable",
			accessor: "team.userMaster",
			Cell: (row, index) => (
				<div key={index}>
					{row.value
						? `${row.value.firstname} ${row.value.lastname}`
						: ""}
				</div>
			),
		},
		{
			Header: "Statut",
			accessor: "enabled",
			filterable: false,
			Cell: (row) => (
				<div>
					{row.value && <Badge color="success">Active</Badge>}
					{!row.value && <Badge color="danger">Désactivé</Badge>}
				</div>
			),
			width: 85,
		},
	]);

	return (
		<Page>
			<PageHeader>
				<PageTitle>
					<H1>Gestion des membres</H1>
					<Button outline onClick={openCreateModal}>
						<i className="fa fa-plus" /> Nouveau
					</Button>
				</PageTitle>
				<Text>
					Chercher, filtrer des membres pouvant accéder au crm
				</Text>
			</PageHeader>

			<WHTable
				data={data}
				columns={columns}
				filterable
				onFetchData={(state) => {
					setFilters(state.filtered);
				}}
			/>

			<Modal
				visible={isShowing}
				onClose={toggle}
				title="Editer un membre"
			>
				{isShowing && (
					<UserForm
						onSubmit={onSubmit}
						toggle={toggle}
						roleGroups={roleGroups}
						initialValues={initialValues}
					/>
				)}
			</Modal>
		</Page>
	);
});
