export const save = (name, value) => {
	value = JSON.stringify(value);
	localStorage.setItem(name, value);
};

export const get = (name) => {
	let value = localStorage.getItem(name);

	if (!value) return false;

	try {
		let parsing = JSON.parse(value);

		if (parsing) {
			return parsing;
		} else {
			return value;
		}
	} catch (error) {
		return false;
	}
};

export const destroy = (name) => {
	localStorage.removeItem(name);
};
