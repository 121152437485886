import React, { useState, useMemo } from "react";
import { APIENUM } from "/wh/services/ApiEnum";
import useModal from "/wh/common/modalHook/customModalHook";
import WHTable from "/wh/components/WHTable";
import {
	Button,
	Page,
	PageHeader,
	PageTitle,
	PageActions,
	H1,
	Text,
	Modal,
} from "/wh/components/WhComponent/index";
import ProductForm from "./ProductForm";
import ApiService from "/wh/services/apiSearch/apiSearch";
import { SubmissionError } from "redux-form";
import * as productService from "../../services/product";
import "./styles.scss";

const ProductList = () => {
	const [dataProduct, setDataProduct] = useState([]);
	const [pages, setPages] = useState(0);

	// const { data, load } = useSearch(APIENUM.PRODUCT);
	const { isShowing, toggle } = useModal();

	const [editProductId, setEditProductId] = useState(null);

	const onLoad = async (state) => {
		try {
			const fullData = await productService.search(state);
			const data = fullData["hydra:member"];
			setDataProduct(data);
			setPages(
				state
					? Math.ceil(
							fullData["hydra:totalItems"] / state.pageSize || 1
					  )
					: null
			);
		} catch (error) {
			console.error({ error });
		}
	};
	const onRowEdit = (productId) => () => {
		setEditProductId(productId);
		toggle();
	};

	const columns = [
		{
			Header: "",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			maxWidth: 100,
			Cell: (row) => (
				<Button small color="info" onClick={onRowEdit(row.original.id)}>
					<i className="fa fa-edit" />
				</Button>
			),
		},
		{
			Header: "Statut",
			accessor: "qcms",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			Cell: (row) => (
				<div className="point-container">
					{row.value && row.value.length > 0 ? (
						<div className="point-green" />
					) : (
						<div className="point-red" />
					)}
				</div>
			),
		},
		{
			Header: "Produit",
			accessor: "name",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			Cell: (row) => (
				<div>
					<span>{row.value}</span>
				</div>
			),
		},
		{
			Header: "Reference",
			accessor: "reference",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
		},
		{
			Header: "Famille",
			accessor: "productFamily.name",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
		},
		{
			Header: "Sous-Famille",
			accessor: "subProductFamily.name",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
		},
		/* {
			Header: "Questionnaire",
			accessor: "qcm",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			Cell: (row) => (
				<div>
					<span>{row.value ? row.value.name : ""}</span>
				</div>
			),
		}, */
	];

	const [submitPending, setSubmitPending] = useState(false);

	const onProductFormSubmit = (data) => {
		const finalData =
			editProductId !== null ? { ...data, id: editProductId } : data;

		const service = new ApiService(`/api/${APIENUM.PRODUCT}`);

		const finalService =
			editProductId !== null ? service.update : service.create;

		setSubmitPending(true);

		return finalService(finalData)
			.then(() => {
				toggle();
				setSubmitPending(false);
			})
			.catch((error) => {
				console.log({ error });
				setSubmitPending(false);
				throw new SubmissionError(error);
			});
	};

	const productFormInitialValues = useMemo(() => {
		if (editProductId !== null) {
			const product = dataProduct.find(
				(product) => product.id === editProductId
			);

			if (!product) {
				return null;
			}

			const { name, qcms } = product;

			const qcmUris = qcms ? qcms.map((item) => item["@id"]) : [];

			return {
				name,
				qcms: qcmUris, // qcmUri,
			};
		} else {
			return null;
		}
	}, [editProductId, dataProduct]);

	const onCreateProductClick = () => {
		setEditProductId(null);

		toggle();
	};

	const synchronise = async () => {
		const service = new ApiService("/api/synchronise-products");
		await service.synchroniseProduct();
		window.location.reload();
	};

	return (
		<Page>
			<PageHeader>
				<PageTitle>
					<H1>Liste des produits et questionnaires associés</H1>
					<PageActions>
						<Button outline onClick={onCreateProductClick}>
							{" "}
							<i className="fa fa-plus" /> Créer un produit
						</Button>
						<Button outline onClick={synchronise}>
							{" "}
							<i className="fa fa-download" /> Synchroniser les
							produits
						</Button>
					</PageActions>
				</PageTitle>

				<Text>
					"Synchronisez" les produits pour l'importer depuis la
					tablette.
				</Text>
			</PageHeader>

			<Modal
				visible={isShowing}
				onClose={toggle}
				title={
					editProductId !== null
						? "Modifier un produit"
						: "Créer un produit"
				}
			>
				{isShowing && (
					<ProductForm
						toggle={toggle}
						onSubmit={onProductFormSubmit}
						initialValues={productFormInitialValues}
					/>
				)}
			</Modal>

			{!submitPending && (
				<WHTable
					key="table"
					onFetchData={onLoad}
					pages={pages}
					data={dataProduct}
					columns={columns}
					className="intervention_table"
				/>
			)}
		</Page>
	);
};

export default ProductList;
