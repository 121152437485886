import { fetch } from "../api";
import configApi from "../../constants/ApiConfig";

const endPoints = {
	login: "/login_check",
	logout: "/logout",
};

export const login = (username, password) =>
	fetch(`${configApi.url}${endPoints.login}`, "post", {
		username,
		password,
	});

export const ping = () => fetch(`${configApi.url}/api/me`, "get");
