import { fetch } from '../../services/api';

const endPoints = '/api/bank_folder_files';

export const STATUS_WAIT = 0;
export const STATUS_MISSING = 5;
export const STATUS_KO = 10;
export const STATUS_OK = 100;

export const update = (id, status) => fetch(`${endPoints}/${id}`, status, 'put');
