import { fetch } from '../../services/api';
import createMethods from '/wh/utils/createMethods';
import { GET } from '@whatsonweb/react-utils';
import QueryBuilder from '../../components/WHTable/queryBuilder';

const endPoints = '/api/contracts';

export const { search, view, create, update, remove } = createMethods(endPoints);

export const exports = data => fetch(`${endPoints}/export`, GET, data);

export const total = (data) => {

	let query = QueryBuilder(data);

	return fetch(`${endPoints}/total`, 'get', query)
};
