import React, { Component } from 'react';
import Loading from '@whatsonweb/loading';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Card, Jumbotron, ListGroup, ListGroupItem } from 'reactstrap';

import * as customerService from '../../services/customer';
import Edit from './views/Edit/Edit';

class Customer extends Component {
	state = {
		loaded: false,
		data: {},
	};
	componentDidMount() {
		this.load();
	}

	load = () => {
		customerService.view(this.props.match.params.id).then(data => {
			this.setState({ data, loaded: true });
		});
	};

	render() {
		const { match } = this.props;

		return this.state.loaded ? (
			<div className="animated fadeIn">
				<Jumbotron>
					<h1 className="display-4">{this.state.data.name}</h1>
				</Jumbotron>

				<div className="row">
					<div className="col-md-2">
						<Card>
							<ListGroup>
								<ListGroupItem tag="a" href={`#${match.url}/edit`}>
									<i className="fa fa-edit fa-fw" /> Editer
								</ListGroupItem>
							</ListGroup>
						</Card>
					</div>
					<div className="col-md-10">
						<Switch>
							<Route
								path={`${match.url}/edit`}
								name="Edit"
								component={() => <Edit data={this.state.data} {...this.props} />}
							/>
							<Redirect from={`${match.url}`} to={`${match.url}/edit`} />
						</Switch>
					</div>
				</div>
			</div>
		) : (
			<Loading />
		);
	}
}

export default Customer;
