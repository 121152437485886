import React from "react";

export default ({ htmlFor = "", children, className }) => {
	return (
		<label
			htmlFor={htmlFor}
			className={`text-gray-600 text-sm mb-2 block ${className}`}
		>
			{children}
		</label>
	);
};
