import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';

registerLocale('fr', fr);

const DatePickerField = (props) => {
	const [startDate, setStartDate] = useState(props.value);

	const handleChange = (date) => {
		setStartDate(date);
	}

	return (
		<div className="date-picker">
			<DatePicker
				className="form__form-group-datepicker"
				selected={Date.parse(startDate)}
				{...props}
				timeFormat="HH:mm"
				dateFormat="dd/MM/yyyy"
				locale="fr"
				dropDownMode="select"
			/>
			<i className="far fa-calendar-alt"/>
		</div>
	)
}

const renderDateTimePickerField = (props) => {
	const { input } = props;
	return <DatePickerField {...input} />;
};

export default renderDateTimePickerField;
