import React from "react";
import {
	Form,
	Alert,
	FieldTxt,
	Button,
} from "/wh/components/WhComponent/index";
import { Field, reduxForm } from "redux-form";
import * as validator from "/wh/components/Form/wh-validator";
import Masks from "../../../utils/maskForm";

const UserForm = ({ error, handleSubmit, submitting, onCancel }) => {
	return (
		<Form onSubmit={handleSubmit}>
			<Field
				label="Nom :"
				name="lastname"
				component={FieldTxt}
				validate={[validator.required]}
				type="text"
			/>

			<Field
				label="Prénom :"
				name="firstname"
				component={FieldTxt}
				validate={[validator.required]}
				type="text"
			/>
			<Field
				label="N° client personnalisé"
				name="extRef"
				// validate={[validator.required]}
				component={FieldTxt}
				type="text"
			/>
			<Field
				name="email"
				label="Email "
				// validate={[validator.required, validator.email]}
				component={FieldTxt}
				type="text"
			/>
			<Field
				name="phone"
				label="Tél fix"
				mask={Masks.phone}
				// validate={[
				// 	validator.required
				// ]}
				component={FieldTxt}
				type="text"
			/>
			<Field
				name="mobile"
				label="Tél portable"
				mask={Masks.phone}
				// validate={[
				// 	validator.required
				// ]}
				component={FieldTxt}
				type="text"
			/>
			<Field
				label="Code Postal :"
				name="zipcode"
				component={FieldTxt}
				type="text"
			/>
			<Field
				label="Adresse :"
				name="street"
				component={FieldTxt}
				type="text"
			/>
			<Field
				label="Ville :"
				name="city"
				component={FieldTxt}
				type="text"
			/>
			<Field
				label="Pays :"
				name="country"
				component={FieldTxt}
				type="text"
			/>

			{error && (
				<Alert color="danger">
					<div dangerouslySetInnerHTML={{ __html: error }} />
				</Alert>
			)}

			<div className="flex justify-center">
				<button
					className="btn px-2"
					onClick={(e) => {
						e.preventDefault();
						onCancel();
					}}
				>
					Annuler
				</button>
				<Button loading={submitting} type={"submit"}>
					Valider
				</Button>
			</div>
		</Form>
	);
};

const rxf = reduxForm({
	form: "UserForm",
	enableReinitialize: true,
})(UserForm);

export default rxf;
