import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as REDUX_FORM_REDUCER } from "redux-form";
import { USER_RK, USER_REDUCER } from "./user";
import { CONTRACTS_RK, CONTRACTS_REDUCER } from "/wh/modules/Contracts/redux/";
import { STATUS_RK, STATUS_REDUCER } from "./status";
import { CUSTOMERS_REDUCER, CUSTOMERS_RK } from "../modules/Customers/redux";

export default (history) =>
	combineReducers({
		router: connectRouter(history),
		form: REDUX_FORM_REDUCER,
		[USER_RK]: USER_REDUCER,
		[CONTRACTS_RK]: CONTRACTS_REDUCER,
		[STATUS_RK]: STATUS_REDUCER,
		[CUSTOMERS_RK]: CUSTOMERS_REDUCER,
	});
