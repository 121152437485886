import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { FieldTxt, FieldSelect2 } from '/wh/components/Form/wh-field';
import { ModalBody, ModalFooter, Alert, Form } from 'reactstrap';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import PropTypes from "prop-types";



const FormMessagePopup = ({ error, handleSubmit, submitting }) =>(
    <Form onSubmit={handleSubmit} className="form-vertical">
        <ModalBody>
            <Field label="Message : " name="comment" component={FieldTxt} type="textarea" />
            {error && (
                <Alert color="danger">
                    <div dangerouslySetInnerHTML={{ __html: error }} />
                </Alert>
            )}
        </ModalBody>
        <ModalFooter>
            <LaddaButton
                className="btn btn-success btn-ladda"
                loading={submitting}
                data-style={EXPAND_LEFT}
                type="submit">
                <i className="fa fa-send" /> Envoyer{' '}
            </LaddaButton>
        </ModalFooter>
    </Form>
)

FormMessagePopup.defaultProps = {
    error: '',
};

FormMessagePopup.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    error: PropTypes.string,
};

export default reduxForm({ form: 'FormMessagePopup' })(FormMessagePopup);