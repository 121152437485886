import React, {Component} from 'react';
import {Form} from 'reactstrap';
import {connect} from 'react-redux';
import {Field, reduxForm, change, FormSection} from 'redux-form'
import {Button, ModalBody, ModalFooter, Alert} from 'reactstrap';


class FormPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        };

    }


    render() {

        const {error, handleSubmit, pristine, reset, submitting, children} = this.props;

        return (

            <Form onSubmit={handleSubmit} className="form-vertical">

                <FormSection name={''}>
                {children}
                </FormSection>

                {error && <Alert color="danger"><div dangerouslySetInnerHTML={{__html: error}} /></Alert>}

                <Button color={'primary'} block type={'submit'}>Appliquer les filtre</Button>

            </Form>

        );


    }
}

FormPopup = reduxForm({
    form: 'FilterForm'
})(FormPopup);

export default FormPopup
