import React, { Fragment } from "react";
import { formatDate } from "/wh/utils/momentUtils";
import { Link } from "react-router-dom";
import {
	Card,
	CardHeader,
	CardTitle,
	Button,
	Label,
	Value,
	H3,
} from "/wh/components/WhComponent/index";
import useSearch from "../../../services/apiSearch/searchHook";
import { APIENUM } from "/wh/services/ApiEnum";
import { useEffect } from "react";

const HisInterventions = ({ contractId, match }) => {
	const { data: interventions, load } = useSearch(
		`${APIENUM.CONTRACT}/${contractId}/interventions`
	);

	useEffect(() => {
		load();
	}, []);

	const formatFromTypes = (value, int) => {
		switch (value) {
			case 1:
				return (
					<Fragment>
						<div>
							<span className={"description"}>
								Date de visite technique :
							</span>
							<span className={"date float-right"}>
								{formatDate(int.startDate, "/")}
							</span>
						</div>
						<div>
							<span className={"description"}>Metreur :</span>
							<span className={"name float-right"}>
								{int.technicien.name}
							</span>
						</div>
					</Fragment>
				);
			case 4:
				return (
					<Fragment>
						<div>
							<span className={"description"}>
								Date de pose :
							</span>
							<span className={"date float-right"}>
								{formatDate(int.startDate, "/")}
							</span>
						</div>
						<div>
							<span className={"description"}>Poseur(s) :</span>
							<span className={"name float-right"}>
								{int.technicien.name}
							</span>
						</div>
					</Fragment>
				);
			case 5:
				return (
					<Fragment>
						<div>
							<span className={"description"}>
								Date de visite fin chantier :
							</span>
							<span className={"date float-right"}>
								{formatDate(int.startDate, "/")}
							</span>
						</div>
						<div>
							<span className={"description"}>Technicien :</span>
							<span className={"name float-right"}>
								{int.technicien.name}
							</span>
						</div>
					</Fragment>
				);
			default:
				return (
					<Fragment>
						<div>
							<span className={"description"}>
								Date de visite technique :
							</span>
							<span className={"date float-right"}>
								{formatDate(int.startDate, "/")}
							</span>
						</div>
						<div>
							<span className={"description"}>Metreur :</span>
							<span className={"name float-right"}>
								{int.technicien.name}
							</span>
						</div>
					</Fragment>
				);
		}
	};

	const customerContractUrl = match.url.split("/").slice(0, 3).join("/");

	console.log({ interventions });

	return (
		<Card small>
			<CardHeader>
				<CardTitle>
					<H3>Historique des interventions</H3>
				</CardTitle>
			</CardHeader>

			{interventions &&
				interventions.map((int, idx) => {
					const rapport = int.rapport;
					return (
						<div key={`his_intervention_${idx}`}>
							<div>
								<span>{`Intervention n°${idx}`}</span>
							</div>
							{formatFromTypes(int.type, int)}
							<div>
								<span className={"description"}>Rapport :</span>
								<span>
									{rapport ? (
										<Link
											to={`${customerContractUrl}/${match.params.bdcId}/rapports/${rapport.id}`}
										>
											Voir le rapport
										</Link>
									) : (
										"Non disponible"
									)}
								</span>
							</div>
						</div>
					);
				})}
		</Card>
	);
};

export default HisInterventions;
