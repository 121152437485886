import { createTypes } from 'redux-friends';

export const STATUS_RK = 'status';

export const SET_STATUS = 'SET_STATUS_LST';

export const SET_STATUS_TYPE = 'SET_STATUS_TYPES';

export const PAYEMENT_TYPES = 'paymentTypes';

export const ROLES = 'roles';

export const MyTypesArray = [SET_STATUS];

export const TYPES = createTypes(STATUS_RK, MyTypesArray);
