import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import * as validator from "/wh/components/Form/wh-validator";
import useSearch from "/wh/services/apiSearch/searchHook";
import { APIENUM } from "/wh/services/ApiEnum";
import moment from "moment";
import * as productService from "../../services/product";
import {
	Button,
	Form,
	FieldSelect,
	FieldTxt,
	FieldDatePicker,
	FieldSelectAsync,
	FormActions,
	Alert,
} from "../../components/WhComponent/index";

moment.locale("fr");

const InterventionForm = ({
	error,
	handleSubmit,
	submitting,
	change,
	toggle,
}) => {
	const sector = useSearch(APIENUM.SECTOR);
	const salers = useSearch(APIENUM.USER, [
		{ id: "pagination", value: false },
	]);
	const form = useSelector((state) => state.form.InterventionForm);

	const { data: interventionTypesData } = useSearch(
		APIENUM.INTERVENTION_TYPES
	);

	const defaultOption = {
		value: null,
		name: "",
	};

	return (
		<Form onSubmit={handleSubmit}>
			<Field
				label="Type d'intervention : "
				inline
				name="type"
				component={FieldSelect}
				options={[
					defaultOption,
					...interventionTypesData.map((type) => ({
						value: type["@id"],
						name: type.label,
					})),
				]}
				validate={[validator.required]}
			/>
			<Field
				label="Produits : "
				inline
				multiple
				name="products"
				component={FieldSelectAsync}
				loadOptions={(inputValue, callback) => {
					productService
						.search({
							filtered: [{ id: "name", value: inputValue }],
						})
						.then((result) =>
							callback(
								result["hydra:member"].map((item) => ({
									label: item.name,
									value: item["@id"],
								}))
							)
						);
				}}
			/>
			<Field
				label="Chef de secteur : "
				inline
				name="sector"
				component={FieldSelect}
				options={[defaultOption, ...sector.data].map((s) => ({
					value: s["@id"],
					name: s.userMaster ? s.userMaster.name : s.name,
				}))}
			/>
			<Field
				label="Technicien : "
				inline
				name="technicien"
				component={FieldSelect}
				options={[
					defaultOption,
					...salers.data.filter(
						(item) =>
							item.roles &&
							(item.roles.includes("ROLE_TECHNICIAN") ||
								item.roles.includes(
									"ROLE_RESPONSABLE_INTERVENTION"
								) ||
								item.roles.includes("ROLE_POSE"))
					),
				].map((s) => ({
					value: s["@id"],
					name: s.name,
				}))}
				validate={[validator.required]}
			/>
			<Field
				label="Description :"
				inline
				name="description"
				component={FieldTxt}
				className="form-text-area"
				type="textarea"
			/>

			<Field
				label="Date de début :"
				name="startDate"
				inline
				component={FieldDatePicker}
				validate={[validator.required]}
			/>

			<Field
				label="Durée (en heures):"
				name="duration"
				inline
				component={FieldTxt}
				validate={[validator.required]}
			/>

			{error && (
				<Alert color="danger">
					<div dangerouslySetInnerHTML={{ __html: error }} />
				</Alert>
			)}
			<FormActions>
				<Button onClick={toggle} color="transparent">
					Annuler
				</Button>
				<Button type="submit" loading={submitting}>
					Valider
				</Button>
			</FormActions>
		</Form>
	);
};

const rxf = reduxForm({
	form: "InterventionForm",
	initialValues: {
		description: "",
		startDate: moment().format("YYYY-MM-DD HH:mm"),
		duration: "02:00",
	},
})(InterventionForm);

export default rxf;
