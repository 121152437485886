import { SET_USER } from './constants';
import {handleActions} from 'redux-actions';

const defaultState = {id:null};

const reducer = handleActions({
	[SET_USER]: (state, action) => {
		const { payload } = action;
		return {
			...state,
			id: payload.id,
			fullname: payload.fullname,
			lastname: payload.lastname,
			firstname: payload.firstname,
			username: payload.username,
			email: payload.email,
			mobile: payload.mobile,
			roles: payload.roles,
			agency: payload.agency
		}
	},
}, defaultState)

export default reducer;
