import { createHashHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './root.reducer';
import thunk from 'redux-thunk';

export const history = createHashHistory();

const persistConfig = {
	key:'root',
	storage
}
const persistedReducer = persistReducer(persistConfig, rootReducer);
export default function configureStore(preloadedState) {
	const store = createStore(
		rootReducer(history),
		preloadedState,
		compose(
			applyMiddleware(
				routerMiddleware(history),
				thunk,
			)
		)
	)
	const persistor = persistStore(store);
	return {store, persistor};
}
