import React from "react";

export default ({ children, small = false, className }) => {
	const getSize = () => {
		if (small) return "p-5";

		return "p-10";
	};

	return (
		<div
			className={`bg-white rounded-lg relative mb-5 ${getSize()} ${className}`}
		>
			{children}
		</div>
	);
};
