import React, { useMemo, useState } from "react";
import useModal from "/wh/common/modalHook/customModalHook";
import ApiService from "/wh/services/apiSearch/apiSearch";
import WHTable from "../../components/WHTable";
import useSearch from "../../services/apiSearch/searchHook";
import { APIENUM } from "/wh/services/ApiEnum";
import { toast } from "react-toastify";
import TeamForm from "./Form";

import {
	Button,
	Page,
	PageHeader,
	PageTitle,
	H1,
	Text,
	Modal,
} from "/wh/components/WhComponent/index";
import { id } from "date-fns/locale";
import { SubmissionError } from "redux-form";

const Teams = () => {
	const { isShowing, toggle } = useModal();

	const { data, load, setFilters } = useSearch(APIENUM.TEAM);
	const [selectedTeam, setSelectedTeam] = useState(null);

	const teamService = new ApiService(`/api/${APIENUM.TEAM}`);

	const deleteTeam = async (id) => {
		if (confirm("Etes vous sûre de vouloir supprimer cette team ?")) {
			try {
				await teamService.remove(id);

				toast.success("Team supprimée");
				load();
			} catch (e) {
				toast.error("Une erreur est survenue");
			}
		}
	};

	const openEditModal = (team) => () => {
		setSelectedTeam(team);
		toggle();
	};

	const onSubmit = async (data) => {
		const service = !data.id ? teamService.create : teamService.update;
		data.userMaster = data.userMaster === "" ? null : data.userMaster;
		try {
			await service(data);

			toggle();
			load();

			toast.success("Team créée");
		} catch (e) {
			toast.error("Une erreur est survenue");

			throw new SubmissionError(e);
		}
	};

	const openCreateModal = () => {
		setSelectedTeam(null);
		toggle();
	};

	const initialValues = useMemo(() => {
		console.log({ selectedTeam });

		return selectedTeam
			? {
					...selectedTeam,
					userMaster: selectedTeam.userMaster["@id"],
					sector: selectedTeam.sector
						? selectedTeam.sector["@id"]
						: null,
					users: selectedTeam.users.map((user) => user["@id"]),
			  }
			: {
					users: [],
			  };
	}, [selectedTeam]);

	const columns = useMemo(() => [
		{
			Header: "",
			accessor: "id",
			filterable: false,
			Cell: (row) => (
				<div>
					<Button
						small
						color="info"
						className="mr-2"
						onClick={openEditModal(row.original)}
					>
						<i className="fa fa-edit" />
					</Button>
					<Button
						small
						color="danger"
						onClick={() => deleteTeam(row.value)}
					>
						<i className="fa fa-trash" />
					</Button>
				</div>
			),
			maxWidth: 120,
		},
		{
			Header: "Nom",
			accessor: "name",
		},
		{
			Header: "Secteur",
			accessor: "sector.name",
		},
		{
			Header: "Responsable",
			accessor: "userMaster",
			Cell: (row, index) => (
				<div key={index}>
					{row.value
						? `${row.value.firstname} ${row.value.lastname}`
						: ""}
				</div>
			),
		},
	]);

	return (
		<Page>
			<PageHeader>
				<PageTitle>
					<H1>Gestion des équipes</H1>
					<Button onClick={openCreateModal}>
						<i className="fa fa-plus" /> Nouveau
					</Button>
				</PageTitle>

				<Text>Gérer les équipes</Text>
			</PageHeader>

			<Modal visible={isShowing} onClose={toggle} title="Editer une team">
				{isShowing && (
					<TeamForm
						onSubmit={onSubmit}
						toggle={toggle}
						initialValues={initialValues}
					/>
				)}
			</Modal>

			<WHTable
				data={data}
				columns={columns}
				filterable
				onFetchData={(state) => {
					setFilters(state.filtered);
				}}
			/>
		</Page>
	);
};

export default Teams;
