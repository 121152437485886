import React, {Component, createRef} from 'react';
import Loading from '@whatsonweb/loading';
import Moment from 'react-moment';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Card, CardHeader, Table, Button} from 'reactstrap';

import TechMessagePopup from './Popup/TechMessagePopup';
import SeniorMessagePopup from './Popup/SeniorMessagePopup';
import JuniorMessagePopup from './Popup/JuniorMessagePopup';
import EcoMessagePopup from './Popup/EcoMessagePopup';

class EnvoyerMessage extends Component {

    messagePopup = createRef();
    state = {
        loaded: true,
        data: {},
    };

    componentDidMount() {
        this.load();
    }

    load = async () => {

    };

    newData = data => {
        const { lst } = this.state;

        const index = lst.findIndex(el => el.id == data.id);

        if (index > -1) {
            lst[index] = data;
        } else {
            lst.push(data);
        }

        this.setState(lst);
    };

    submit = async () => {
        await this.load();
        await this.props.onReload();
    };

    render() {
        const {data} = this.props;
        return (
            <Card>

                <TechMessagePopup ref={'createMessagePopupTech'} data={data['technicienVt']}/>
                <EcoMessagePopup ref={'createMessagePopupEco'} data={data['customer']} />
                <JuniorMessagePopup ref={'createMessagePopupJun'} data={data['junior']}/>
                <SeniorMessagePopup ref={'createMessagePopupSen'} data={data['senior']}/>


                <CardHeader>
                    <i className="fa fa-edit"/> Envoyer un message
                    <div className="card-actions">
                        {data['technicienVt'] &&
                        <Button onClick={() => this.refs.createMessagePopupTech.handleOpen()}>
                            <i className="fa fa-plus"/> Au technicien
                        </Button>
                        }
                        {data['senior'] &&
                        <Button onClick={() => this.refs.createMessagePopupSen.handleOpen()}>
                            <i className="fa fa-plus"/> Au Senior
                        </Button>
                        }
                        {data['junior'] &&
                        <Button onClick={() => this.refs.createMessagePopupJun.handleOpen()}>
                            <i className="fa fa-plus"/> Au Junior
                        </Button>
                        }
                        {data['customer'] &&
                        <Button onClick={() => this.refs.createMessagePopupEco.handleOpen()}>
                            <i className="fa fa-plus"/> A l'eco habitant
                        </Button>
                        }
                    </div>
                </CardHeader>
            </Card>
        )
    }


}

export default EnvoyerMessage;
