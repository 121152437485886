import React, { useState } from "react";
import { Select } from "/wh/components/WhComponent/index";

const Filter = ({ onUpdate, filter_name, options, label = "Tous" }) => {
	const [filter, setFilter] = useState([]);

	const updateFilter = (e) => {
		const updatedFilter = [{ id: filter_name, value: e }];
		setFilter(updatedFilter);
		onUpdate(updatedFilter);
	};

	const opts = [];

	opts.push({ value: "", label: "tous" });
	{
		options &&
			options.map((el, index) =>
				opts.push({ value: el.value, label: el.name })
			);
	}

	return (
		<div className="flex-1 pr-2">
			<Select
				placeholder={label}
				onChange={(event) => updateFilter(event.value)}
				value={filter ? filter.value : ""}
				options={opts}
			></Select>
		</div>
	);
};

export default Filter;
