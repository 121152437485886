import React, { useState, Fragment } from "react";
import { Button } from "/wh/components/WhComponent/index";
import SearchUser from "/wh/common/searchUser/SearchUser";
import DisplayList from "/wh/common/displayList/DisplayLists";
import useModal from "/wh/common/modalHook/customModalHook";
import CreateUserForm from "./CreateUserForm";
import { Modal } from "/wh/components/WhComponent/index";
import * as customerService from "../../../services/customer/index";

const CreateUserModal = ({ title, load }) => {
	const { isShowing, toggle } = useModal();

	const [users, setUsers] = useState([]);

	const getUsers = async (search) => {
		await customerService
			.search({ filtered: [{ id: "searchProspect", value: search }] })
			.then((data) => {
				setUsers(data["hydra:member"]);
			})
			.catch((error) => {
				console.error("In calling users service::", error);
			});
	};

	const onUserEdit = () => {
		toggle();
		load();
	};

	const rows = [["firstname", "lastname"], ["email"], ["zipcode", "city"]];

	const action = {
		name: "Editer",
		onClick: onUserEdit,
	};

	const createUserCallBack = () => {
		toggle();
		load();
	};

	return (
		<Fragment>
			<Button onClick={toggle} outline>
				<i className="fa fa-plus" /> Créer un client
			</Button>

			<Modal visible={isShowing} onClose={toggle} title={title} size="s">
				<SearchUser
					placeholder="Votre Recherche"
					users={users}
					onChange={getUsers}
				>
					{users.length > 0 && (
						<DisplayList
							list={users}
							rows={rows}
							action={action}
							className="overflow-auto h-64"
						/>
					)}
					<CreateUserForm user={null} callback={createUserCallBack} />
				</SearchUser>
			</Modal>
		</Fragment>
	);
};

export default CreateUserModal;
