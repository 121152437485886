import { createAction } from 'redux-friends';

import {
	SET_CONTRACTS,
	SET_CONTRACT,
	TYPES,
	REMOVE_CONTRACT,
	UPDATE_CONTRACT,
	REPLACE_CONTRACT,
} from './constants';
import * as contractService from '../../../services/contract';

export const setContracts = createAction(TYPES[SET_CONTRACTS]);
export const setContract = createAction(TYPES[SET_CONTRACT]);
export const removeContract = createAction(TYPES[REMOVE_CONTRACT]);
export const updateContract = createAction(TYPES[UPDATE_CONTRACT]);
export const replaceContract = createAction(TYPES[REPLACE_CONTRACT]);

const loadContracts = async state => {
	try {
		const fullData = await contractService.search(state);
		const data = fullData['hydra:member'].map(el => {
			if (el.invoice) el.invoice = JSON.parse(el.invoice);
			return el;
		});
		return {
			data,
			pages: Math.ceil(fullData['hydra:totalItems'] / state.pageSize || 1),
		};
	} catch (error) {
		console.error({ error });
	}
};

const loadContractsTotal = async state => {
	try {
		const total = await contractService.total(state);

		return total;

	} catch (error) {

		console.error({ error });
	}
};

export const fetchContracts = (state = {}) => async dispatch => {
	try {
		const data = await loadContracts(state);
		dispatch(setContracts(data));
	} catch (error) {
		console.error(error);
	}
};

export const fetchContractsTotal = (state = {}) => async dispatch => {
	try {
		const data = await loadContractsTotal(state);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const fetchContract = contractId => async dispatch => {
	try {
		const data = await contractService.view(contractId);
		if (data.invoice) data.invoice = JSON.parse(data.invoice);
		dispatch(setContract(data));
		dispatch(replaceContract(data));
	} catch (error) {
		console.error(error);
	}
};

export const deleteContract = toDeleteId => async dispatch => {
	try {
		await contractService.remove(toDeleteId);
		dispatch(removeContract(toDeleteId));
	} catch (error) {
		console.error(error);
	}
};
