import React, { useEffect, Fragment, useState } from "react";
import WHTable from "/wh/components/WHTable";
import useSearch from "/wh/services/apiSearch/searchHook";
import { APIENUM } from "/wh/services/ApiEnum";
import { Redirect } from "react-router-dom";
import { Button } from "/wh/components/WhComponent/index";
import * as qcmsService from "../../services/qcm";
import { toast } from "react-toastify";

const QuestionnaireList = ({ types, fetchData = false, onReload, history }) => {
	const { data, load } = useSearch(APIENUM.QCM, []);
	const [qcmsData, setQcmsData] = useState([]);
	const [pages, setPages] = useState([]);
	const [loaded, setLoaded] = useState(false);

	const [questionnaireViewId, setQuestionnaireViewId] = useState(null);

	const onRowClick = (rowInfos) => {
		setQuestionnaireViewId(rowInfos.original.id);
	};

	const onLoad = async (state) => {
		setLoaded(false);
		try {
			const fetchedData = await qcmsService.search(state);
			setQcmsData(fetchedData["hydra:member"]);
			setPages(Math.ceil(data["hydra:totalItems"] / state.pageSize));
			setLoaded(true);
		} catch (error) {
			console.error(error);
		}
	};

	/* useEffect(() => {
		if (fetchData) {
			load();
			onReload(false);
		}
	}, [fetchData, load, onReload]); */

	const onEdit = (id) => {
		history.push(`/questionnaires/${id}`);
	};
	const view = (id) => {
		history.push(`/questionnaires/${id}/view`);
	};

	const remove = async (toDelete) => {
		if (confirm("Etes vous sûre de vouloir supprimer cet item ?")) {
			try {
				await qcmsService.remove(toDelete);

				const lst = qcmsData;

				const index = lst.findIndex((el) => el.id == toDelete);

				if (index > -1) {
					console.log("here");
					lst.splice(index, 1);

					setQcmsData(lst);
				}

				toast.success("qcm supprimé");
				window.location.reload();
			} catch (error) {
				toast.error("Une erreur est survenue");
			}
		}
	};
	const columns = [
		{
			Header: "",
			accessor: "id",
			filterable: false,
			width: 150,
			Cell: (row, index) => (
				<div key={index}>
					<Button
						small
						color="info"
						className="mr-2"
						onClick={() => onEdit(row.value)}
					>
						<i className="fa fa-edit" />
					</Button>
					<Button
						small
						className="mr-2"
						color="warning"
						onClick={() => view(row.value)}
					>
						<i className="fa fa-eye" />
					</Button>
					<Button
						small
						color="danger"
						onClick={() => remove(row.value)}
					>
						<i className="fa fa-trash" />
					</Button>
				</div>
			),
		},
		{
			Header: "N° questionnaire",
			accessor: "id",
			className: "bdc_col_container min-width-200",
			headerClassName: "bdc_header_cell min-width-200",
			Cell: (row) => (
				<div>
					<span className="questionnaire-row-text">{row.value}</span>
				</div>
			),
			width: 50,
		},

		{
			Header: "Nom",
			accessor: "name",
			className: "bdc_col_container min-width-200",
			headerClassName: "bdc_header_cell min-width-200",
			Cell: (row) => (
				<div className="questionnaire-row-text">{row.value}</div>
			),
		},
		{
			Header: "Produit",
			accessor: "product.name",
			className: "bdc_col_container min-width-200",
			headerClassName: "bdc_header_cell min-width-200",
			Cell: (row) => (
				<div className="questionnaire-row-text">{row.value}</div>
			),
		},
		{
			Header: "Type d'intervention",
			accessor: "interventionType.label",
			className:
				"bdc_col_container bdc_col_container-left bdc_col_container-left",
			headerClassName: "bdc_header_cell bdc_col_container-left",
			Cell: (row) => (
				<div className="questionnaire-row-text">{row.value}</div>
			),
		},
	];

	return (
		<Fragment>
			{/*{*/}
			{/*  questionnaireViewId !== null &&*/}
			{/*    <Redirect to={`/questionnaires/${questionnaireViewId}/view`} />*/}
			{/*}*/}
			<WHTable
				data={qcmsData}
				columns={columns}
				filterable
				loading={!loaded}
				onFetchData={onLoad}
				pages={pages}
				className="bdc_table"
				// onRowClick={onRowClick}
			/>
		</Fragment>
	);
};

export default QuestionnaireList;
