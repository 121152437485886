import React, { useEffect, useState } from "react";

import { Button } from "/wh/components/WhComponent/index";

const DateFilter = ({ onUpdate, dayType }) => {
	const [currentSelection, setCurrentSelection] = useState("days");

	useEffect(() => {
		onUpdate(currentSelection);
	}, [currentSelection]);

	return (
		<div className="bg-gray-100 p-1 rounded">
			<Button
				border={false}
				color={currentSelection == "days" ? "white" : "transparent"}
				onClick={() => setCurrentSelection("days")}
			>
				<span>JOUR</span>
			</Button>
			<Button
				border={false}
				color={currentSelection == "weeks" ? "white" : "transparent"}
				onClick={() => setCurrentSelection("weeks")}
			>
				<span>SEMAINE</span>
			</Button>
			<Button
				border={false}
				color={currentSelection == "months" ? "white" : "transparent"}
				onClick={() => setCurrentSelection("months")}
			>
				<span>MOIS</span>
			</Button>
		</div>
	);
};

export default DateFilter;
