import React, { Fragment } from "react";
import { Input } from "/wh/components/WhComponent/index";

const SearchUser = ({ placeholder, onChange, children }) => {
	return (
		<Fragment>
			<Input
				placeholder={placeholder}
				onChange={(e) => {
					onChange(e.target.value);
				}}
			/>
			{children}
		</Fragment>
	);
};

export default SearchUser;
