import React, { useMemo } from 'react'
import Survey, { QuestionType } from './Survey'
import ApiService from '/wh/services/apiSearch/apiSearch'
import { APIENUM } from '/wh/services/ApiEnum'

const ReportSurveyQuestions =  {
  VTValid: 1,
  pictureExplainingRefusal: 2,
  miseEnService: 3,
  demandeDP: 4,
  demandeEnedis: 5,
  protectionChantier: 6,
  detachementCable: 7,
  planPicture: 8,
  constructionSign: 9,
  hasDatePose: 10,
  datePose: 11,
  numberOfDays: 12,
  numberOfPeople: 13,
  suggestAnotherProduct: 14,
  selectAnotherProduct: 15
}

const RapportSurvey = ({ rapport, contractProducts, formatQuestionnaireWithImages, updateRapport, assignPictureQuestionDefaultValue }) => {

  // We generate a list of Questions which is render by the Survey Component
  const reportSurveyQuestions = useMemo(
    () => [
      {
        type: QuestionType.BOOLEAN,
        isChild: false,
        idx: ReportSurveyQuestions.VTValid,
        text: 'VT validée ?',
        children: {
          ['false']: [
            ReportSurveyQuestions.pictureExplainingRefusal,
            ReportSurveyQuestions.suggestAnotherProduct
          ]
        }
      },
      {
        type: QuestionType.IMAGE,
        isChild: true,
        idx: ReportSurveyQuestions.pictureExplainingRefusal,
        parentIdx: ReportSurveyQuestions.VTValid,
        text: 'Photos explicatives du refus'
      },
      {
        type: QuestionType.BOOLEAN,
        isChild: false,
        idx: ReportSurveyQuestions.miseEnService,
        text: 'Mise en service ?'
      },
      {
        type: QuestionType.BOOLEAN,
        isChild: false,
        idx: ReportSurveyQuestions.demandeDP,
        text: 'Demande de DP ?'
      },
      {
        type: QuestionType.BOOLEAN,
        isChild: false,
        idx: ReportSurveyQuestions.demandeEnedis,
        text: 'Demande Enedis',
        children: {
          ['true']: [
            ReportSurveyQuestions.protectionChantier,
            ReportSurveyQuestions.detachementCable,
            ReportSurveyQuestions.planPicture
          ]
        }
      },
      {
        type: QuestionType.BOOLEAN,
        isChild: true,
        idx: ReportSurveyQuestions.protectionChantier,
        parentIdx: ReportSurveyQuestions.demandeEnedis,
        text: 'Protection chantier'
      },
      {
        type: QuestionType.BOOLEAN,
        isChild: true,
        idx: ReportSurveyQuestions.detachementCable,
        parentIdx: ReportSurveyQuestions.demandeEnedis,
        text: 'Détachement de cable'
      },
      {
        type: QuestionType.IMAGE,
        isChild: true,
        idx: ReportSurveyQuestions.planPicture,
        parentIdx: ReportSurveyQuestions.demandeEnedis,
        text: 'Photo des plans'
      },
      {
        type: QuestionType.BOOLEAN,
        isChild: false,
        idx: ReportSurveyQuestions.constructionSign,
        text: 'Panneau de chantier'
      },
      {
        type: QuestionType.BOOLEAN,
        isChild: false,
        idx: ReportSurveyQuestions.hasDatePose,
        text: 'Date de pose ?',
        children: {
          ['true']: [ReportSurveyQuestions.datePose]
        }
      },
      {
        type: QuestionType.DATE,
        isChild: true,
        idx: ReportSurveyQuestions.datePose,
        parentIdx: ReportSurveyQuestions.hasDatePose,
        text: 'Sélectionner une date de pose'
      },
      {
        type: QuestionType.NUMERIC,
        isChild: false,
        idx: ReportSurveyQuestions.numberOfDays,
        text: 'Nombre de jour'
      },
      {
        type: QuestionType.NUMERIC,
        isChild: false,
        idx: ReportSurveyQuestions.numberOfPeople,
        text: 'Nombre de personne'
      },
      {
        type: QuestionType.BOOLEAN,
        isChild: true,
        parentIdx: ReportSurveyQuestions.VTValid,
        idx: ReportSurveyQuestions.suggestAnotherProduct,
        text: 'Je suggère un autre produit conforme ?',
        children: {
          ['true']: [ReportSurveyQuestions.selectAnotherProduct]
        }
      },
      {
        type: QuestionType.SELECT,
        isChild: true,
        parentIdx: ReportSurveyQuestions.suggestAnotherProduct,
        idx: ReportSurveyQuestions.selectAnotherProduct,
        text: 'Sélectionner un autre produit conforme',
        select: {
          options: contractProducts.map((product) => ({
            label: product.name,
            value: product.id
          })),
          withOtherField: false
        }
      }
    ],
    []
  )

  // Prefill the survey if already answered according to Report data
  const rapportSurveyDefaultAnswers = useMemo(() => {
    if (![null, undefined].includes(rapport.vtValid)) {
      const result = []
      
      const mappingReportToSurvey = [
        [ReportSurveyQuestions.VTValid, rapport.vtValid],
        [ReportSurveyQuestions.miseEnService, rapport.inOperation],
        [ReportSurveyQuestions.demandeDP, rapport.dp],
        [ReportSurveyQuestions.demandeEnedis, rapport.isEnedis],
        [ReportSurveyQuestions.protectionChantier, rapport.protectionChantier],
        [ReportSurveyQuestions.detachementCable, rapport.detachementCable],
        [ReportSurveyQuestions.constructionSign, rapport.constructionSign],
        [
          ReportSurveyQuestions.hasDatePose,
          ![null, undefined].includes(rapport.posingDate)
        ],
        [
          ReportSurveyQuestions.datePose,
          ![null, undefined].includes(rapport.posingDate)
            ? rapport.posingDate
            : null
        ],
        [ReportSurveyQuestions.numberOfDays, rapport.nbDay],
        [ReportSurveyQuestions.numberOfPeople, rapport.nbPerson],
        [
          ReportSurveyQuestions.suggestAnotherProduct,
          rapport.invalidVtProductSuggestion !== null
        ],
        [
          ReportSurveyQuestions.selectAnotherProduct,
          rapport.invalidVtProductSuggestion ? parseInt(rapport.invalidVtProductSuggestion.split('/')[3]) : null
        ],
        [
          ReportSurveyQuestions.pictureExplainingRefusal,
          assignPictureQuestionDefaultValue(rapport.refusExplanThumb)
        ],
        [ReportSurveyQuestions.planPicture, assignPictureQuestionDefaultValue(rapport.enedisPlanThumb)]
      ]

      mappingReportToSurvey.forEach(([fieldIdx, rapportValue]) => {
        if (![null, undefined].includes(rapportValue)) {
          result.push({ idx: fieldIdx, value: rapportValue })
        }
      })

      return result
    } else {
      return []
    }
  }, [rapport])

  const formatImageCollection = (imageCollection, surveyImages) => {
    if (!surveyImages) {
      return null
    }
    
    const newFiles = surveyImages.map((surveyImage) => {
      return surveyImage['uri']
    })
    
    const newCollection = imageCollection ?
      {
        ...imageCollection,
        files: newFiles
      }
    :
      {
        files: newFiles
      }

    return newCollection
  }

  const onSave = async (questions, reponses) => {
    const formattedReponses = await formatQuestionnaireWithImages(questions, reponses)

    const getReponseValueByIdx = (reponseIdx) => {
      const result = formattedReponses.find(response => response.idx === reponseIdx)

      return result !== undefined ? result.value : undefined
    }

    const enedisPlanThumb = 
      formatImageCollection(rapport.enedisPlanThumb,
        getReponseValueByIdx(ReportSurveyQuestions.planPicture)
      )

    const refusExplanThumb = 
      formatImageCollection(rapport.refusExplanThumb,
        getReponseValueByIdx(ReportSurveyQuestions.pictureExplainingRefusal)
      )

    const newRapport = {
      id: rapport.id,
      vtValid: getReponseValueByIdx(ReportSurveyQuestions.VTValid),
      inOperation: getReponseValueByIdx(ReportSurveyQuestions.miseEnService),
      nbDay: getReponseValueByIdx(ReportSurveyQuestions.numberOfDays), 
      nbPerson: getReponseValueByIdx(ReportSurveyQuestions.numberOfPeople), 
      posingDate: getReponseValueByIdx(ReportSurveyQuestions.datePose), 
      dp: getReponseValueByIdx(ReportSurveyQuestions.demandeDP), 
      constructionSign: getReponseValueByIdx(ReportSurveyQuestions.constructionSign), 
      isEnedis: getReponseValueByIdx(ReportSurveyQuestions.demandeEnedis), 
      protectionChantier: getReponseValueByIdx(ReportSurveyQuestions.protectionChantier), 
      detachementCable: getReponseValueByIdx(ReportSurveyQuestions.detachementCable), 
      invalidVtProductSuggestion: getReponseValueByIdx(ReportSurveyQuestions.invalidVtProductSuggestion),
      enedisPlanThumb,
      refusExplanThumb,
    }

    try {
      updateRapport(newRapport)
      return true
    } catch (error) {
      return false
    }

    
  }
  

  return (
    <Survey
      questions={reportSurveyQuestions}
      defaultAnswers={rapportSurveyDefaultAnswers}
      onSave={onSave}
      saveImage={() => {}}
      deleteImage={() => {}}
    />
  )
}

export default RapportSurvey