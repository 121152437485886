import React from "react";
import CreateUserForm from "../../modules/Customers/create/CreateUserForm";

import { Card, Label, Text } from "../../components/WhComponent/index";

const UserHeader = ({ user, rows, loadUser }) => {
	if (!user) return <Card></Card>;

	return (
		<Card small>
			{rows.map((row, idx) => {
				return (
					<div key={`parent_${idx}`}>
						{row.map((el, idx) => {
							return (
								<div className="flex" key={`child_${idx}`}>
									<div className="w-1/3">
										<Label>{`${el.label} ${
											el.label !== "" ? ":" : ""
										}`}</Label>
									</div>
									<div className="w-2/3">
										<Text small color="black">
											{user[el.key]}
										</Text>
									</div>
								</div>
							);
						})}
					</div>
				);
			})}
			<CreateUserForm user={user} callback={loadUser} isUpdate />
		</Card>
	);
};

export default UserHeader;
