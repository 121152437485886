import React from "react";
import request from "superagent";
import * as session from "../../services/session";
import { toast } from "react-toastify";
import ApiConfig from "../../constants/ApiConfig";

const nl2br = (str, is_xhtml) => {
	const breakTag =
		is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
	return `${str}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
};

const prepareValidationError = (error) => {
	if (!error) {
		return error;
	}

	if (error["hydra:description"]) {
		if (error.violations) {
			toast.error("Le formulaire contient des erreurs");

			error._error = "";

			error.violations.map((el) => {
				/**
				 * Todo : Problème de validation sur les champs d'entité liée
				 * Il trouve bien l'erreur mais lors du focus sur le champ, il ne parvient pas à le vider et
				 * provoque une erreur
				 * En attentant on remonte tous les messages, mais les champs ne sont pas colorés
				 */

				error[el["propertyPath"]] = el.message;
				error._error += `${el.message}<br />`;

				// const test = el['propertyPath'].split('.');

				// if (test.length == 2) {
				// 	if (!error[test[0]]) error[test[0]] = [];
				// 	error[test[0]][test[1]] = el.message;
				// 	error._error += `${el.message  }<br />`;
				// } else {
				// }
			});
		} else {
			toast.error("Une erreur inconnue est survenue");

			error._error = error["hydra:description"];
		}
	} else {
		if (error.code && error.code == 401) {
			if (error.message == "Bad credentials") {
				error._error =
					"Vous n’avez pas été reconnu. Merci de réessayer ou de contacter votre administrateur. ";
			}
		}
	}

	return error;
};

export const fetch = (
	url,
	method = "GET",
	data = {},
	files = {},
	applicationJson = false
) =>
	new Promise((resolve, reject) => {
		method = method.toUpperCase();
		if (url && !url.match(/^http/)) {
			url = `${ApiConfig.url}${url}`;
		}
		let query = request(method, url);

		if (applicationJson) {
			query.accept("application/json");
		}

		switch (method) {
			case "GET":
				query.query(data);
				break;

			case "POST":
			case "PUT":
			case "DELETE":
				query.send(data);

				break;

			case "UPLOAD":
				query = request.post(url);

				Object.keys(files).forEach((key) => {
					const file = files[key];
					query.attach("file", file);
				});
				break;
		}

		const jwtToken = session.getJwtToken();

		if (jwtToken) {
			query.set("Authorization", `Bearer ${jwtToken}`);
		}

		query
			.then((data) => {
				if (data.body) resolve(data.body);
				else resolve(data.text);
			})
			.catch((error) => {
				if (error.response) {
					reject(prepareValidationError(error.response.body));
				} else {
					toast.error("Une erreur inconnue est survenue");
					reject(error);
				}
			});
	});

export default null;
