import React from "react";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import Label from "./Label";

const FieldCheckbox = (props) => {
	const {
		label,
		input,
		disabled,
		help,
		meta: { touched, error },
	} = props;

	return (
		<div className="mb-2">
			<div className="flex items-center">
				<input {...input} className="mb-2" type="checkbox" />

				<Label className="ml-2">{label}</Label>
			</div>
			{touched && error && (
				<div className="text-xs text-red-600">{error}</div>
			)}
			{help && <div className="">{help}</div>}
		</div>
	);
};

FieldCheckbox.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	disabled: PropTypes.bool,
	input: PropTypes.shape().isRequired,
	help: PropTypes.string,
	meta: PropTypes.shape({
		touched: PropTypes.bool,
		error: PropTypes.string,
	}),
};

FieldCheckbox.defaultProps = {
	label: false,
	disabled: false,
	help: null,
	meta: {
		touched: false,
		error: "",
	},
};

export default FieldCheckbox;
