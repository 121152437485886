import React, { Fragment , useState, useEffect} from "react";
import useModal from "/wh/common/modalHook/customModalHook";
import { Modal, Button } from "/wh/components/WhComponent/index";
import * as customerService from "../../../services/customer";
import { SubmissionError } from "redux-form";
import UserForm from "./Form";
import { history } from "../../../redux/store";

const CreateUserForm = ({ callback, isUpdate = false, user = null }) => {
	const { isShowing, toggle } = useModal();


	const [userForm, setUserForm] = useState(user);

	const onSubmit = (data) => {
		const finalService = isUpdate
			? customerService.update
			: customerService.create;

		return finalService(data)
			.then((response) => {
				toggle();
				if (callback) callback();
				history.push(`/customer_contracts/${response.id}`);
			})
			.catch((error) => {
				throw new SubmissionError(error);
			});
	};

	const onCancel = () => toggle();

	const modalTitle = isUpdate
		? "Editer"
		: "Créer un client";

	useEffect(() => {
		if (!isShowing) return;
		setUserForm(user)
	}, [user, isShowing])

	return (
		<div>
			<Button onClick={toggle} block>
				{modalTitle}
			</Button>
			<Modal
				visible={isShowing}
				onClose={toggle}
				size={"s"}
				title={modalTitle}
				customStyles={{
					height: "auto",
					maxHeight: "80%",
					overflow: "auto",
				}}
			>
				{isShowing &&
				<UserForm
					initialValues={userForm}
					onSubmit={onSubmit}
					onCancel={onCancel}
				/>
				}
			</Modal>
		</div>
	);
};

export default CreateUserForm;
