import React from 'react';
import { Col, FormGroup, FormText, Label, InputGroup, FormFeedback, Input } from 'reactstrap';

import classNames from 'classnames';

import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import MaskedFormControl from 'react-bootstrap-maskedinput';

export const SimpleInput = ({
								placeholder,
								name,
								input,
								label,
								type,
								help,
								meta: { touched, error },
							}) => (
	<Input
		placeholder={placeholder}
		{...input}
		type={type}
		{...(touched && error ? { valid: false } : {})}
	/>
);

export const FieldTxtIcon = ({
								 min,
								 disabled,
								 placeholder,
								 bsSize,
								 id,
								 mask,
								 prepend,
								 append,
								 name,
								 input,
								 label,
								 type,
								 help,
								 meta: { touched, error },
							 }) => (
	<FormGroup row>
		<Col md="3">{label && <Label>{label}</Label>}</Col>
		<Col xs="12" md="9">
			<InputGroup>
				{prepend && (
					<div className="input-group-prepend">
                            <span className="input-group-text">
                                {prepend.icon && <i className={prepend.icon} />}
								{!prepend.icon && prepend}
                            </span>
					</div>
				)}

				{mask && (
					<MaskedFormControl
						{...input}
						id={id}
						bsSize={bsSize}
						placeholder={placeholder}
						disabled={disabled}
						type={type}
						mask={mask}
					/>
				)}
				{!mask && (
					<Input
						{...input}
						min={min}
						id={id}
						bsSize={bsSize}
						placeholder={placeholder}
						disabled={disabled}
						type={type}
						className={classNames({ 'is-invalid': touched && error })}
					/>
				)}

				{append && (
					<div className="input-group-append">
                            <span className="input-group-text">
                                {append.icon && <i className={append.icon} />}
								{!append.icon && append}
                            </span>
					</div>
				)}
				{touched &&
				error && (
					<FormFeedback className="help-block" style={{ display: 'block' }}>
						{error}
					</FormFeedback>
				)}
			</InputGroup>
			{help && <FormText className="help-block">{help}</FormText>}
		</Col>
	</FormGroup>
);

export const FieldTxt = ({
							min,
							mask,
							name,
							input,
							label,
							onBlur,
							type,
							help,
							bsSize,
							placeholder,
							id,
							disabled,
							meta: { touched, error },
						}) => (
	<FormGroup row>
		<Col md="3">{label && <Label>{label}</Label>}</Col>
		<Col xs="12" md="9">
			{mask && (
				<MaskedFormControl
					{...input}
					id={id}
					bsSize={bsSize}
					placeholder={placeholder}
					disabled={disabled}
					type={type}
					className={classNames({ 'is-invalid': touched && error })}
					mask={mask}
				/>
			)}
			{!mask && (
				<Input
					{...input}
					min={min}
					id={id}
					bsSize={bsSize}
					placeholder={placeholder}
					disabled={disabled}
					type={type}
					className={classNames({ 'is-invalid': touched && error })}
				/>
			)}

			{touched && error && <FormFeedback className="help-block">{error}</FormFeedback>}
			{help && <FormText className="help-block">{help}</FormText>}
		</Col>
	</FormGroup>
);

export const FieldCheckbox = ({ name, input, label, type, meta: { touched, error } }) => (
	<FormGroup row>
		<Col md="3" />
		<Col xs="12" md="9">
			<input {...input} type="checkbox" /> <label htmlFor={name}>{label}</label>
			{touched && error && <span className="error-txt">{error}</span>}
		</Col>
	</FormGroup>
);

export const FieldSelect = ({
								valueField,
								labelField,
								nullValue,
								required = true,
								help,
								options,
								name,
								input,
								label,
								type,
								meta: { touched, error },
							}) => {
	if (!valueField) valueField = 'value';
	if (!labelField) labelField = 'name';

	return (
		<FormGroup row>
			<Col md="3">{label && <Label>{label}</Label>}</Col>
			<Col xs="12" md="9">
				<Input type="select" {...input} className={classNames({ 'is-invalid': touched && error })}>
					{!required && <option value="">{nullValue}</option>}
					{options.map((option, index) => (
						<option value={option[valueField]} key={index}>
							{option[labelField]}
						</option>
					))}
				</Input>
				{touched && error && <FormFeedback className="help-block">{error}</FormFeedback>}
				{help && <FormText className="help-block">{help}</FormText>}
			</Col>
		</FormGroup>
	);
};

export const FieldSelect2 = ({
  help,
  options,
  name,
  input,
  label,
  type,
	meta: { touched, error },
	isMulti = true,
  }) => (
    <FormGroup row>
      <Col md="3">{label && <Label>{label}</Label>}</Col>
      <Col xs="12" md="9">
        <Select
          value={input.value}
		  options={options}
          isMulti={isMulti}
          onChange={value => input.onChange(value)}
          onBlur={() => {
						if (isMulti) {
            	input.onBlur([...input.value]);
						}
          }}
        />
        {touched && error && <FormFeedback className="help-block" style={{ display: 'block' }}>
					{error}
				</FormFeedback>}
        {help && <FormText className="help-block">{help}</FormText>}
      </Col>
    </FormGroup>
);

export const FieldSelect3 = ({
								 help,
								 options,
								 name,
								 input,
								 label,
								 loadOptions,
								 type,
								 meta: { touched, error },
								 isMulti = true,
							 }) => {

	return(
		<FormGroup row>
			<Col md="3">{label && <Label>{label}</Label>}</Col>
			<Col xs="12" md="9">
				<AsyncSelect
					value={input.value}
					isMulti
					cacheOptions
					onChange={value => input.onChange(value)}
					defaultOptions
						onBlur={() => {
							if (isMulti) {
								input.onBlur([...input.value]);
							}
						}}
					loadOptions={loadOptions}
				/>
				{touched && error && <FormFeedback className="help-block" style={{ display: 'block' }}>
					{error}
				</FormFeedback>}
				{help && <FormText className="help-block">{help}</FormText>}
			</Col>
		</FormGroup>
	)};

export const FieldSelectAsync = ({
									 filterOptions,
									 help,
									 options,
									 multi =true,
									 loadOptions,
									 change,
									 name,
									 input,
									 label,
									 type,
									 meta: { touched, error },
								 }) => (
	<FormGroup row>
		<Col md="3">{label && <Label>{label}</Label>}</Col>
		<Col xs="12" md="9">
			<Select.Async
				name={name}
				placeholder="Chercher"
				noResultsText="Pas de résultat"
				searchPromptText="Commencez votre saisie"
				options={options}
				filterOptions={filterOptions}
				multi={multi}
				{...input}
				loadOptions={loadOptions}
				onChange={value => input.onChange(value)}
				onBlur={() => input.onBlur(input.value)}
				{...(touched && error ? { valid: false } : {})}
			/>
			{touched &&
			error && (
				<FormFeedback className="help-block" style={{ display: 'block' }}>
					{error}
				</FormFeedback>
			)}
			{help && <FormText className="help-block">{help}</FormText>}
		</Col>
	</FormGroup>
);
