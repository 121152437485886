import React, {Fragment, useEffect, useState} from 'react';
import { useSelector } from 'react-redux';

const ReduxLoader = ({children}) => {

	const status = useSelector(state => state.status)
  const [loadedParams, setLoadedParams] = useState(false);
	useEffect(() => {
		if (status && status.interventionTypes)
			setLoadedParams(true)
  }, [status])
	return <Fragment>
		{loadedParams && children}
	</Fragment>
}

export default ReduxLoader;
