import { fetch } from "../api";
import QueryBuilder from "./queryBuilder";

class ApiService {
	constructor(endPoint) {
		this.endPoint = endPoint;
	}

	search = (data) => {
		const query = QueryBuilder(data);

		return fetch(`${this.endPoint}`, "get", {
			...query,
		});
	};

	view = (id) => fetch(`${this.endPoint}/${id}`, "get");

	create = (data) => fetch(`${this.endPoint}`, "post", data);

	update = (data) => fetch(`${this.endPoint}/${data.id}`, "put", data);

	remove = (id) => fetch(`${this.endPoint}/${id}`, "delete");

	synchroniseProduct = () => fetch(`${this.endPoint}`, "get");

	/* const UPLOAD_FILE = `${BASE_API}files/upload`
	const UPLOADS_FOLDER = '/uploads'
	export const FILE_BASE_URL = `${CONFIG.BASE_URL}${UPLOADS_FOLDER}` */

	uploadFile = async (file, type = null) => {
		const fileNameSegments = file.name.split(".");
		const fileExtension = fileNameSegments[fileNameSegments.length - 1];
		const blob = file.slice(0, file.size, file.type);

		const randomName = Math.random().toString(36).substring(10);
		const newFile = new File(
			[blob],
			`${fileNameSegments[0]}_${randomName}.${fileExtension}`
		);

		const result = await fetch(
			`${this.endPoint}`,
			"UPLOAD",
			{ type },
			{ newFile }
		);

		return {
			uri: result["@id"],
			url: result.url,
		};
	};
}

export default ApiService;
