import React from "react";
import Form from "./Form";
import { reduxForm } from "redux-form";

const FormWrapper = ({ handleSubmit, children, className, initialValues }) => {
	return (
		<Form
			onSubmit={handleSubmit}
			className={className}
			initialvalues={initialValues}
		>
			{children}
		</Form>
	);
};

export default reduxForm()(FormWrapper);
