import React, { Component, createRef } from "react";
import WHTable from "../../components/WHTable";
import {
	Page,
	PageHeader,
	PageTitle,
	H1,
	Button,
	Text,
} from "../../components/WhComponent/index";

import * as customerService from "../../services/customer";
import CreateUserModal from "/wh/modules/Customers/create/CreateUserModal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

class Customers extends Component {
	state = {
		data: [],
		loaded: true,
	};
	createPopup = createRef();

	getName = (obj) => {
		const { firstname, lastname, name } = obj;
		if (!firstname && !lastname) return name;
		return `${firstname} ${lastname}`;
	};

	async remove(toDelete) {
		if (confirm("Etes vous sûre de vouloir supprimer cet client ?")) {
			try {
				await customerService.remove(toDelete);

				const lst = this.state.data;

				const index = lst.findIndex((el) => el.id == toDelete);

				if (index > -1) {
					lst.splice(index, 1);

					this.setState({ data: lst });
				}

				toast.success("client supprimé");
			} catch (error) {
				toast.error("Une erreur est survenue");
			}
		}
	}

	columns = () => [
		{
			Header: "#",
			accessor: "id",
			Cell: (row, index) => (
				<div key={index}>
					<Button
						small
						color="warning"
						className="mr-2"
						to={`/customer_contracts/${row.original.id}/interventions`}
					>
						<i className="fa fa-eye" />
					</Button>
					<Button
						small
						color="danger"
						onClick={() => this.remove(row.value)}
					>
						<i className="fa fa-trash" />
					</Button>
				</div>
			),
		},

		{
			Header: "extRef",
			accessor: "extRef",
		},
		{
			Header: "Nom",
			accessor: "name",
		},
		{
			Header: "Code postal",
			accessor: "zipcode",
		},
		{
			Header: "Ville",
			accessor: "city",
		},
		{
			Header: "Téléphone fixe",
			accessor: "phone",
		},
		{
			Header: "Téléphone portable",
			accessor: "mobile",
		},
	];

	load = (state) => {
		this.setState({ loaded: false });

		return customerService.search(state).then((data) => {
			this.setState({
				data: data["hydra:member"],
				loaded: true,
			});

			if (state) {
				this.setState({
					pages: Math.ceil(data["hydra:totalItems"] / state.pageSize),
				});
			}
		});
	};

	openUserCreate = () => {
		this.refs.popupCreate.handleOpen();
	};
	newData = (data) => {
		const { lst } = this.state;

		const index = lst.findIndex((el) => el.id == data.id);

		if (index > -1) {
			lst[index] = data;
		} else {
			lst.push(data);
		}

		this.setState(lst);
	};

	render() {
		const { data, pages } = this.state;

		console.log({ data });

		return (
			<Page>
				{/*<CreateUserPopup ref={"popupCreate"} newData={this.newData} />*/}

				<PageHeader>
					<PageTitle>
						<H1>Gestion des clients</H1>

						<CreateUserModal
							title={"Chercher"}
							load={this.load}
							history={this.props.history}
						/>
					</PageTitle>
					<Text>Chercher, filtrer des clients</Text>
				</PageHeader>

				<WHTable
					data={data}
					pages={pages}
					columns={this.columns()}
					filterable
					onFetchData={this.load}
					loading={!this.state.loaded}
				/>
			</Page>
		);
	}
}

export default Customers;
