import React, { Fragment } from "react";
import { formatDate } from "/wh/utils/momentUtils";
import useModal from "/wh/common/modalHook/customModalHook";
import FacturationModalContent from "./ModalsForm/FacturationModalContent";
import { parseDate } from "/wh/utils/parseForm";
import { SubmissionError } from "redux-form";
import ApiService from "/wh/services/apiSearch/apiSearch";
import { APIENUM } from "/wh/services/ApiEnum";
import {
	Card,
	CardHeader,
	CardTitle,
	Modal,
	Button,
	Label,
	Value,
	H3,
} from "/wh/components/WhComponent/index";

const Facturation = ({ amount, reload, bankFolder, infos }) => {
	const { isShowing, toggle } = useModal();
	const initialValues = bankFolder
		? {
				dateReception: bankFolder.dateReception
					? parseDate(bankFolder.dateReception)
					: null,
				dateFinancement: bankFolder.dateFinancement
					? parseDate(bankFolder.dateFinancement)
					: null,
				dateAcceptation: bankFolder.dateAcceptation
					? parseDate(bankFolder.dateAcceptation)
					: null,
		  }
		: {};

	const onSubmit = (data) => {
		const apiService = new ApiService(`/api/${APIENUM.FACTURATION}`);

		apiService
			.update({ ...data, id: bankFolder.id })
			.then((result) => {
				toggle();

				reload();
			})
			.catch((error) => {
				throw new SubmissionError(error);
			});
	};

	return (
		<Card small>
			<CardHeader>
				<CardTitle>
					<H3>Facturations</H3>

					<Button outline onClick={toggle}>
						<i className="fa fa-edit" /> Modifier
					</Button>
				</CardTitle>
			</CardHeader>

			{bankFolder && (
				<div>
					<div className="flex">
						<Label className="w-1/3">
							Maison de financement :{" "}
						</Label>
						<Value className="w-2/3">{bankFolder.bankName}</Value>
					</div>
					<div className="flex">
						<Label className="w-1/3">Date de la vente : </Label>
						<Value className="w-2/3">
							{formatDate(bankFolder.created, "/")}
						</Value>
					</div>
					<div className="flex">
						<Label className="w-1/3">Montant de la vente:</Label>
						<Value className="w-2/3">{amount}€</Value>
					</div>

					<div className="flex">
						<Label className="w-1/3">
							Date de reception de dossier :{" "}
						</Label>
						<Value className="w-2/3">
							{bankFolder.dateReception
								? formatDate(bankFolder.dateReception, "/")
								: ""}
						</Value>
					</div>

					<div className="flex">
						<Label className="w-1/3">
							Date d'envoie de financement :{" "}
						</Label>
						<Value className="w-2/3">
							{bankFolder.dateFinancement
								? formatDate(bankFolder.dateFinancement, "/")
								: ""}
						</Value>
					</div>

					<div className="flex">
						<Label className="w-1/3">
							Date d'acception de financement :{" "}
						</Label>
						<Value className="w-2/3">
							{bankFolder.dateAcceptation
								? formatDate(bankFolder.dateAcceptation, "/")
								: ""}
						</Value>
					</div>

					<div className="flex">
						<Label className="w-1/3">Paiment comptant :</Label>
						<Value className="w-2/3">
							{infos &&
								infos.order &&
								infos.order["amount"] -
									infos.order["total-credit"]}
							€
						</Value>
					</div>
				</div>
			)}
			<Modal visible={isShowing} onClose={toggle} title="Financements">
				<FacturationModalContent
					initialValues={initialValues}
					onSubmit={onSubmit}
					toggle={toggle}
				/>
			</Modal>
		</Card>
	);
};

export default Facturation;
