import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { debounce } from "lodash";

class WHTable extends Component {
	constructor(props) {
		super(props);
	}

	filterMethod(filter, row, column) {
		console.log({ filter, row, column });

		const id = filter.pivotId || filter.id;

		if (row[id] === undefined || !row[id]) return true;
		if (filter.value === undefined || !filter.value) return true;

		const value = String(row[id].toLowerCase());
		const search = String(filter.value.toLowerCase());

		if (value.indexOf(search) > -1) return true;

		return false;
	}

	render() {
		const {
			defaultFiltered,
			data,
			pages,
			loading,
			columns,
			filterable,
			className,
			onRowClick = null,
			onFetchData = () => {},
		} = this.props;

		return (
			<ReactTable
				manual
				defaultFilterMethod={this.filterMethod}
				data={data}
				pages={pages}
				loading={loading}
				columns={columns}
				getTdProps={(state, rowInfo) => {
					return {
						className: "pointer",
						onClick: (event, handleOriginal) => {
							if (rowInfo && onRowClick) {
								onRowClick(rowInfo);
							}

							if (handleOriginal) {
								handleOriginal();
							}
						},
					};
				}}
				defaultFiltered={defaultFiltered}
				filterable={filterable}
				onFetchData={debounce(onFetchData, 200)}
				className={className ? className : "-striped -highlight"}
				{...this.props}
			/>
		);
	}
}

export default WHTable;
