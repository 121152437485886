import React, { useState, useEffect } from 'react';
import moment from 'moment/moment'

export const useDate = () => {

	const [rowDate, setDate] = useState(new Date())
	const [rawMomentDate, setRawMomentDate] = useState(null);
	const [type, setType] = useState('days');
	const [dateFilters, setDateFilters]= useState([]);
	const [date, setDisplayDate] = useState(null)

	const setFilters = (_rowMomentDate, type) => {
		const start = _rowMomentDate.clone().startOf(type).format(`YYYY${'-'}MM${'-'}DD`);
		const end = _rowMomentDate.clone().endOf(type).format(`YYYY${'-'}MM${'-'}DD`);
		const filters = [{id:'startDate[after]', value:start}]
		filters.push({id:'startDate[before]', value:end})
		setDateFilters(filters);
	}

	const setFilterForDay = (_rawMomentDate) => {
		const newDate = moment(_rawMomentDate)
		const filters = [{id:'startDate[after]', value:newDate.format(`YYYY${'-'}MM${'-'}DD`)}]
		filters.push({id:'startDate[before]', value:newDate.clone().add(1, 'day').format(`YYYY${'-'}MM${'-'}DD`)})
		setDateFilters(filters);

	}
	const getWeekInterval = (_rawMomentDate) => {
		setFilters(_rawMomentDate, 'week');
		const start = _rawMomentDate.clone().startOf('week').format('Do');
		const end = _rawMomentDate.clone().endOf('week').format('Do MMMM YYYY');
		return `${start} au ${end}`
	}

	const getMonthInterval = (_rawMomentDate) => {
		setFilters(_rawMomentDate, 'month');
		const start = _rawMomentDate.clone().startOf('month').format('Do');
		const end = _rawMomentDate.clone().endOf('month').format('Do MMMM YYYY');
		return `${start} au ${end}`
	}

	const getDateDay = (newRowDate) => {
		const _date = moment(newRowDate)
		setFilterForDay(newRowDate);
		const newDate = _date.format('dddd Do MMMM YYYY');
		return newDate;
	}

	const add = (type) => {
		const newDate = moment(rawMomentDate, "DD.MM.YYYY").clone().add(1, type);

		if (type === 'days') {
			setDisplayDate(getDateDay(newDate))
		}
		if (type === 'weeks') {
			setDisplayDate(getWeekInterval(newDate))
		}
		if (type === 'months') {
			setDisplayDate(getMonthInterval(newDate))
		}
		setRawMomentDate(newDate);
	}

	const substract = (type) => {
		const newDate = moment(rawMomentDate, "DD.MM.YYYY").clone().subtract(1, type);
		if (type === 'days') {
			setDisplayDate(getDateDay(newDate))
		}
		if (type === 'weeks') {
			setDisplayDate(getWeekInterval(newDate))
		}
		if (type === 'months') {
			setDisplayDate(getMonthInterval(newDate))
		}
		setRawMomentDate(newDate);
	}

	const changeType = (type) => {
		setType(type);
	}

	const onUpdateType = (type) => {
			if (type === 'days')
			{
				const _date = rawMomentDate ? rawMomentDate.format('dddd Do MMMM YYYY') : (rowDate ? getDateDay(rowDate) : '');
				setFilterForDay(rawMomentDate ? rawMomentDate : rowDate)
				setDisplayDate(_date);
			}
			if (type === 'weeks')
				setDisplayDate(getWeekInterval(rawMomentDate));
			if (type === 'months')
				setDisplayDate(getMonthInterval(rawMomentDate));
	}


	useEffect(() => {
		const _date = moment(rowDate)
		setRawMomentDate(_date);
		const newDate = _date.format('dddd Do MMMM YYYY');
		setDisplayDate(newDate);
	}, [])

	useEffect(() => {
		onUpdateType(type);
	}, [type])

	return {
		date,
		changeType,
		add,
		substract,
		dateFilters
	}
}
