import React, { useMemo } from "react";
import { Field, reduxForm } from "redux-form";
import * as validator from "/wh/components/Form/wh-validator";
import { APIENUM } from "/wh/services/ApiEnum";
import { useEffect } from "react";
import useSearch from "../../../services/apiSearch/searchHook";

import {
	Button,
	FormActions,
	FieldTxt,
	FieldSelect,
	FieldCheckbox,
	Alert,
	Form,
} from "/wh/components/WhComponent/index";

const UserForm = ({ handleSubmit, submitting, roleGroups, toggle }) => {
	const getROles = () => {
		const opts = [];
		const roleGroupKeys = Object.keys(roleGroups);

		for (const key of roleGroupKeys) {
			roleGroups[key].map((el) => {
				opts.push({
					name: el.value,
					value: el.value,
				});
			});
		}

		return opts;
	};

	const { data: teamDatas, load: loadTeamDatas } = useSearch(APIENUM.TEAM, [
		{ id: "pagination", value: false },
	]);

	useEffect(() => {
		loadTeamDatas();
	}, []);

	const teamOptions = useMemo(() => {
		return teamDatas
			? teamDatas.map((team) => ({ name: team.name, value: team["@id"] }))
			: [];
	}, [teamDatas]);

	return (
		<Form onSubmit={handleSubmit}>
			{/*{error && (*/}
			{/*	<Alert color="danger">*/}
			{/*		<div dangerouslySetInnerHTML={{ __html: error }} />*/}
			{/*	</Alert>*/}
			{/*)}*/}

			<Field
				label="Prénom : "
				inline={true}
				name="firstname"
				component={FieldTxt}
				type="text"
				validate={[validator.required]}
			/>
			<Field
				label="Nom : "
				inline={true}
				name="lastname"
				inline={true}
				component={FieldTxt}
				type="text"
				validate={[validator.required]}
			/>
			<Field
				label="Email : "
				inline={true}
				name="email"
				component={FieldTxt}
				type="text"
				validate={[validator.required]}
			/>
			<Field
				label="Mobile : "
				inline={true}
				name="mobile"
				component={FieldTxt}
				type="text"
			/>
			<Field
				label="Job : "
				name="job"
				inline={true}
				component={FieldTxt}
				type="text"
			/>
			<Field
				label="Login : "
				inline={true}
				name="username"
				component={FieldTxt}
				type="text"
				validate={[validator.required]}
			/>

			<Field
				label="Rôles : "
				inline={true}
				name="roles"
				component={FieldSelect}
				multiple={true}
				type="select"
				options={getROles()}
			/>

			<Field
				label="Team : "
				inline={true}
				name="team"
				component={FieldSelect}
				type="select"
				options={teamOptions}
				required={false}
			/>

			<Field
				label="Activé"
				name="enabled"
				type="checkbox"
				component={FieldCheckbox}
			/>

			{/*{error && (*/}
			{/*	<Alert color="danger">*/}
			{/*		<div dangerouslySetInnerHTML={{ __html: error }} />*/}
			{/*	</Alert>*/}
			{/*)}*/}

			<FormActions>
				<Button color="transparent" onClick={toggle}>
					Annuler
				</Button>
				<Button type="submit" loading={submitting}>
					<i className="fa fa-save" /> Enregistrer
				</Button>
			</FormActions>
		</Form>
	);
};

export default reduxForm({ form: "UserForm" })(UserForm);
