import React, { useState, useEffect } from "react";
import DateFilter from "./Components/DateFilter";
import DisplayDate from "./Components/DisplayDate";
import { useSelector } from "react-redux";
import WHTable from "/wh/components/WHTable";
import useSearch from "/wh/services/apiSearch/searchHook";
import { APIENUM } from "/wh/services/ApiEnum";
import { formatDate, extractHour } from "/wh/utils/momentUtils";
import Filter from "/wh/modules/Planning/Components/Filter";
import ApiService from "/wh/services/apiSearch/apiSearch";

import {
	Page,
	PageHeader,
	PageTitle,
	H1,
	Card,
} from "../../components/WhComponent/index";

const Planning = (props) => {
	const [dayType, setDayType] = useState("days");

	const { data, setFilters, load } = useSearch(
		APIENUM.INTERVENTION,
		[],
		false
	);
	const [sectors, setSectors] = useState([]);
	const [technicians, setTechnicians] = useState([]);
	const status = useSelector((state) => state.status);

	useEffect(() => {
		const sectorService = new ApiService(`/api/${APIENUM.SECTOR}`);
		sectorService
			.search({})
			.then((data) => {
				setSectors(data["hydra:member"]);
			})
			.catch((e) => {
				console.error(e);
			});
		const userService = new ApiService(`/api/${APIENUM.USER}`);
		userService
			.search({ filtered: [{ id: "roles", value: "ROLE_TECHNICIAN" }] })
			.then((data) => {
				setTechnicians(data["hydra:member"]);
			})
			.catch((e) => {
				console.error(e);
			});
	}, []);

	const updateFilter = (filters) => {
		setFilters(filters);
	};

	const setFilterDayType = (type) => {
		setDayType(type);
	};

	const getStatusColor = (value) => {
		if (!status) return;
		const stat = status.interventionStatus.find((st) => st.value === value);
		return `intervention_status_color intervention_status_color-${stat.color}`;
	};

	const getStatusName = (value) => {
		if (!status) return;
		const stat = status.interventionStatus.find((st) => st.value === value);
		return stat.name;
	};

	const columns = [
		{
			Header: "Statut",
			accessor: "state",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			filterable: false,
			Cell: (row) => (
				<div>
					<span className={getStatusColor(row.value)}>
						{getStatusName(row.value)}
					</span>
				</div>
			),
		},
		{
			Header: "Conformité",
			accessor: "conformity",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			Cell: (row) => (
				<div>
					{row.value ? (
						<span className="intervention_status_color intervention_status_color-blue">
							Conforme
						</span>
					) : (
						<span className="intervention_status_color intervention_status_color-red">
							Non Conforme
						</span>
					)}
				</div>
			),
		},
		{
			Header: "Client",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			filterable: false,
			Cell: (row) => (
				<div>
					<span>
						{row.original.contract
							? row.original.contract.customer.lastname
							: ""}
					</span>
				</div>
			),
		},
		{
			Header: "Ville",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			filterable: false,
			Cell: (row) => (
				<div>
					<span>
						{row.original.contract
							? row.original.contract.customer.city
							: ""}
					</span>
				</div>
			),
		},
		{
			Header: "Produit",
			accessor: "product",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			Cell: (row) => {
				return (
					<div>
						{row.value ? (
							<span>{row.value.name}</span>
						) : (
							<span></span>
						)}
					</div>
				);
			},
		},
		{
			Header: "Type",
			accessor: "type.label",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			filterable: true,
			/* Cell: (row) => (
				<div>
					<span>
						{row.value && row.value.label
							? row.value.label
							: "noInter"}
					</span>
				</div>
			), */
			Filter: ({ filter, onChange }) => (
				<select
					onChange={(event) => {
						//@todo pas propre, mauvaise structure de donnée mais cela fonctionne
						if (event.target.value !== "")
							onChange(event.target.value);
						else {
							onChange({ id: "state", value: "" });
						}
					}}
					style={{ width: "100%", height: "45px" }}
					value={filter ? filter.value : ""}
				>
					<option value="">Tous</option>
					{status &&
						status.interventionTypes.map((el, index) => (
							<option value={el.value} key={index}>
								{el.label}
							</option>
						))}
				</select>
			),
		},
		{
			Header: "Technicien",
			accessor: "technicien",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			Cell: (row) => (
				<div>
					<span className={"intervention_metreur"}>
						{row.value ? row.value.firstname : ""}
					</span>
				</div>
			),
		},
		{
			Header: "Date début",
			accessor: "startDate",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			Cell: (row) => (
				<div>
					{formatDate(row.value, "/")} {extractHour(row.value)}
				</div>
			),
		},
		{
			Header: "Date de fin",
			accessor: "endDate",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			Cell: (row) => (
				<div>
					{formatDate(row.value, "/")} {extractHour(row.value)}
				</div>
			),
		},
		{
			Header: "Secteur",
			accessor: "sector",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			Cell: (row) => <div>{row.value && row.value.name}</div>,
		},
		{
			Header: "Planning",
			accessor: "dp",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			Cell: (row) => {
				const printBoolean = (value) => (value ? "oui" : "non");

				return (
					<div>
						{printBoolean(
							row.original.rapport
								? row.original.rapport.dp
								: false
						)}
					</div>
				);
			},
		},
	];

	return (
		<Page>
			<PageHeader>
				<PageTitle>
					<H1>Planning</H1>
				</PageTitle>
			</PageHeader>

			<Card>
				<div className="flex mb-5 justify-between">
					<DisplayDate type={dayType} onUpdate={setFilters} />
					<DateFilter onUpdate={setDayType} dayType={dayType} />
				</div>

				<div className="flex">
					{status && (
						<Filter
							filter_name={"state"}
							label="Statut"
							options={status.interventionStatus}
							onUpdate={updateFilter}
						/>
					)}
					<Filter
						filter_name={"conformity"}
						label="Conformité"
						options={[
							{ name: "Conforme", value: 1 },
							{ name: "Non Conforme", value: 0 },
						]}
						onUpdate={updateFilter}
					/>
					<Filter
						filter_name={"sector.name"}
						label="Secteurs"
						options={sectors.map((s) => ({
							name: s.name,
							value: s.name,
						}))}
						onUpdate={updateFilter}
					/>
					<Filter
						filter_name={"technicien.firstname"}
						label="Technicien"
						options={technicians.map((s) => ({
							name: s.firstname,
							value: s.firstname,
						}))}
						onUpdate={updateFilter}
					/>
					<Filter
						filter_name={"rapport.dp"}
						label="DP"
						options={[
							{ name: "Oui", value: 1 },
							{ name: "Non", value: 0 },
						]}
						onUpdate={updateFilter}
					/>
				</div>
			</Card>

			<WHTable
				key={"table"}
				data={data}
				columns={columns}
				getTrProps={(_state, rowInfo) => {
					return {
						onClick: () =>
							props.history.push(
								`/customer_contracts/${rowInfo.original.contract.customer.id}/interventions/${rowInfo.original.id}`
							),
					};
				}}
				className="intervention_table"
				onFetchData={(state) => {
					setFilters(state.filtered);
				}}
			/>
		</Page>
	);
};

export default Planning;
