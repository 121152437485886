import React, { useState } from "react";
import {
	Button,
	Page,
	PageHeader,
	PageTitle,
	H1,
	Text,
	PageActions,
	Card,
} from "/wh/components/WhComponent/index";
import useQuestionnaire from "./useQuestionnaire";
import QuestionElement, { DependenciesManager } from "./QuestionElement";
import ApiService from "/wh/services/apiSearch/apiSearch";
import { APIENUM } from "/wh/services/ApiEnum";
import { Redirect } from "react-router-dom";

const QuestionnaireEdition = ({ match, history }) => {
	const {
		questions,
		questionnaire,
		up,
		down,
		deleteQuestion,
		createQuestion,
		updateQuestionType,
		updateQuestionOptions,
		updateAnswerDependencies,
		updateQuestionDependencies,
	} = useQuestionnaire(match.params.id);

	const [redirect, setRedirect] = useState(false);
	const submitQ = () => {
		const formatedQuestions = JSON.stringify(questions);
		const qcmService = new ApiService(`/api/${APIENUM.QCM}`);
		qcmService
			.update({ id: match.params.id, questionnaire: formatedQuestions })
			.then((data) => {
				setRedirect(true);
			});
	};

	const renderRedirect = () => {
		if (redirect) {
			return <Redirect to={`/questionnaires/${match.params.id}/view`} />;
		}
	};

	const goBack = () => {
		history.push(`/questionnaires/${match.params.id}/view`);
	};

	return (
		<Page>
			<PageHeader>
				<PageTitle>
					<H1>{questionnaire.name}</H1>
					<PageActions>
						<Button onClick={() => goBack()} outline>
							<i className="fa fa-chevron-left" /> Retour à la
							liste
						</Button>
					</PageActions>
				</PageTitle>

				<Text>Modifier votre formulaire</Text>
			</PageHeader>

			{questions.map((q, idx) => {
				return (
					<Card key={`question-creator-${idx}`}>
						<QuestionElement
							up={up}
							down={down}
							onDelete={deleteQuestion}
							question={q}
							onUpdate={updateQuestionType}
							onUpdateOptions={updateQuestionOptions}
							questionIdx={idx}
							first={idx === 0}
							last={idx === questions.length-1}
						/>
						{idx > 0 && (
							<DependenciesManager
								currentQuestion={q}
								questions={questions}
								onUpdateQuestion={updateQuestionDependencies}
								onUpdateAnswer={updateAnswerDependencies}
								questionIdx={q.idx}
							/>
						)}
					</Card>
				);
			})}

			<Button onClick={createQuestion} outline block className="mb-5">
				<i className="fa fa-plus" /> Ajouter une Question
			</Button>

			<Button onClick={submitQ} block>
				<i className="fa fa-save" /> Enregistrer le questionnaire
			</Button>

			{renderRedirect()}
		</Page>
	);
};

export default QuestionnaireEdition;
