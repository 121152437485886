import React, { Component } from "react";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import * as sessionService from "../../services/session";

class HeaderDropdownAccount extends Component {
	state = { dropdownOpen: false };

	toggle = () => this.setState({ dropdownOpen: !this.state.dropdownOpen });

	logout = () => sessionService.logout().then(() => this.goTo("/login"));

	goTo = (route) => this.props.history.push(route);

	render() {
		return (
			<div className="dropdown relative inline-block text-left mr-10">
				<button className="inline-flex justify-center w-full px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150">
					Paramètres
				</button>

				<ul className="dropdown-menu absolute hidden text-gray-700 pt-1 right-0 left-auto">
					<li className="">
						<NavLink
							className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap flex text-gray-700"
							to="/account"
						>
							<div className="mr-5" style={{ width: 16 }}>
								<i className="fa fa-user" />
							</div>
							<div>Profil</div>
						</NavLink>
					</li>
					<li className="">
						<NavLink
							className="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-gray-700 flex"
							to="/account/password"
						>
							<div className="mr-5" style={{ width: 16 }}>
								<i className="fa fa-key" />
							</div>
							<div>Changer de mot de passe</div>
						</NavLink>
					</li>
					<li className="">
						<a
							className="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap text-gray-700 flex"
							href="#"
							onClick={() => this.logout()}
						>
							<div className="mr-5" style={{ width: 16 }}>
								<i className="fa fa-lock" />
							</div>
							<div>Se déconnecter</div>
						</a>
					</li>
				</ul>
			</div>
		);
	}
}

export default withRouter(HeaderDropdownAccount);
