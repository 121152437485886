import React from "react";

export default ({ onChange, checked = false }) => {
	return (
		<input
			type="checkbox"
			className={`mb-2 mr-10`}
			checked={checked}
			onChange={onChange}
		/>
	);
};
