import createMethods from "/wh/utils/createMethods";
import { fetch } from "/wh/services/api";
import configApi from "../../constants/ApiConfig";

const endPoints = "/api/contract-messages";
const endPointsJun = "/api/contract-messages-junior";
const endPointsSen = "/api/contract-messages-senior";
const endPointsEco = "/api/contract-messages-eco";
const endPointsTech = "/api/contract-messages-tech";

export const { search, view, create, update, remove } = createMethods(
	endPoints,
);

export const sendSenior = data =>
	fetch(`${configApi.url}${endPointsSen}`, "post", data);
export const sendJunior = data =>
	fetch(`${configApi.url}${endPointsJun}`, "post", data);
export const sendEco = data =>
	fetch(`${configApi.url}${endPointsEco}`, "post", data);
export const sendTech = data =>
	fetch(`${configApi.url}${endPointsTech}`, "post", data);
