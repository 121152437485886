import React, { Component, createRef } from "react";
import Loading from "@whatsonweb/loading";
import Moment from "react-moment";
import { connect } from "react-redux";

import { Card, CardHeader, Table, Button } from "reactstrap";

import * as eventService from "/wh/services/event";
import * as memberService from "/wh/services/member";

import CreateEventPopup from "../../../CreateEventPopup";
import PreAccordPopup from "./Popup/PreAccord";

import PropTypes from "prop-types";

class EcoHabitant extends Component {
	state = {
		loaded: true,
		data: {},
		events: [],
		techniciens: [],
	};

	eventPopup = createRef();
	preaccord = createRef();

	componentDidMount() {
		this.load();
	}

	submit = async () => {
		await this.load();
		await this.props.onReload();
	};

	load = async () => {
		const { data, status } = this.props;

		try {
			const eventPromiseEvent = eventService.search({
				"contract.id": data["id"],
				statusFamily: status.statusFamily["ECO"],
			});
			const eventPromiseTechn = memberService.search({
				filtered: [{ id: "roles", value: "ROLE_TECHNICIAN" }],
			});

			Promise.all([eventPromiseEvent, eventPromiseTechn]).then(values => {
				const [events, technicians] = values;
				this.setState({
					events: events["hydra:member"],
					technicians: technicians["hydra:member"],
					loaded: true,
				});
			});
		} catch (error) {
			console.error(error);
		}
	};

	render() {
		const { status } = this.props;
		const { technicians, loaded } = this.state;

		if (!loaded) return <Loading />;

		return (
			<Card>
				<CreateEventPopup
					ref={this.eventPopup}
					type={status.statusFamily.ECO}
					status={status.ecoStatus}
					onSubmit={this.submit}
					technicians={technicians}
				/>
				<PreAccordPopup
					ref={this.preaccord}
					onSubmit={this.submitPreaAccord}
					data={this.state.data}
					{...this.props}
				/>

				<CardHeader>
					<i className="fa fa-leaf" /> Eco Habitant
					<div className="card-actions">
						<Button
							onClick={() =>
								this.eventPopup.current
									// .getWrappedInstance()
									.handleOpen(this.props.data)
							}
						>
							<i className="fa fa-plus" /> Ajouter un évenement
						</Button>
					</div>
				</CardHeader>

				<Table responsive striped hover>
					<thead className="thead-light">
						<tr>
							<th />
							<th>Date</th>
							<th>Nom</th>
							<th>Commentaire</th>
						</tr>
					</thead>
					<tbody>
						{this.state.events.map(
							({ id, date, statusName, comment }) => (
								<tr key={id}>
									<td>#{id}</td>
									<td>
										<Moment format="DD/MM/YYYY">
											{date}
										</Moment>
									</td>
									<td>{statusName}</td>
									<td>{comment}</td>
								</tr>
							),
						)}
					</tbody>
				</Table>
			</Card>
		);
	}
}

EcoHabitant.propTypes = {
	onReload: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ status: state.status });

export default connect(mapStateToProps)(EcoHabitant);
