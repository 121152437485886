import React, { Fragment } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Form, ModalBody, Alert } from 'reactstrap';
import { ModalLaddaSaveButton } from '@whatsonweb/ladda-save-button';
import { FieldTxt, FieldSelect } from '../../../../../components/Form/wh-field';
import * as validator from "/wh/components/Form/wh-validator";

const FormEventPopup = ({
	status,
	error,
	handleSubmit,
	submitting,
	currentValues,
	technicians,
}) => (
	<Form onSubmit={handleSubmit} className="form-vertical">
		<ModalBody>
			<Field
				label="Evènement : "
				name="status"
				component={FieldSelect}
				labelField="label"
				nullValue="Choisir un statut"
				validate={[validator.required]}
				required={false}
				options={status}
				type="select"
			/>
			{currentValues.status && currentValues.status == 24 && (
				<Fragment>
					<Field label="Date : " name="fixed" component={FieldTxt} type="date" />
					<Field label="Heure : " name="fixedHour" component={FieldTxt} type="time" />
					<Field
						label="technicien : "
						name="fixedTo"
						required={false}
						nullValue="Choisir un technicien"
						labelField="name"
						valueField="@id"
						component={FieldSelect}
						options={technicians}
						type="select"
					/>
				</Fragment>
			)}

			<Field label="Commentaire : " name="comment" component={FieldTxt} type="textarea" />

			{error && (
				<Alert color="danger">
					<div dangerouslySetInnerHTML={{ __html: error }} />
				</Alert>
			)}
		</ModalBody>
		<ModalLaddaSaveButton submitting={submitting} />
	</Form>
);

FormEventPopup.defaultProps = {
	error: '',
	technicians: [],
	currentValues: {},
};

FormEventPopup.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	submitting: PropTypes.bool.isRequired,
	error: PropTypes.string,
	status: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	technicians: PropTypes.arrayOf(PropTypes.shape({})),
	currentValues: PropTypes.shape({}),
};

const formName = 'FormEventPopup';

export const mapStateToProps = state => ({
	currentValues: getFormValues(formName)(state),
});

export default compose(
	reduxForm({ form: formName }),
	connect(mapStateToProps)
)(FormEventPopup);
