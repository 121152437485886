import React, { Fragment, useEffect, useState } from "react";
import WHTable from "/wh/components/WHTable";
import * as contractService from "/wh/services/contract";
import { formatDate } from "/wh/utils/momentUtils";
import { Redirect } from "react-router-dom";
import { Button } from "/wh/components/WhComponent/index";

const ContractList = ({ id, match }) => {
	const [selectedContractId, setSelectedContractId] = useState(null);

	const [contracts, setContracts] = useState([]);
	useEffect(() => {
		const customerId = id;
		const filtered = {
			filtered: [{ id: "customer.id", value: customerId }],
		};
		contractService.search(filtered).then((data) => {
			setContracts(data["hydra:member"]);
		});
	}, []);

	const columns = [
		{
			Header: "",
			Cell: (row) => (
				<Button
					color="warning"
					small
					to={`${match.url}/contracts/${row.original.id}`}
				>
					<i className="fa fa-eye" />
				</Button>
			),
			maxWidth: 50,
		},
		{
			Header: "N° contrat",
			accessor: "id",
			maxWidth: 120,
		},
		{
			Header: "Date",
			accessor: "date",

			Cell: (row) => {
				return formatDate(row.value, "/");
			},
		},
	];

	return <WHTable data={contracts} columns={columns} />;
};

export default ContractList;
