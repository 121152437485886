import React from "react";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import DatePicker from "/wh/components/DatePicker/DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import Label from "./Label";
import classNames from "classnames";

const FieldTxt = (props) => {
	const {
		type,
		label,
		placeholder,
		input,
		disabled,
		iconBefore,
		iconAfter,
		btnBefore,
		inline,
		btnAfter,
		help,
		meta: { touched, error },
	} = props;

	return (
		<div className={classNames({ flex: inline }, "mb-2 ")}>
			<Label className={classNames({ "w-1/4": inline })}>{label}</Label>
			<div
				className={classNames({ "w-3/4": inline }, "flex", "flex-col")}
			>
				<div className="flex">
					{iconBefore && <div className="">{iconBefore}</div>}
					{btnBefore && btnBefore()}
					{(!type || ["password", "text"].includes(type)) && (
						<input
							{...input}
							className="mb-2 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
							type={type}
							placeholder={placeholder ? placeholder : null}
						/>
					)}
					{type && type == "textarea" && (
						<textarea
							{...input}
							className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
							placeholder={placeholder ? placeholder : null}
						></textarea>
					)}
					{iconAfter && <div className="">{iconAfter}</div>}
					{btnAfter && btnAfter()}
				</div>
				{touched && error && (
					<div className="text-xs text-red-600">{error}</div>
				)}
				{help && <div className="">{help}</div>}
			</div>
		</div>
	);
};

FieldTxt.propTypes = {
	type: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	disabled: PropTypes.bool,
	inline: PropTypes.bool,
	iconBefore: PropTypes.element,
	iconAfter: PropTypes.element,
	input: PropTypes.shape().isRequired,
	btnBefore: PropTypes.func,
	btnAfter: PropTypes.func,
	help: PropTypes.string,
	meta: PropTypes.shape({
		touched: PropTypes.bool,
		error: PropTypes.string,
	}),
};

FieldTxt.defaultProps = {
	label: false,
	type: "text",
	disabled: false,
	help: null,
	placeholder: false,
	iconBefore: null,
	iconAfter: null,
	btnBefore: null,
	btnAfter: null,
	inline: false,
	meta: {
		touched: false,
		error: "",
	},
};

export default FieldTxt;
