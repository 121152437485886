import React, { useState, useEffect } from "react";
import uuidv4 from "uuid/v4";
import ApiService from "/wh/services/apiSearch/apiSearch";
import { APIENUM } from "/wh/services/ApiEnum";

const useQuestionnaire = (id) => {
	const [questions, setQuestions] = useState([]);
	const [questionnaire, setQuestionnaire] = useState({});

	useEffect(() => {
		const qcmService = new ApiService(`/api/${APIENUM.QCM}`);
		qcmService.view(id).then((data) => {
			setQuestionnaire(data);
			if (data.questionnaire === "?") setQuestions([]);
			else {
				const formatedData = JSON.parse(data.questionnaire);
				setQuestions(formatedData);
			}
		});
	}, []);

	const updateQuestionDependencies = (parentIdx, idx) => {
		const updatedQuestions = [...questions];
		const questionToUpdate = { ...updatedQuestions[idx] };

		// We search for the question idx to clean its presence from other question children list
		updatedQuestions.forEach((question, index) => {
			if (question.children) {
				Object.keys(question.children).forEach((childListKey) => {
					const childList = question.children[childListKey];

					const indexOfIdx = childList.indexOf(idx);
					if (indexOfIdx !== -1) {
						childList.splice(indexOfIdx, 1);
					}

					updatedQuestions[index].children[childListKey] = childList;

					if (childList.length === 0) {
						delete updatedQuestions[index].children[childListKey];
					}
				});
			}
		});

		/* // if the question dependencie change, we suppress the data in the current parentIdx
		if (questionToUpdate.parentIdx && questionToUpdate.parentIdx !== parentIdx) {
			const prevParentQuestion = {...updatedQuestions[questionToUpdate.parentIdx]}
			for (let prop in prevParentQuestion.children) {
				const childIdx = prevParentQuestion.children[`${prop}`].findIndex((c) => c.idx === idx);
				if (childIdx > -1) {
					prevParentQuestion.children[`${prop}`].splice(childIdx, 1);
				}
			}
			updatedQuestions[questionToUpdate.parentIdx] = prevParentQuestion;
		} */

		questionToUpdate.parentIdx = parentIdx;
		questionToUpdate.isChild = parentIdx !== null ? true : false;
		updatedQuestions[idx] = { ...questionToUpdate };
		setQuestions(updatedQuestions);
	};

	const updateAnswerDependencies = (idx, parentIdx, answer) => {
		const updatedQuestions = [...questions];
		const questionToUpdate = { ...updatedQuestions[idx] };
		const parentQuestion = { ...updatedQuestions[parentIdx] };

		if (!parentQuestion.children) {
			parentQuestion.children = {};
		}

		const child = parentQuestion.children.hasOwnProperty(`${answer}`)
			? parentQuestion.children[`${answer}`]
			: [];
		for (let prop in parentQuestion.children) {
			const childIdx = parentQuestion.children[`${prop}`].find(
				(c) => c === idx
			);
			// we delete the pre-existing answer
			if (childIdx !== undefined) {
				parentQuestion.children[`${prop}`].splice(childIdx, 1);
			}
		}

		child.push(questionToUpdate.idx);

		const cleanChildren = {};

		Object.keys(parentQuestion.children).forEach((childListKey) => {
			const childList = parentQuestion.children[childListKey];
			if (childList.length > 0) {
				cleanChildren[childListKey] = childList;
			}
		});

		parentQuestion.children = { ...cleanChildren, [`${answer}`]: child };
		updatedQuestions[parentIdx] = parentQuestion;

		setQuestions(updatedQuestions);
	};

	const deleteQuestion = (q) => {
		const updatedQuestions = [...questions];
		const index = updatedQuestions.findIndex((el) => el.id == q.id);
		if (index > -1) {
			updatedQuestions.splice(index, 1);
		}
		setQuestions(updatedQuestions);
	};
	const move = (arr, old_index, new_index) => {
		while (old_index < 0) {
			old_index += arr.length;
		}
		while (new_index < 0) {
			new_index += arr.length;
		}
		if (new_index >= arr.length) {
			var k = new_index - arr.length;
			while (k-- + 1) {
				arr.push(undefined);
			}
		}
		arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

		return arr;
	};

	const up = (q) => {
		const updatedQuestions = [...questions];
		const index = updatedQuestions.findIndex((el) => el.id == q.id);

		let data = move(updatedQuestions, index, index - 1);

		setQuestions(data);
	};

	const down = (q) => {
		const updatedQuestions = [...questions];
		const index = updatedQuestions.findIndex((el) => el.id == q.id);
		let data = move(updatedQuestions, index, index + 1);
		setQuestions(data);
	};

	const updateQuestionType = (idx, type, data) => {
		const updatedQuestions = [...questions];
		const updatedQuestion = { ...updatedQuestions[idx] };

		if (type === "select") {
			updatedQuestion[`${type}`].options = data;
			updatedQuestions[idx] = updatedQuestion;

			setQuestions(updatedQuestions);
			return;
		}

		updatedQuestion[`${type}`] = data;

		if (type === "type" && data === 2) {
			updatedQuestion.select = {
				options: [{ value: "" }],
				withOtherField: false,
				children: {},
			};
		}

		updatedQuestions[idx] = updatedQuestion;
		setQuestions(updatedQuestions);
	};

	const updateQuestionOptions = (idx, type, data) => {
		const updatedQuestions = [...questions];
		const updatedQuestion = { ...updatedQuestions[idx] };

		if (type === "otherField") {
			updatedQuestion[`select`].withOtherField = data;
			updatedQuestions[idx] = updatedQuestion;
			setQuestions(updatedQuestions);
			return;
		}

		updatedQuestion[`${type}`].options = data;
		updatedQuestions[idx] = updatedQuestion;
		setQuestions(updatedQuestions);
	};
	const createQuestion = () => {
		const updatedQuestions = [...questions];
		const question = {
			id: uuidv4(),
			text: "",
			idx: updatedQuestions.length,
			type: 0,
			children: [],
			parentIdx: null,
			isChild: false,
			nextQuestionIdxAvailable: null,
			select: {
				options: [],
				withOtherField: false,
			},
		};
		updatedQuestions.push(question);
		setQuestions(updatedQuestions);
	};

	return {
		deleteQuestion,
		questions,
		questionnaire,
		up,
		down,
		createQuestion,
		updateQuestionType,
		updateQuestionDependencies,
		updateAnswerDependencies,
		updateQuestionOptions,
	};
};

export default useQuestionnaire;
