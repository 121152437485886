import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Badge } from "reactstrap";
import classNames from "classnames";
import nav from "./_nav";
import SidebarFooter from "./../SidebarFooter";
import SidebarHeader from "./../SidebarHeader";
import SidebarMinimizer from "./../SidebarMinimizer";
import * as session from "../../services/session";

class Sidebar extends Component {
	constructor(props) {
		super(props);

		this.handleClick = this.handleClick.bind(this);
		this.activeRoute = this.activeRoute.bind(this);
		this.hideMobile = this.hideMobile.bind(this);
	}

	handleClick(e) {
		e.preventDefault();
		e.target.parentElement.classList.toggle("open");
	}

	activeRoute(routeName, props) {
		// return this.props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
		return props.location.pathname.indexOf(routeName) > -1
			? "nav-item nav-dropdown open"
			: "nav-item nav-dropdown";
	}

	hideMobile() {
		if (document.body.classList.contains("sidebar-mobile-show")) {
			document.body.classList.toggle("sidebar-mobile-show");
		}
	}

	// todo Sidebar nav secondLevel
	// secondLevelActive(routeName) {
	//   return this.props.location.pathname.indexOf(routeName) > -1 ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
	// }

	render() {
		const props = this.props;

		// badge addon to NavItem
		const badge = (badge) => {
			if (badge) {
				const classes = classNames(badge.class);
				return (
					<Badge className={classes} color={badge.variant}>
						{badge.text}
					</Badge>
				);
			}
		};

		// simple wrapper for nav-title item
		const wrapper = (item) => {
			return item.wrapper && item.wrapper.element
				? React.createElement(
						item.wrapper.element,
						item.wrapper.attributes,
						item.name
				  )
				: item.name;
		};

		// nav list section title
		const title = (title, key) => {
			const classes = classNames("nav-title", title.class);
			return (
				<li key={key} className={classes}>
					{wrapper(title)}{" "}
				</li>
			);
		};

		// nav list divider
		const divider = (divider, key) => {
			const classes = classNames("divider", divider.class);
			return <li key={key} className={classes}></li>;
		};

		// nav label with nav link
		const navLabel = (item, key) => {
			const classes = {
				item: classNames("hidden-cn", item.class),
				link: classNames("nav-label", item.class ? item.class : ""),
				icon: classNames(
					!item.icon ? "fa fa-circle" : item.icon,
					item.label.variant ? `text-${item.label.variant}` : "",
					item.label.class ? item.label.class : ""
				),
			};
			return navLink(item, key, classes);
		};

		// nav item with nav link
		const navItem = (item, key) => {
			const classes = {
				item: classNames(item.class),
				link: classNames(
					"nav-link",
					item.variant ? `nav-link-${item.variant}` : ""
				),
				icon: classNames(item.icon),
			};
			return navLink(item, key, classes);
		};

		// nav link
		const navLink = (item, key, classes) => {
			const url = item.url ? item.url : "";

			let put = true;

			if (item.roles && !session.checkRole(item.roles)) put = false;

			if (put) {
				return (
					<li key={key} className={""}>
						<NavLink
							to={url}
							className={
								"text-base flex mr-8 py-4 pl-10 rounded-r-md text-gray-800 hover:bg-orange-600 hover:text-white"
							}
							onClick={this.hideMobile}
						>
							<div className="mr-3">
								<i className={classes.icon}></i>
							</div>
							<div>
								{item.name}
								{badge(item.badge)}
							</div>
						</NavLink>
					</li>
				);
			}
		};

		// nav dropdown
		const navDropdown = (item, key) => {
			if (item.roles && session.checkRole(item.roles)) {
				return (
					<li key={key} className={this.activeRoute(item.url, props)}>
						<a
							className="text-base flex mr-8 py-4 pl-10 rounded-r-md text-gray-800 hover:bg-orange-600 hover:text-white"
							href="#"
							onClick={this.handleClick}
						>
							<div className="mr-3">
								<i className={item.icon} />
							</div>
							<div>{item.name}</div>
						</a>
						<ul className="">{navList(item.children)}</ul>
					</li>
				);
			}
		};

		// nav type
		const navType = (item, idx) =>
			item.title
				? title(item, idx)
				: item.divider
				? divider(item, idx)
				: item.label
				? navLabel(item, idx)
				: item.children
				? navDropdown(item, idx)
				: navItem(item, idx);

		// nav list
		const navList = (items) => {
			return items.map((item, index) => navType(item, index));
		};

		// sidebar-nav root
		return (
			<div
				className="border-r border-gray-300 bg-white"
				style={{ width: 300 }}
			>
				<SidebarHeader />
				<nav className="pt-10">
					<ul>{navList(nav.items)}</ul>
				</nav>
				<SidebarFooter />
				<SidebarMinimizer />
			</div>
		);
	}
}

export default Sidebar;
