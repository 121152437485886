import React from "react";
import { reduxForm, Field } from "redux-form";
import * as validator from "/wh/components/Form/wh-validator";
import {
	FieldSelect,
	FieldSelectMultiple,
	FieldTxt,
	Form,
	FormActions,
	Button,
	Alert,
} from "/wh/components/WhComponent/index";
import { useEffect } from "react";
import useSearch from "../../../services/apiSearch/searchHook";
import { APIENUM } from "/wh/services/ApiEnum";
import { ModalBody } from "reactstrap";

const TeamForm = ({
	handleSubmit,
	toggle,
	initialValues,
	error,
	...otherProps
}) => {
	const onCancelClick = (event) => {
		event.preventDefault();
		toggle();
	};

	const { data: userDatas, load: loadUserDatas } = useSearch(APIENUM.USER, [
		{ id: "pagination", value: false },
	]);

	const {
		data: sectorDatas,
		load: loadSectorDatas,
	} = useSearch(APIENUM.SECTOR, [{ id: "pagination", value: false }]);

	useEffect(() => {
		loadUserDatas();
		loadSectorDatas();
	}, []);

	return (
		<Form onSubmit={handleSubmit}>
			{error && (
				<Alert color="danger">
					<div dangerouslySetInnerHTML={{ __html: error }} />
				</Alert>
			)}
			<Field
				label="Nom : "
				name="name"
				component={FieldTxt}
				type="text"
				validate={[validator.required]}
			/>

			<Field
				label="Responsable : "
				name="userMaster"
				component={FieldSelect}
				options={[
					...userDatas.map((user) => ({
						value: user["@id"],
						name: `${user.firstname} ${user.lastname}`,
					})),
				]}
				// validate={[validator.required]}
				required={false}
			/>

			<Field
				label="Secteur : "
				name="sector"
				component={FieldSelect}
				options={sectorDatas.map((sector) => ({
					value: sector["@id"],
					name: sector.name,
				}))}
				validate={[validator.required]}
				required={false}
			/>

			<Field
				label="Membres : "
				name="users"
				component={FieldSelectMultiple}
				options={[
					...userDatas.map((user) => ({
						value: user["@id"],
						label: `${user.firstname} ${user.lastname}`,
					})),
				]}
				validate={[validator.required]}
			/>

			<FormActions>
				<Button color="transparent" onClick={onCancelClick}>
					Annuler
				</Button>
				<Button onClick={handleSubmit}>Valider</Button>
			</FormActions>
		</Form>
	);
};

export default reduxForm({ form: "TeamForm" })(TeamForm);
