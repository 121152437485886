import React, { useState } from "react";
import useModal from "/wh/common/modalHook/customModalHook";
import {
	Button,
	Page,
	PageHeader,
	PageTitle,
	H1,
	Text,
	Modal,
} from "/wh/components/WhComponent/index";
import { useSelector } from "react-redux";
import { SubmissionError } from "redux-form";
import FormQuestionnaire from "/wh/modules/Questionnaires/CreateQuestionnaire/FormQuestionnaires";
import useSearch from "/wh/services/apiSearch/searchHook";
import Loading from "@whatsonweb/loading";
import { APIENUM } from "/wh/services/ApiEnum";
import ApiService from "/wh/services/apiSearch/apiSearch";
import QuestionnaireList from "/wh/modules/Questionnaires/QuestionnaireList";

const Questionnaires = (props) => {
	const { isShowing, toggle } = useModal();
	const [isLoading, setIsLoading] = useState(false);

	const { data, load } = useSearch(APIENUM.PRODUCT, []);
	const [fetchData, setFetchData] = useState(false);
	const status = useSelector((state) => state.status);

	const goToQuestionnaireEdition = (id) => {
		props.history.push(`/questionnaires/${id}`);
	};

	const onSubmit = (formData) => {
		console.log("data", formData);
		setIsLoading(true);
		const visTech = status.interventionTypes.find(
			(st) => st.label.toLowerCase() === "visite technique"
		);

		const service = new ApiService(`/api/${APIENUM.QCM}`);
		const createdQCM = {
			name: formData.name,
			interventionType: formData.type,
			product: formData.product ? formData.product.value : null,
			questionnaire: "?",
		};
		service
			.create(createdQCM)
			.then((qcm) => {
				setIsLoading(false);
				setFetchData(true);
				toggle();
				goToQuestionnaireEdition(qcm.id);
			})
			.catch((error) => {
				setIsLoading(false);
				throw new SubmissionError(error);
			});

		//@todo push to new route
	};

	return (
		<Page>
			<PageHeader>
				<PageTitle>
					<H1>Questionnaires interventions</H1>
					<Button onClick={toggle} outline>
						<i className="fa fa-plus" /> Créer un questionnaire
					</Button>
				</PageTitle>

				<Text>
					Retrouver la liste des questionnaires pour les interventions
					terrains
				</Text>
			</PageHeader>
			<QuestionnaireList
				types={status.interventionTypes}
				fetchData={fetchData}
				onReload={setFetchData}
				history={props.history}
			/>
			<Modal
				visible={isShowing}
				onClose={toggle}
				title="Créer un questionnaire"
			>
				{isLoading ? (
					<Loading />
				) : (
					<FormQuestionnaire
						types={
							status && status.interventionTypes
								? status.interventionTypes.map((int) => ({
										name: int.label,
										value: `/api/intervention_types/${int.id}`,
								  }))
								: []
						}
						//initialValues={{type:{name:status.interventionTypes[0]["label"], value:status.interventionTypes[0]["@id"]}}}
						initialValues={{
							type: `/api/intervention_types/${status.interventionTypes[0].id}`,
						}}
						toggle={toggle}
						visTech={status.interventionTypes.find(
							(st) =>
								st.label.toLowerCase() === "visite technique"
						)}
						onSubmit={onSubmit}
					/>
				)}
			</Modal>
		</Page>
	);
};

export default Questionnaires;
