import React from "react";

export const H1 = ({ children }) => {
	return <div className="text-4xl font-light">{children}</div>;
};

export const H2 = ({ children }) => {
	return <div className="text-2xl font-light">{children}</div>;
};

export const H3 = ({ children }) => {
	return <div className="text-xl font-light">{children}</div>;
};
