export const DELAY_TAB = '0';
export const FINANCIAL_TAB = '1';
export const ECO_HAB_TAB = '2';
export const VISIT_TAB = '3';
export const ENVOYER_MESSAGE = '4';

export const TABS = [
	{ tabName: DELAY_TAB, label: 'Délais retractation', colorKey: 'statusDelayColor' },
	{ tabName: ECO_HAB_TAB, label: 'Eco Habitant', colorKey: 'statusEcoColor' },
	{ tabName: FINANCIAL_TAB, label: 'Financement', colorKey: 'statusBankColor' },
	{ tabName: VISIT_TAB, label: 'visite technique', colorKey: 'statusVtColor' },
	{ tabName: ENVOYER_MESSAGE, label: 'Envoyer un message', colorKey: 'statusColor' },
];
