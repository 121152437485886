import React, { useState , Fragment } from 'react';
import { Input } from 'reactstrap';

const ImageUploader = ({ onImagesChange, defaultUris = [] }) => {

  const [ images, setImages ] = useState(defaultUris)

  const onImageListChange = (newImages) => {
    setImages(newImages)

    onImagesChange(newImages)
  }

  const onChange = (event) => {
    
    const file = event.target.files[0]
    let reader = new FileReader();

    reader.onload = event => {
      // onChange({ content: event.target.result, name: file.name, file: file, type: file.type  })
      const newImage = { content: event.target.result, name: file.name, file, type: file.type  }

      onImageListChange([...images, newImage])
    };
    reader.readAsDataURL(file);
  

  }

  const removeImage = (index) => () => {
    const newImages = [...images]

    newImages.splice(index, 1)

    onImageListChange(newImages)
  }

  return (
    <div className='img-picker-container'>
      <div className='images-container'>
        {images.map((image, index) => 
          <div key={index} className='image-container'>
            <div className='delete-button' onClick={removeImage(index)}>X</div>
            <img src={image.content ? image.content : image} className='img' />
          </div>)
        }         
      </div>

      <div className='image-input'>
        <Input type="file" name="file" onChange={onChange} />
      </div>
    </div>
  )
}

export default ImageUploader