import React from "react";
import Select from "react-select";

export default ({ value, options = [], onChange, placeholder = null }) => {
	const colourStyles = {
		control: (styles) => ({ ...styles, minHeight: "51px" }),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
			};
		},
		input: (styles) => ({ ...styles }),
		placeholder: (styles) => ({ ...styles }),
		singleValue: (styles, { data }) => ({ ...styles }),
	};

	return (
		<Select
			value={value}
			placeholder={placeholder}
			options={options}
			onChange={onChange}
			styles={colourStyles}
		/>
	);
};
