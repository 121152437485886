import React, { Fragment } from "react";
import Rodal from "rodal";
import { H1 } from "./Text/H";

export const Modal = ({
	visible = null,
	title = null,
	onClose,
	children,
	size = "m",
	customStyles = { height: "auto", maxHeight: "80%", overflowY: "scroll" },
}) => {
	const handleClose = () => {
		if (onClose) {
			onClose();
		}
	};

	const width = () => {
		switch (size) {
			case "s":
				return 500;
				break;
			case "m":
				return 800;
				break;
			case "l":
				return "90%";
				break;
		}
	};

	return (
		<Rodal
			className="modal"
			visible={visible}
			onClose={handleClose}
			showCloseButton={false}
			width={width()}
			customStyles={customStyles}
		>
			<button
				onClick={handleClose}
				className="absolute right-0 top-0 mt-4 mr-4"
			>
				<i className="text-2xl fa fa-remove"></i>
			</button>
			<div className="px-10">
				<div className="flex justify-between mb-5">
					<H1>{title}</H1>
				</div>
				<div>{children}</div>
			</div>
		</Rodal>
	);
};
