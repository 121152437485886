import React, { useMemo } from "react";
import { Field, reduxForm } from "redux-form";
import {
	FieldSelect,
	FieldTxt,
	Form,
	FormActions,
	Button,
} from "/wh/components/WhComponent/index";
import * as validator from "/wh/components/Form/wh-validator";
import useSearch from "/wh/services/apiSearch/searchHook";
import { APIENUM } from "/wh/services/ApiEnum";

const ProductForm = ({ handleSubmit, toggle }) => {
	const { data: qcms } = useSearch(APIENUM.QCM);

	const qcmOptions = useMemo(
		() => [
			{ value: null, name: "" },
			...qcms.map((qcm) => ({ value: qcm["@id"], name: qcm.name })),
		],
		[qcms]
	);

	const onCancelClick = (event) => {
		event.preventDefault();
		toggle();
	};

	return (
		<Form>
			<Field
				label="Nom"
				name="name"
				component={FieldTxt}
				validate={[validator.required]}
				type="text"
			/>
			<Field
				label="Questionnaire"
				name="qcms"
				component={FieldSelect}
				multiple
				options={qcmOptions}
				type="text"
			/>
			<FormActions>
				<Button color="transparent" onClick={onCancelClick}>
					Annuler
				</Button>
				<Button onClick={handleSubmit}>Valider</Button>
			</FormActions>
		</Form>
	);
};

export default reduxForm({
	form: "ProductForm",
})(ProductForm);
