const getApiConfig = url => ({
	url: `${url}`,
		assetUrl: url,
			helpAdmin: `${url}/docs/static`,
				help: `${url}/docs/static`,
				});

				const APICONFIG_prod = getApiConfig("http://crm-api.omeo.pro");

				module.exports = APICONFIG_prod;

