import * as store from './storage';
import * as api from './api';

export { password } from './account';

const jwt_token = null;

export const user = {
	id: null,
	name: null,
	email: null,
	roles: null,
	agency: null,
};

export const login = (username, password) =>
	api
		.login(username, password)
		.then(response => {
			store.save('jwt_token', response['token']);
		})
		.catch(exception => {
			throw exception;
		});

export const logout = () => {
	store.destroy('jwt_token');
	return Promise.resolve('disconnected');
};

export const checkRole = ROLE => {
	let res = false;

	if (!user.roles) return false;

	user.roles.map(el => {
		if (el == ROLE) res = true;
	});

	return res;
};
/**
 *
 * @returns {*}
 */
export const getJwtToken = () => store.get('jwt_token');

export const isAuthenticated = () => {
	const jwtToken = getJwtToken();

	return api
		.ping()
		.then(response => {
			user.id = response.id;
			user.name = response.name;
			user.email = response.email;
			user.roles = response.roles;
			user.agency = response.agency;

			return Promise.resolve(response);
		})
		.catch(() => Promise.reject('disconnected'));
};
