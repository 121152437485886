import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { FieldTxt, FieldSelect2 } from '/wh/components/Form/wh-field';
import { ModalBody, ModalFooter, Alert, Form } from 'reactstrap';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';

import PropTypes from 'prop-types';

const destTypes = [
	{ value: '10', label: 'Commercial Senior' },
	{ value: '20', label: 'Eco Habitant' },
	{ value: '30', label: 'Technicien Previsite' },
	{ value: '40', label: 'Technicien installateur' },
	{ value: '50', label: 'Gestionnaire de dossier' },
	{ value: '60', label: 'Tous le monde' },
];

const FormMessagePopup = ({ error, handleSubmit, submitting }) => (
	<Form onSubmit={handleSubmit} className="form-vertical">
		<ModalBody>
			<Field
				label="Destinataire(s) : "
				name="type"
				component={FieldSelect2}
				options={destTypes}
				type="select"
			/>

			<Field label="Message : " name="comment" component={FieldTxt} type="textarea" />

			{error && (
				<Alert color="danger">
					<div dangerouslySetInnerHTML={{ __html: error }} />
				</Alert>
			)}
		</ModalBody>
		<ModalFooter>
			<LaddaButton
				className="btn btn-success btn-ladda"
				loading={submitting}
				data-style={EXPAND_LEFT}
				type="submit">
				<i className="fa fa-save" /> Enregistrer{' '}
			</LaddaButton>
		</ModalFooter>
	</Form>
);

FormMessagePopup.defaultProps = {
	error: '',
};

FormMessagePopup.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	submitting: PropTypes.bool.isRequired,
	error: PropTypes.string,
};

export default reduxForm({ form: 'FormMessagePopup' })(FormMessagePopup);
