import React, { useEffect, Fragment } from "react";
import moment from "moment";
import WHTable from "/wh/components/WHTable";
import { formatDate, extractHour } from "/wh/utils/momentUtils";
import { useSelector } from "react-redux";
import { SubmissionError } from "redux-form";
import useSearch from "../../services/apiSearch/searchHook";
import InterventionForm from "/wh/modules/CustomersContractList/InterventionForm";
import { APIENUM } from "/wh/services/ApiEnum";
import useModal from "/wh/common/modalHook/customModalHook";
import ApiService from "/wh/services/apiSearch/apiSearch";
import { toast } from "react-toastify";
import { Button, Modal } from "../../components/WhComponent/index";
import { Route } from "react-router-dom";

const InterventionList = ({
	endPoint,
	filter = [],
	filterable = true,
	withCreate = false,
	bdcId = null,
	products = [],
	history,
	match,
}) => {
	console.log({ filter });

	const { data, setFilters, load } = useSearch(endPoint, filter);

	console.log({ data });

	const { isShowing, toggle } = useModal();
	const status = useSelector((state) => state.status);

	const getStatusColor = (value) => {
		if (!status) return;
		const stat = status.interventionStatus.find((st) => st.value === value);
		return `intervention_status_color intervention_status_color-${
			stat ? stat.color : ""
		}`;
	};

	useEffect(() => {
		const f = filter.find((f) => f.id === "type");

		console.log("flag Filter 1");

		if (f) {
			if (f.value === "") {
				const updatedFilter = filter.filter((f) => f.id !== "type");
				setFilters(updatedFilter);
			} else setFilters(filter);
		} else {
			setFilters(filter);
		}
	}, [filter]);

	const getStatusName = (value) => {
		if (!status || status.length === 0) return "noname";
		const stat = status.interventionStatus.find((st) => st.value === value);
		if (stat) return stat.name;
		else return "nc";
	};

	const updateFilter = (e) => {
		const { filtered } = e;

		console.log("flag Filter 2");

		setFilters(filtered);
	};
	const viewDetails = (value) => {
		history &&
			history.push(
				`/customer_contracts/${value.contract.id}/interventions/${value.id}`
			);
	};

	const columns = [
		{
			Header: "BDC",
			accessor: "contract.id",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			filterable,
			Cell: (row) => <div>{row.value}</div>,
		},
		{
			Header: "Statut",
			accessor: "state",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			filterable,
			Cell: (row) => (
				<div>
					<span className={getStatusColor(row.value)}>
						{getStatusName(row.value)}
					</span>
				</div>
			),
			Filter: ({ filter, onChange }) => (
				<select
					onChange={(event) => {
						//@todo pas propre, mauvaise structure de donnée mais cela fonctionne
						if (event.target.value !== "")
							onChange(event.target.value);
						else {
							onChange({ id: "state", value: "" });
						}
					}}
					style={{ width: "100%", height: "45px" }}
					value={filter ? filter.value : ""}
				>
					<option value="">Tous</option>
					{status &&
						status.interventionStatus &&
						status.interventionStatus.map((el, index) => (
							<option value={el.value} key={index}>
								{el.name}
							</option>
						))}
				</select>
			),
		},
		{
			Header: "Type",
			accessor: "type",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			filterable,
			Cell: (row) => (
				<div>
					<span>
						{row.value && row.value.label
							? row.value.label
							: "noInter"}
					</span>
				</div>
			),
			Filter: ({ filter, onChange }) => (
				<select
					onChange={(event) => {
						//@todo pas propre, mauvaise structure de donnée mais cela fonctionne
						if (event.target.value !== "")
							onChange(event.target.value);
						else {
							onChange({ id: "state", value: "" });
						}
					}}
					style={{ width: "100%", height: "45px" }}
					value={filter ? filter.value : ""}
				>
					<option value="">Tous</option>
					{status &&
						status.interventionTypes.map((el, index) => (
							<option value={el.value} key={index}>
								{el.label}
							</option>
						))}
				</select>
			),
		},
		{
			Header: "Métreur",
			accessor: "technicien",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			Cell: (row) => (
				<div>
					<span className="intervention_metreur">
						{row.value && row.value.firstname}
					</span>
				</div>
			),
		},
		{
			Header: "Produit",
			accessor: "product",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			Cell: (row) => (
				<div>
					{row.value ? <span>{row.value.name}</span> : <span></span>}
				</div>
			),
		},
		{
			Header: "Conformité",
			accessor: "conformity",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			Cell: (row) => (
				<div>
					{row.value ? (
						<span className="intervention_status_color intervention_status_color-blue">
							Conforme
						</span>
					) : (
						<span className="intervention_status_color intervention_status_color-red">
							Non Conforme
						</span>
					)}
				</div>
			),
		},
		{
			Header: "Date début",
			accessor: "startDate",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			Cell: (row) => <div>{formatDate(row.value, "/")}</div>,
		},
		{
			Header: "Heure début",
			accessor: "startDate",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			Cell: (row) => <div>{extractHour(row.value)}</div>,
		},
		{
			Header: "Date de fin",
			accessor: "endDate",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			Cell: (row) => <div>{formatDate(row.value, "/")}</div>,
		},
		{
			Header: "Heure de fin",
			accessor: "endDate",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			Cell: (row) => <div>{extractHour(row.value)}</div>,
		},
	];

	const onSubmitIntervention = (data) => {
		const toSubmitData = [];
		data.products.map((el) => {
			toSubmitData.push(el.value);
		});

		data.products = toSubmitData;
		console.log("toSubmitData::", data);

		const newIntervention = {
			...data,
			contract: `/api/contracts/${bdcId}`,
		};

		newIntervention.startDate = moment(
			data.startDate,
			"YYYY-MM-DD HH:mm"
		).format("YYYY-MM-DD HH:mm:ss");
		newIntervention.duration = `${data.duration}:00`;

		const startDateMoment = moment(
			newIntervention.startDate,
			"YYYY-MM-DD HH:mm:ss"
		);
		const endDateMoment = startDateMoment.add(
			moment.duration(newIntervention.duration)
		);

		newIntervention.endDate = endDateMoment.format("YYYY-MM-DD HH:mm:ss");

		delete newIntervention.startTime;

		const intervention = new ApiService(`/api/${APIENUM.INTERVENTION}`);
		intervention
			.create(newIntervention)
			.then(() => {
				load();
				toggle();
				toast.success("Enregistrement réussi");
			})
			.catch((error) => {
				throw new SubmissionError(error);
			});
	};

	return (
		<Fragment>
			{withCreate && (
				<Fragment>
					<Button block onClick={toggle}>
						{" "}
						<i className="fa fa-plus" /> Créer une intervention
					</Button>
					<Modal
						visible={isShowing}
						onClose={toggle}
						title="Créer une intervention"
					>
						<InterventionForm
							toggle={toggle}
							onSubmit={onSubmitIntervention}
							products={products}
						/>
					</Modal>
				</Fragment>
			)}

			<WHTable
				key="table"
				data={data}
				columns={columns}
				sortable={false}
				onFetchData={({ filtered }) => {
					setFilters([...filter, ...filtered]);
				}}
				getTrProps={(_state, rowInfo) => {
					return {
						onClick: () => {
							history.push(
								`/customer_contracts/${rowInfo.original.contract.customer.id}/interventions/${rowInfo.original.id}`
							);
						},
					};
				}}
			/>
		</Fragment>
	);
};

export default InterventionList;
