import React, { Component } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import FormMessagePopup from './Form';
import * as contractMessageService from '/wh/services/contract-message';
import { toast } from 'react-toastify';
import { SubmissionError } from 'redux-form';

import PropTypes from 'prop-types';

class CreateMessagePopup extends Component {
	state = { open: false };

	handleOpen = () => this.setState({ open: true });

	handleClose = () => this.setState({ open: false });

	onSubmit = async data => {
		try {
			const fetchedData = await contractMessageService.create(data);
			this.props.newData(fetchedData);
			toast.success('Enregistrement réussi');
			this.handleClose();
		} catch (error) {
			throw new SubmissionError(error);
		}
	};
	render() {
		const { className } = this.props;
		const { open } = this.state;

		return (
			<Modal isOpen={open} className={className} fade={false}>
				<ModalHeader toggle={() => this.handleClose()}>Créer</ModalHeader>

				<FormMessagePopup onSubmit={this.onSubmit} handleClose={this.handleClose} />
			</Modal>
		);
	}
}

CreateMessagePopup.defaultProps = {
	className: '',
};

CreateMessagePopup.propTypes = {
	newData: PropTypes.bool.isRequired,
	className: PropTypes.string,
};

export default CreateMessagePopup;
