import React from 'react';
import Form from './form';

class WhSideBar extends React.Component {


    constructor(props) {

        super(props);

        this.state = {
            height : 0
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {

        let height = window.innerHeight - 190;

        this.setState({ height: height });
    }


    filterToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('aside-menu-hidden');
    }

    onSubmit(data) {

        let filters = [];

        let mapObj = {'-':'.', '{':'[', '}':']'};

        Object.keys(data).map(function(objectKey, index) {

            let str = objectKey.replace(/\{|\}|-/gi, function(matched){return mapObj[matched];});

            filters.push({id : str, value : data[objectKey]})

        });

        this.props.onFilter(filters);
        document.body.classList.toggle('aside-menu-hidden');

    }

    render() {

        const {children} = this.props;

        const childrenWithProps = React.Children.map(children, child =>
            React.cloneElement(child, { onSubmit: this.onSubmit }));

        return (

            <aside className="aside-menu filters">

                <a href="javascript:void(0);" className={'filter-title'} onClick={this.filterToggle}>
                    <span>{this.props.title}</span>
                    <i className={'fa fa-remove'} />
                </a>

                <div className="aside-body">

                    {childrenWithProps}

                </div>


            </aside>

        );

    }



}

export default WhSideBar;
