export default {
	items: [
		{
			name: "Dashboards",
			url: "/dashboard",
			icon: "icon-speedometer",
		},
		{
			name: "Clients",
			url: "/customers",
			icon: "fa fa-user-circle",
			roles: "ROLE_ADMIN",
		},
		{
			name: "Contrats",
			url: "/contracts",
			icon: "fa fa-inbox",
			roles: "ROLE_ADMIN",
		},
		{
			name: "Planning",
			url: "/dp",
			icon: "fa fa-calendar",
			roles: "ROLE_ADMIN",
		},
		{
			name: "Questionnaires",
			url: "/questionnaires",
			icon: "fa fa-question-circle",
			roles: "ROLE_ADMIN",
		},
		{
			name: "Produits",
			url: "/products",
			icon: "fa fa-cubes",
			roles: "ROLE_ADMIN",
		},
		{
			name: "Famille de Produits",
			url: "/product_families",
			icon: "fa fa-cubes",
			roles: "ROLE_ADMIN",
		},
		{
			name: "Types d’intervention",
			url: "/intervention_management",
			icon: "fa fa-truck",
			roles: "ROLE_ADMIN",
		},
		{
			name: "Secteurs",
			url: "/sectors",
			icon: "fa fa-map",
			roles: "ROLE_ADMIN",
		},
		{
			name: "Teams",
			url: "/teams",
			icon: "fa fa-users",
			roles: "ROLE_ADMIN",
		},
		{
			name: "Membres",
			url: "/members",
			icon: "fa fa-user",
			roles: "ROLE_ADMIN",
		},
	],
};
