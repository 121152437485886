import React, { useMemo } from "react";
import useModal from "/wh/common/modalHook/customModalHook";
import { Field } from "redux-form";

import {
	Card,
	Modal,
	FieldSelect,
	FormWrapper,
	CardHeader,
	CardTitle,
	FormActions,
	Button,
	Label,
	Value,
	H3,
} from "/wh/components/WhComponent/index";

const Contact = ({ commercial, eco_habitant, onUpdate, commercialDatas }) => {
	const { isShowing, toggle } = useModal();

	const defaultOption = {
		value: null,
		name: "",
	};

	const initialValues = useMemo(
		() => ({
			commercial: commercial ? commercial["@id"] : null,
			ecoHabitant: eco_habitant ? eco_habitant["@id"] : null,
		}),
		[commercial, eco_habitant]
	);

	const onSubmit = (data) => {
		let updatedData = {};
		for (let key in data) {
			updatedData = { ...updatedData, [key]: data[key] };
		}

		onUpdate(updatedData);
		toggle();
	};

	const togglePreventDefault = (e) => {
		toggle();
		e.preventDefault();
	};

	const commercialOptions = useMemo(
		() =>
			[defaultOption, ...commercialDatas].map((commercial) => ({
				value: commercial["@id"],
				name: commercial.name,
			})),
		[commercialDatas]
	);

	return (
		<Card small>
			<CardHeader>
				<CardTitle>
					<H3>Contacts</H3>
					<Button outline onClick={toggle}>
						<i className="fa fa-edit" />
						Modifier
					</Button>
				</CardTitle>
			</CardHeader>
			<div className="flex">
				<Label className="w-1/3">Commercial :</Label>
				<Value className="w-2/3">
					{commercial ? commercial.firstname : null}{" "}
					{commercial ? commercial.lastname.toUpperCase() : null}
				</Value>
			</div>

			<div className="flex">
				<Label className="w-1/3">Eco-habitant :</Label>
				<Value className="w-2/3">
					{eco_habitant ? eco_habitant.firstname : null}{" "}
					{eco_habitant ? eco_habitant.lastname.toUpperCase() : null}
				</Value>
			</div>

			<Modal visible={isShowing} onClose={toggle} title="Contact">
				<FormWrapper
					onSubmit={onSubmit}
					form={"submitCommercial"}
					initialValues={initialValues}
				>
					<Field
						inline
						name={"commercial"}
						component={FieldSelect}
						label={"Commercial"}
						options={commercialOptions}
					/>
					<Field
						inline
						name={"ecoHabitant"}
						component={FieldSelect}
						label={"Eco-habitant"}
						options={commercialOptions}
					/>
					<FormActions className={"submit-btns"}>
						<Button
							color="transparent"
							onClick={togglePreventDefault}
						>
							Annuler
						</Button>
						<Button type="submit">Valider</Button>
					</FormActions>
				</FormWrapper>
			</Modal>
		</Card>
	);
};

export default Contact;
