import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { FieldTxt } from '/wh/components/Form/wh-field';
import { CardBody, Form, Alert } from 'reactstrap';
import Loading from '@whatsonweb/loading';
import { CardLaddaSaveButton } from '@whatsonweb/ladda-save-button';
import PropTypes from 'prop-types';

const FormEdit = ({ error, handleSubmit, submitting }) => (
	<Form onSubmit={handleSubmit} className="form-horizontal">
		<CardBody>
			{submitting && <Loading />}

			<div className="row">
				<div className="col-md-6">
					<Field label="Champ 1 : " name="name" component={FieldTxt} type="text" />
					<Field label="Champ 2 : " name="code" component={FieldTxt} type="text" />
				</div>
			</div>

			{error && (
				<Alert color="danger">
					<div dangerouslySetInnerHTML={{ __html: error }} />
				</Alert>
			)}
		</CardBody>
		<CardLaddaSaveButton submitting={submitting} />
	</Form>
);

FormEdit.defaultProps = {
	error: '',
};

FormEdit.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	submitting: PropTypes.bool.isRequired,
	error: PropTypes.string,
};

export default reduxForm({ form: 'FormEditContract' })(FormEdit);
