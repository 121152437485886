import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import * as validator from "/wh/components/Form/wh-validator";
import {
	Form,
	Button,
	FieldTxt,
	FormActions,
	Alert,
} from "/wh/components/WhComponent/index";

class FormLogin extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { error, handleSubmit, submitting } = this.props;

		return (
			<Form onSubmit={handleSubmit}>
				{error && <Alert color="danger">{error._error}</Alert>}

				<Field
					name="username"
					component={FieldTxt}
					validate={[validator.required]}
					type="text"
				/>

				<Field
					name="password"
					component={FieldTxt}
					validate={[validator.required]}
					type="password"
				/>
				<FormActions>
					<Button block type="submit" loading={submitting}>
						S'identifier
					</Button>
				</FormActions>
			</Form>
		);
	}
}

FormLogin = reduxForm({
	form: "FormLogin",
})(FormLogin);

export default FormLogin;
