import React from "react";
import {
	Card,
	CardHeader,
	CardTitle,
	H3,
} from "/wh/components/WhComponent/index";

const Product = ({ products = [], infos = {} }) => {
	return (
		<Card small>
			<CardHeader>
				<CardTitle>
					<H3>Produits commandés</H3>
				</CardTitle>
			</CardHeader>

			{products.map((product, index) => (
				<div key={index}>- {product ? product.name : ""}</div>
			))}
			{infos &&
				infos.products &&
				infos.products.map((product, index) => (
					<div key={index}>- {product ? product.name : ""}</div>
				))}
		</Card>
	);
};

export default Product;
