import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default ({
	to = null,
	color = "default",
	outline = false,
	block = false,
	onClick = null,
	border = true,
	rounded = true,
	className = "",
	small = false,
	loading = false,
	children,
}) => {
	const [colors, setColors] = useState({
		bg: "",
		txt: "",
		border: "",
		active: {},
		hover: {},
	});

	useEffect(() => {
		switch (color) {
			case "default":
				setColors({
					bg: "orange-500",
					txt: "white",
					border: "orange-500",
					active: {
						bg: "orange-500",
						txt: "orange-700",
						border: "orange-500",
					},
					hover: {
						bg: "orange-400",
						txt: "white",
						border: "transparent",
					},
				});
				break;
			case "success":
				setColors({
					bg: "orange-500",
					txt: "white",
					border: "orange-500",
					active: {
						bg: "orange-500",
						txt: "orange-700",
						border: "orange-500",
					},
					hover: {
						bg: "orange-400",
						txt: "white",
						border: "transparent",
					},
				});
				break;
			case "danger":
				setColors({
					bg: "red-500",
					txt: "white",
					border: "red-500",
					active: {
						bg: "red-500",
						txt: "red-700",
						border: "red-500",
					},
					hover: {
						bg: "red-400",
						txt: "white",
						border: "transparent",
					},
				});
				break;
			case "info":
				setColors({
					bg: "black",
					txt: "white",
					border: "black",
					active: {
						bg: "black",
						txt: "white",
						border: "transparent",
					},
					hover: {
						bg: "black",
						txt: "gray-100",
						border: "transparent",
					},
				});
				break;
			case "warning":
				setColors({
					bg: "gray-500",
					txt: "gray-700",
					border: "gray-500",
					active: {
						bg: "gray-500",
						txt: "gray-700",
						border: "gray-500",
					},
					hover: {
						bg: "gray-400",
						txt: "white",
						border: "transparent",
					},
				});
				break;

			case "transparent":
				setColors({
					bg: "transparent",
					txt: "gray-500",
					border: "transparent",
					active: {
						bg: "gray-500",
						txt: "gray-700",
						border: "gray-500",
					},
					hover: {
						bg: "gray-400",
						txt: "white",
						border: "transparent",
					},
				});
				break;
			case "white":
				setColors({
					bg: "white",
					txt: "gray-500",
					border: "transparent",
					active: {
						bg: "gray-500",
						txt: "gray-700",
						border: "gray-500",
					},
					hover: {
						bg: "gray-400",
						txt: "white",
						border: "transparent",
					},
				});
				break;
		}
	}, [color]);

	const getColors = () => {
		if (outline) {
			return `bg-transparent hover:bg-${colors.bg} text-${colors.bg} hover:text-${colors.hover.txt} border-${colors.border} hover:border-${colors.hover.border}`;
		}

		if (!outline) {
			return `bg-${colors.bg} text-${colors.txt} border-${colors.border} hover:bg-${colors.hover.bg} hover:text-${colors.hover.txt}`;
		}
	};

	const getBlock = () => {
		return block ? "w-full" : "";
	};

	const getBorder = () => {
		return border ? "border" : "";
	};

	const getFontWeight = () => {
		return outline ? "font-bold" : "font-semibold";
	};

	const getRounded = () => {
		return rounded ? "rounded" : "";
	};

	const getSize = () => {
		if (small) return "py-1 px-2";
		return "py-2 px-4";
	};

	if (to) {
		return (
			<Link
				to={to}
				className={`btn ${getRounded()} ${getSize()} ${getBorder()} ${getBlock()} ${getFontWeight()} ${getColors()} ${className}`}
			>
				{children}
			</Link>
		);
	} else {
	}
	return (
		<button
			className={`btn ${getRounded()} ${getSize()} ${getBorder()} ${getBlock()} ${getFontWeight()} ${getColors()} ${className}`}
			onClick={onClick}
			disabled={loading}
		>
			{children}
		</button>
	);
};
