import React from "react";
import { Field, reduxForm } from "redux-form";
import {
	FieldTxt,
	Form,
	FormActions,
	Button, FieldSelectAsync,
} from "/wh/components/WhComponent/index";
import * as validator from "/wh/components/Form/wh-validator";

import * as productFamilyService from "/wh/services/product_families";

const ProductFamilyForm = ({ handleSubmit, toggle }) => {

	const onCancelClick = (event) => {
		event.preventDefault();
		toggle();
	};

	return (
		<Form>
			<Field
				label="Nom"
				name="name"
				component={FieldTxt}
				validate={[validator.required]}
				type="text"
			/>

			<Field
				label="Famille parent : "
				inline
				name="parent"
				component={FieldSelectAsync}
				loadOptions={(inputValue, callback) => {
					productFamilyService
						.search({
							filtered: [{ id: "parent[exists]", value: 'false' }],
						})
						.then((result) =>
							callback(
								result["hydra:member"].map((item) => ({
									label: item.name,
									value: item["@id"],
									id: item.id
								}))
							)
						);
				}}
			/>

			<FormActions>
				<Button color="transparent" onClick={onCancelClick}>
					Annuler
				</Button>
				<Button onClick={handleSubmit}>Valider</Button>
			</FormActions>
		</Form>
	);
};

export default reduxForm({
	form: "ProductFamilyForm",
	enableReinitialize: true,
})(ProductFamilyForm);
