import React from "react";
import { reduxForm, Field } from "redux-form";

import {
	Form,
	FieldDatePicker,
	FormActions,
	Button,
} from "/wh/components/WhComponent/index";

const FacturationModalContent = ({
	handleSubmit,
	initialValues,
	submiting,
	toggle,
	error,
}) => {
	return (
		<Form onSubmit={handleSubmit}>
			{error && <Alert color="danger">{error}</Alert>}

			<Field
				inline
				label="Réception du dossier"
				name="dateReception"
				component={FieldDatePicker}
			/>

			<Field
				inline
				label="Envoi du financement"
				name="dateFinancement"
				component={FieldDatePicker}
			/>

			<Field
				inline
				label="Acceptation du financement"
				name="dateAcceptation"
				component={FieldDatePicker}
			/>

			<FormActions>
				<Button color="transparent" onClick={toggle}>
					Annuler
				</Button>
				<Button type="submit" loadding={submiting}>
					Valider
				</Button>
			</FormActions>
		</Form>
	);
};

export default reduxForm({
	form: "facturation_form",
})(FacturationModalContent);
