import React, {Component} from 'react';
import {
    Button
} from 'reactstrap';

class BtnFilter extends Component {

    filterToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('aside-menu-hidden');
    }

    render() {

        return (

        <Button color="primary" block={true} size="lg" onClick={this.filterToggle} className={'btn-filter'}>
            <i className={'fa fa-filter'} style={{float:'right', margin : '6px 0 0 0'}} /> { this.props.title }
        </Button>


        );

    }



}

export default BtnFilter;
