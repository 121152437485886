import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { SubmissionError } from 'redux-form';
import FormPassword from './FormPassword';
import { Row, Col } from 'reactstrap';

import * as sessionService from '../../services/session';

class MyPassword extends Component {
	submit = async values => {
		try {
			await sessionService.password(values);
			toast.success('Enregistrement réussi');
		} catch (error) {
			throw new SubmissionError(error);
		}
	};

	render() {
		return (
			<div className="animated fadeIn">
				<Row>
					<Col xs="12" sm="6">
						<FormPassword onSubmit={this.submit} />
					</Col>
				</Row>
			</div>
		);
	}
}

export default MyPassword;
