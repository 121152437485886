import React, { useState, useEffect, Fragment, useMemo } from "react";

import {
	Button,
	Input,
	Select,
	Label,
	Checkbox,
} from "/wh/components/WhComponent/index";

export const DependenciesManager = ({
	currentQuestion,
	questions,
	onUpdateQuestion,
	onUpdateAnswer,
	questionIdx,
}) => {
	const [selectableQuestions, setSelectableQuestions] = useState([]);
	const [selectedQuestion, setSelectedQuestion] = useState(null);
	const [selectableOptions, setSelectableOptions] = useState(null);
	const [currentOption, setCurrentOption] = useState(null);

	const defaultOption = { label: "Aucune", value: null };
	const emptyOption = { label: "", value: null };

	const manageSelectableOptions = (selectedQuestion) => {
		const question = questions.find((q) => q.idx === selectedQuestion.idx);

		if (question) {
			if (question.type === 0) {
				const formatedOptions = [
					{ label: "oui", value: "oui" },
					{ label: "non", value: "non" },
				];
				setSelectableOptions(formatedOptions);
			}
			if (question.type === 2) {
				const formatedOptions =
					question.select && question.select.options
						? question.select.options.map((ans) => ({
								label: ans.label ? ans.label : ans.value,
								value: ans.value,
						  }))
						: null;

				setSelectableOptions(formatedOptions);
			}
		}
	};

	useEffect(() => {
		const formatedQuestions = questions
			.filter((q, index) => {
				return (
					q.idx !== questionIdx &&
					questionIdx > q.idx &&
					// Si booléen ou Select
					[0, 2].includes(q.type)
				);
			})
			.map((q) => ({ label: q.text, value: q.idx }));

		setSelectableQuestions([defaultOption, ...formatedQuestions]);

		if (selectedQuestion) {
			manageSelectableOptions(selectedQuestion);
		}
	}, [questions]);

	const handleSelectQuestion = (selected) => {
		setSelectedQuestion(selected);
		setCurrentOption(emptyOption);

		const question = questions.find((q) => q.idx === selected.value);

		if (question) {
			if (question.type === 0) {
				const formatedOptions = [
					{ label: "oui", value: "oui" },
					{ label: "non", value: "non" },
				];
				setSelectableOptions(formatedOptions);
			} else if (question.type === 2) {
				const formatedOptions = question.select.options.map((ans) => {
					return {
						label: ans.label ? ans.label : ans.value,
						value: ans.value,
					};
				});
				setSelectableOptions(formatedOptions);
			}
			onUpdateQuestion(question.idx, questionIdx);
		} else {
			setSelectableOptions(null);
			onUpdateQuestion(null, questionIdx);
			// onUpdateAnswer(questionIdx, null)
		}
	};

	const handleSelectOption = (option) => {
		setCurrentOption(option);
		onUpdateAnswer(questionIdx, selectedQuestion.value, option.label);
	};

	const questionDefaultValue = useMemo(() => {
		const { parentIdx } = currentQuestion;

		if (currentQuestion.parentIdx !== null) {
			const parentQuestion = questions.find(
				(question) => question.idx === parentIdx
			);

			const resultOption = {
				label: parentQuestion.text,
				value: parentIdx,
			};

			manageSelectableOptions(parentQuestion);

			setSelectedQuestion(resultOption);

			return resultOption;
		} else {
			return defaultOption;
		}
	}, []);

	const optionDefaultValue = useMemo(() => {
		const { parentIdx } = currentQuestion;

		if (currentQuestion.parentIdx !== null) {
			const parentQuestion = questions.find(
				(question) => question.idx === parentIdx
			);

			const childrenGroupIndex = Object.keys(
				parentQuestion.children
			).find(
				(keyAnswer) =>
					parentQuestion.children[keyAnswer].find(
						(children) => children.idx === currentQuestion.idx
					) !== -1
			);

			const optionLabel =
				childrenGroupIndex === "true"
					? "oui"
					: childrenGroupIndex === "false"
					? "non"
					: childrenGroupIndex;

			return { label: optionLabel, value: childrenGroupIndex };
		} else {
			return null;
		}
	}, []);

	return (
		<div className={"flex"}>
			<hr />

			<div className="w-1/5 flex items-center">
				<Label>Question conditionné par :</Label>
			</div>

			<div className="w-2/5 ml-10">
				<Select
					value={
						selectedQuestion
							? selectedQuestion
							: questionDefaultValue
					}
					options={selectableQuestions}
					onChange={handleSelectQuestion}
				/>
			</div>
			<div className="w-2/5 ml-10">
				<Select
					value={currentOption ? currentOption : optionDefaultValue}
					options={selectableOptions}
					onChange={handleSelectOption}
					isDisabled={selectableOptions === null}
				/>
			</div>
		</div>
	);
};

const OptionsElement = ({
	select,
	answers,
	onUpdate,
	onUpdateOptions,
	questionIdx,
}) => {
	const handleChange = (e, idx) => {
		const updatedAnswers = [...select.options];

		updatedAnswers[idx] = { value: e.target.value };
		onUpdate(questionIdx, "select", updatedAnswers);
	};

	const addOptions = () => {
		if (answers === null) {
			onUpdate(questionIdx, "select", [""]);
			return;
		}
		const updatedAnswers = [...select.options];
		updatedAnswers.push("");
		onUpdate(questionIdx, "select", updatedAnswers);
	};

	const addOtherField = (addOtherField) => {
		onUpdateOptions(questionIdx, "otherField", addOtherField);
	};

	const onCheckOtherFieldChange = (event) => {
		addOtherField(event.target.checked);
	};

	const isOtherFieldOptionChecked = select ? select.withOtherField : false;

	return (
		<Fragment>
			{select &&
				Array.isArray(select.options) &&
				select.options.map((ans, idx) => (
					<div key={idx}>
						<Label>Option {idx} : </Label>
						<Input
							key={`answers_${idx}`}
							value={ans.value}
							onChange={(e) => handleChange(e, idx)}
						/>
					</div>
				))}
			<Button onClick={addOptions} color="info" block>
				Ajouter une option
			</Button>
			<div className="flex items-center h-20">
				<Checkbox
					onChange={onCheckOtherFieldChange}
					checked={isOtherFieldOptionChecked}
				/>
				<Label>Ajouter la possibilité d'un champ autre</Label>
			</div>
		</Fragment>
	);
};
const QuestionElement = ({
	question,
	up,
	down,
	onUpdate,
	onUpdateOptions,
	questionIdx,
	onDelete,
	first,
	last
}) => {
	const options = [
		{ label: "Oui/Non", value: 0 },
		{ label: "Champs text", value: 1 },
		{ label: "Liste de valeurs", value: 2 },
		{ label: "Numerique", value: 3 },
		{ label: "Photo + commentaire", value: 4 },
		{ label: "Date", value: 5 },
	];

	const handleChange = (selectedOption) => {
		onUpdate(questionIdx, "type", selectedOption.value);
	};

	const handleQuestionChange = (e) => {
		onUpdate(questionIdx, "text", e.target.value);
	};

	return (
		<div>
			<div>
				<div className="flex mb-10">
					<div className="w-3/4 mr-10">
						<Label>Question {`${questionIdx}`} : </Label>
						<Input
							placeholder="Saisir l'intitulé de la question"
							value={question.text}
							onChange={handleQuestionChange}
							grey
						/>
					</div>

					<div className="w-1/4">
						<Label>Type : </Label>
						<Select
							label={"test"}
							value={options.find(
								(opt) => opt.value === question.type
							)}
							options={options}
							onChange={handleChange}
						/>
					</div>
				</div>

				<div className="flex mb-10">
					<div className="w-3/4 mr-10">
						{question.type === 2 && (
							<OptionsElement
								select={question.select}
								answers={question.answers}
								onUpdateOptions={onUpdateOptions}
								onUpdate={onUpdate}
								questionIdx={questionIdx}
							/>
						)}
					</div>
					<div className="w-1/4 text-right">
						<Button
							color="danger"
							onClick={() => onDelete(question)}
							className="ml-2"
						>
							<i className={"fa fa-trash"}></i>
						</Button>

						{!first &&
							<Button
								color="info"
								onClick={() => up(question)}
								className="ml-2"
							>
								<i className={"fa fa-arrow-up"}></i>
							</Button>
						}

						{!last &&
							<Button
								color="info"
								onClick={() => down(question)}
								className="ml-2"
							>
								<i className={"fa fa-arrow-down"}></i>
							</Button>
						}
					</div>
				</div>
			</div>
		</div>
	);
};

export default QuestionElement;
