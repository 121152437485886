import React, { useMemo, useState } from "react";
import { APIENUM } from "/wh/services/ApiEnum";
import WHTable from "../../../components/WHTable";
import useSearch from "../../../services/apiSearch/searchHook";
import useModal from "/wh/common/modalHook/customModalHook";
import InterventionTypeForm from "../InterventionTypeForm";
import ApiService from "/wh/services/apiSearch/apiSearch";
import { SubmissionError } from "redux-form";

import {
	Button,
	Page,
	PageHeader,
	PageTitle,
	PageActions,
	H1,
	Text,
	Modal,
} from "/wh/components/WhComponent/index";

const InterventionManagement = () => {
	const { data: interventionTypes, load } = useSearch(
		APIENUM.INTERVENTION_TYPES
	);

	const { isShowing, toggle } = useModal();
	const [editId, setEditId] = useState(null);

	const onFormSubmit = (data) => {
		let finalData = editId !== null ? { ...data, id: editId } : data;

		const service = new ApiService(`/api/${APIENUM.INTERVENTION_TYPES}`);

		const finalService = editId !== null ? service.update : service.create;

		return finalService(finalData)
			.then(() => {
				load();
				toggle();
			})
			.catch((error) => {
				throw new SubmissionError(error);
			});
	};

	const onNewClick = () => {
		setEditId(null);
		toggle();
	};

	const onEditClick = (typeId) => () => {
		setEditId(typeId);
		toggle();
	};

	const onDeleteClick = (typeId) => () => {
		const service = new ApiService(`/api/${APIENUM.INTERVENTION_TYPES}`);
		service.remove(typeId).then(() => {
			load();
		});
	};

	const formInitialValues = useMemo(() => {
		if (editId !== null) {
			const { label } = interventionTypes.find(
				(interventionType) => interventionType.id === editId
			);

			return {
				label,
			};
		} else {
			return null;
		}
	}, [editId, interventionTypes]);

	const columns = useMemo(
		() => [
			{
				Header: "",
				accessor: "id",
				filterable: false,
				Cell: (row) => (
					<div>
						<Button
							color="info"
							small
							className="mr-2"
							onClick={onEditClick(row.original.id)}
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							color="danger"
							small
							className="mr-2"
							onClick={onDeleteClick(row.original.id)}
						>
							<i className="fa fa-trash" />
						</Button>
					</div>
				),
				maxWidth: 120,
			},
			{
				Header: "Label",
				accessor: "label",
			},
		],
		[]
	);

	return (
		<Page>
			<PageHeader>
				<PageTitle>
					<H1> Gestion des types d'intervention</H1>

					<Button outline onClick={onNewClick}>
						{" "}
						<i className="fa fa-plus" /> Nouveau
					</Button>
				</PageTitle>

				<Text>Gérer les types d'intervention</Text>
			</PageHeader>

			<WHTable
				data={interventionTypes}
				columns={columns}
				onFetchData={load}
				loading={false}
			/>

			<Modal
				visible={isShowing}
				onClose={toggle}
				title="Editer un type d'intervention"
			>
				{isShowing && (
					<InterventionTypeForm
						toggle={toggle}
						onSubmit={onFormSubmit}
						initialValues={formInitialValues}
					/>
				)}
			</Modal>
		</Page>
	);
};

export default InterventionManagement;
