import React from "react";

export default ({ children, color, small }) => {
	const getColor = () => {
		switch (color) {
			case "black":
				return "black";

			default:
				return "gray-500";
		}
	};

	const getSize = () => {
		if (small) return "sm";

		return "base";
	};

	return (
		<div className={`text-${getSize()} text-${getColor()}`}>{children}</div>
	);
};
