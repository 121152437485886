import React, { Component, createRef } from "react";
import Loading from "@whatsonweb/loading";
import Moment from "react-moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Card, CardHeader, Table, Button } from "reactstrap";

import CreateEventPopup from "../../../CreateEventPopup";
import * as eventService from "/wh/services/event";

class TechnicalVisit extends Component {
	state = {
		loaded: true,
		events: [],
	};

	eventPopup = createRef();

	componentDidMount() {
		this.load();
	}

	submit = async () => {
		await this.load();
		await this.props.onReload();
	};
	load = async () => {
		this.setState({ loaded: false });

		const { data, status } = this.props;
		try {
			const fetchedData = await eventService.search({
				"contract.id": data["id"],
				statusFamily: status.statusFamily.VT,
			});
			this.setState({ events: fetchedData["hydra:member"] });
		} catch (error) {
			console.error(error);
		} finally {
			this.setState({ loaded: true });
		}
	};

	render() {
		const { status } = this.props;
		const { loaded } = this.state;

		return loaded ? (
			<Card>
				<CreateEventPopup
					ref={this.eventPopup}
					type={status.statusFamily.VT}
					status={status.vtStatus}
					onSubmit={this.submit}
				/>
				<CardHeader>
					<i className="fa fa-" /> Visite technique
					<div className="card-actions">
						<Button
							onClick={() =>
								this.eventPopup.current
									// .getWrappedInstance()
									.handleOpen(this.props.data)
							}
						>
							<i className="fa fa-plus" /> Ajouter un évenement
						</Button>
					</div>
				</CardHeader>

				<Table responsive striped hover>
					<thead className="thead-light">
						<tr>
							<th />
							<th>Date</th>
							<th>Nom</th>
							<th>Commentaire</th>
						</tr>
					</thead>
					<tbody>
						{this.state.events.map((el, index) => (
							<tr key={index}>
								<td />
								<td>
									<Moment format="DD/MM/YYYY">
										{el.date}
									</Moment>
								</td>
								<td>{el.statusName}</td>
								<td>{el.comment}</td>
							</tr>
						))}
					</tbody>
				</Table>
			</Card>
		) : (
			<Loading />
		);
	}
}

TechnicalVisit.propTypes = {
	data: PropTypes.shape({}).isRequired,
	status: PropTypes.shape({}).isRequired,
	onReload: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ status: state.status });

export default connect(mapStateToProps)(TechnicalVisit);
