import React, { useState, useEffect } from "react";
import ApiService from "/wh/services/apiSearch/apiSearch";

// custom Hooks
const useSearch = (
	endPoint = "",
	filter = [],
	fetchAll = true,
	itemId = null
) => {
	// save the basic params of the api call
	const [filtered, setFiltered] = useState(filter);
	const [currentFiltered, setCurrentFiltered] = useState(filter);
	const [apiEndPoint, setApiEndpoint] = useState(`/api/${endPoint}`);
	const [pending, setPending] = useState(false);
	const [data, setData] = useState([]);

	console.log({ currentFiltered, filtered });

	function setFilters(filters) {
		setCurrentFiltered(filters);
	}

	function load(fetch = true) {
		const finalApiEndPoint =
			itemId !== null ? `${apiEndPoint}/${itemId}` : apiEndPoint;

		const service = new ApiService(finalApiEndPoint);
		const updatedFiltered = currentFiltered.filter((f) => f.value !== "");

		setPending(true);

		console.log({ updatedFiltered });

		service.search({ filtered: updatedFiltered }).then((data) => {
			setPending(false);

			if (itemId !== null && fetch) {
				setData(data);
			} else if (fetch) setData(data["hydra:member"]);
		});
	}

	useEffect(() => {
		if (!fetchAll && currentFiltered.length === 0) return;
		let fetch = true;

		load(fetch);
		return () => (fetch = false);
	}, [currentFiltered]);

	return {
		data,
		setFilters,
		load,
		pending,
	};
};

export default useSearch;
