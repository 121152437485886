import { createAction } from "redux-actions";
import { SET_STATUS, SET_STATUS_TYPE } from "./constants";
import * as statusService from "../../services/status";
import * as interventionTypeService from "../../services/interventionType";

export const setStatus = createAction(SET_STATUS, (data) => data);
export const setStatusType = createAction(SET_STATUS_TYPE, (data) => data);
export const fetchStatus = () => (dispatch) => {
	try {
		statusService.load().then((data) => {
			interventionTypeService.load().then((it) => {
				dispatch(setStatus(data));
				dispatch(setStatusType(it));
			});
		});
	} catch (error) {
		console.warn(error);
	}
};
