import React, {Component} from 'react';
import {Field, change, Form, reduxForm} from 'redux-form'
import {FieldTxt, FieldSelect, FieldTxtIcon} from '../../components/Form/wh-field';
import request from 'superagent';
import * as validator from "../../components/Form/wh-validator";
import {Button, Alert} from 'reactstrap';
import {connect} from "react-redux";


class FormProject extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        };

    }


    render() {

        const {handleSubmit, error} = this.props;

        return (

            <div>


                <Form onSubmit={handleSubmit} className="form-inline">

                    <div className="aside-content">

					<Field label="Du : " name="date[after]" component={FieldTxt} type="date" />
					<Field label="Au : " name="date[before]" component={FieldTxt} type="date" />

                    {error && <Alert color="danger"><div dangerouslySetInnerHTML={{__html: error}} /></Alert>}

                    </div>
                    <div className="aside-footer">
                        <Button color={'primary'} block type={'submit'}>Appliquer les filtres</Button>
                    </div>

                </Form>


            </div>

        );

    }
}

FormProject = reduxForm({
    form: 'FormFilterProperty',
    onChange: (values, dispatch, props) => {

    },
})(FormProject);

const mapStateToProps = state => ({
    user: state.user
});

export default connect(
    mapStateToProps
)(FormProject);

