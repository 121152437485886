import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import configureStore, { history } from "./redux/store";
import "rodal/lib/rodal.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import "./styles";
// Containers
import Full from "./containers/Full/";

import Login from "./modules/Pages/Login/";
import Register from "./modules/Pages/Register/";
import Page404 from "./modules/Pages/Page404/";
import Page500 from "./modules/Pages/Page500/";

const { persistor, store } = configureStore({});

//@todo readd PersistGate
ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<Switch>
				<Route
					exact
					path="/login"
					name="Login Page"
					component={Login}
				/>
				<Route
					exact
					path="/register"
					name="Register Page"
					component={Register}
				/>
				<Route exact path="/404" name="Page 404" component={Page404} />
				<Route exact path="/500" name="Page 500" component={Page500} />
				<Route path="/" name="Home" component={Full} />
			</Switch>
		</ConnectedRouter>
	</Provider>,
	document.getElementById("root")
);
