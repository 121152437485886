import React, { Component } from "react";
import Moment from "react-moment";
import { bindActionCreators } from "redux";
import NumberFormat from "react-number-format";
import {
	Button,
	Page,
	PageHeader,
	PageTitle,
	PageActions,
	H1,
	Badge,
	Text,
} from "/wh/components/WhComponent/index";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import WHTable from "../../components/WHTable";
import * as contractService from "../../services/contract";
import {
	fetchContracts,
	fetchContractsTotal,
	deleteContract,
	fetchContract,
	setContract,
} from "./redux/actions";
import { getContracts, getContractsPages } from "./redux";

import ContractPopup from "./Popup/ContractPopup";

import { getStatusLst, getStatus, getPaymentTypeLst } from "../../redux/status";
import { getUser } from "../../redux/user";
import { syncContracts } from "./utils";

import WhSideBar from "../../components/Filter/sideBar";
import BtnFilter from "../../components/Filter/btn";
import FormFilters from "./FormFilters";

class Contracts extends Component {
	state = { loaded: true, defaultFiltered: [], total: 0 };

	editPopup = null;

	getPopupMethods = (methods) => (this.editPopup = methods);

	columns = () => [
		{
			Header: "",
			accessor: "id",
			filterable: false,
			Cell: (row) => (
				<div>
					<Button
						small
						className="mr-2"
						color="warning"
						onClick={() => this.onPopupOpen(row.value)}
					>
						<i className="fa fa-eye" />
					</Button>
					<Button
						small
						color="danger"
						onClick={() => this.remove(row.value)}
					>
						<i className="fa fa-trash" />
					</Button>
				</div>
			),
			maxWidth: 120,
		},
		{
			Header: "Par",
			accessor: "senior.user.id",
			Cell: (row) => (
				<span>
					{row.original.senior &&
						row.original.senior.user &&
						row.original.senior.user.lastname}
				</span>
			),
			Filter: ({ filter, onChange }) => (
				<select
					onChange={(event) => onChange(event.target.value)}
					style={{ width: "100%", height: "30px" }}
					value={filter ? filter.value : ""}
				>
					<option value="">Tous</option>
				</select>
			),
		},
		{
			Header: "Statut",
			accessor: "status",
			minWidth: 200,
			Cell: ({ original }) => (
				<div>
					<Badge color={original.statusColor}>
						{original.statusName}
					</Badge>
					<br />
					<Badge color={original.statusDelayColor}>
						<i className="fa fa-clock-o" />
					</Badge>
					<Badge color={original.statusEcoColor}>
						<i className="fa fa-leaf" />
					</Badge>
					<Badge color={original.statusBankColor}>
						<i className="fa fa-bank" />
					</Badge>
					<Badge color={original.statusVtColor}>
						<i className="fa fa-sliders" />
					</Badge>
				</div>
			),
			Filter: ({ filter, onChange }) => (
				<select
					onChange={(event) => onChange(event.target.value)}
					style={{ width: "100%", height: "30px" }}
					value={filter ? filter.value : ""}
				>
					<option value="">Tous</option>
					{this.props.statusLst.map((el, index) => (
						<option value={el.value} key={index}>
							{el.label}
						</option>
					))}
				</select>
			),
		},
		{
			Header: "Créé le",
			accessor: "date",
			filterable: false,
			Cell: (row) => <Moment format="DD/MM/YYYY">{row.value}</Moment>,
		},
		{
			Header: "Nom",
			accessor: "customer.name",
			Cell: (row) => (
				<span>
					{row.original.customer ? row.original.customer.name : ""}
				</span>
			),
		},
		{
			Header: "Ville",
			accessor: "customer.city",
		},
		{
			Header: "Montant",
			accessor: "amount",
			Cell: (row) => (
				<NumberFormat
					value={row.value}
					displayType="text"
					thousandSeparator
					prefix="€"
				/>
			),
		},
		{
			Header: "Types de paiements",
			accessor: "paymentType",
			Cell: (row) => <span>{row.original.paymentTypeName}</span>,
			Filter: ({ filter, onChange }) => (
				<select
					onChange={(event) => onChange(event.target.value)}
					style={{ width: "100%", height: "30px" }}
					value={filter ? filter.value : ""}
				>
					<option value="">Tous</option>
					{this.props.paymentTypes.map((el, index) => (
						<option value={el.value} key={index}>
							{el.label}
						</option>
					))}
				</select>
			),
		},
		{
			Header: "Maison financement",
			accessor: "bankFolder.bankName",
		},
	];

	onPopupOpen = (id) => {
		this.props.onSetCurrentContract(id);
		this.editPopup.handleOpen();
	};

	exports = async (exportData) => {
		this.setState({ loaded: false });

		try {
			await contractService.exports(exportData);
			toast.success("Votre export est envoyé par email");
		} catch (error) {
			console.error(error);
		} finally {
			this.setState({ loaded: true });
		}
	};

	load = async (state) => {
		this.setState({ loaded: false });
		await this.props.onFetchContracts(state);
		const total = await this.props.onFetchContractsTotal(state);
		this.setState({ loaded: true, total: total.total });
	};

	remove = (toDelete) => {
		if (confirm("Etes vous sûre de vouloir supprimer cet item ?")) {
			this.setState({ loaded: false });
			this.props.onDeleteContract(toDelete);
			this.setState({ loaded: true });
		}
	};

	onSync = async () => {
		this.setState({ loaded: false });
		await syncContracts();
		await this.load({});
		toast.success("Vos contrats on été synchronisés");
	};

	render() {
		const { contracts, pages, onSetCurrentContract } = this.props;
		const { getPopupMethods, load, columns } = this;
		const { loaded, total } = this.state;

		return (
			<Page>
				<PageHeader>
					<PageTitle>
						<H1>Gestion des contrats</H1>
						<PageActions>
							<Button onClick={this.onSync} outline>
								<i className="fa fa-refresh" /> Synchroniser
							</Button>
							<Button onClick={this.exports} outline>
								<i className="fa fa-upload" /> Exporter
							</Button>

							<Button>
								Total :
								<strong>
									<NumberFormat
										value={total}
										displayType="text"
										decimalScale={0}
										thousandSeparator
										prefix="€"
									/>
								</strong>
							</Button>
						</PageActions>
						<ContractPopup
							setMethods={getPopupMethods}
							onSetCurrentContract={onSetCurrentContract}
						/>
					</PageTitle>
					<Text>Chercher, filtrer des contrats</Text>
				</PageHeader>

				{/*
				<BtnFilter title="+ de filtres" />
				
				<WhSideBar
					default={this.state.defaultFiltered}
					onFilter={(data) =>
						this.setState({ defaultFiltered: data })
					}
					title="Filtrer les contrats"
				>
					<FormFilters />
				</WhSideBar>
				*/}

				<WHTable
					data={contracts}
					pages={pages}
					columns={columns()}
					filterable
					onFetchData={load}
					loading={!loaded}
					defaultFiltered={this.state.defaultFiltered}
				/>
			</Page>
		);
	}
}

Contracts.propTypes = {
	onFetchContracts: PropTypes.func.isRequired,
	onDeleteContract: PropTypes.func.isRequired,
	onSetCurrentContract: PropTypes.func.isRequired,
	onFetchContractsTotal: PropTypes.func.isRequired,
	contracts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	statusLst: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	pages: PropTypes.number.isRequired,
	history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
	match: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired,
	status: PropTypes.shape({}).isRequired,
	paymentTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = (state) => ({
	user: getUser(state),
	status: getStatus(state),
	paymentTypes: getPaymentTypeLst(state),
	contracts: getContracts(state),
	pages: getContractsPages(state),
	statusLst: getStatusLst(state),
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			onFetchContracts: fetchContracts,
			onFetchContractsTotal: fetchContractsTotal,
			onDeleteContract: deleteContract,
			onReload: fetchContract,
			onSetCurrentContract: (id) =>
				id ? fetchContract(id) : setContract(null),
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Contracts);
