import React from "react";

export default class PageHeader extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="flex items-center justify-between mb-6">
				{this.props.children}
			</div>
		);
	}
}
