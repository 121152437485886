import React from "react";
import PropTypes from "prop-types";
import DatePicker from "./DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import Label from "./Label";
import classNames from "classnames";

const FieldDatePicker = (props) => {
	const {
		type,
		label,
		placeholder,
		input,
		disabled,
		inline,
		help,
		meta: { touched, error },
	} = props;

	return (
		<div className={classNames({ flex: inline }, "mb-2")}>
			<Label className={classNames({ "w-1/4": inline })}>{label}</Label>
			<div className={classNames({ "w-3/4": inline }, "flex")}>
				<div className="flex-1">
					<DatePicker input={input} className="py-3 px-3" />
				</div>

				{touched && error && (
					<span className="text-xs text-red-600">{error}</span>
				)}
			</div>
			{help && <span className="">{help}</span>}
		</div>
	);
};

FieldDatePicker.propTypes = {
	type: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	disabled: PropTypes.bool,
	inline: PropTypes.bool,
	input: PropTypes.shape().isRequired,
	help: PropTypes.string,
	meta: PropTypes.shape({
		touched: PropTypes.bool,
		error: PropTypes.string,
	}),
};

FieldDatePicker.defaultProps = {
	label: false,
	type: "date",
	disabled: false,
	help: null,
	placeholder: false,
	inline: false,
	meta: {
		touched: false,
		error: "",
	},
};

export default FieldDatePicker;
