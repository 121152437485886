import React, { useState, useMemo } from "react";
import { APIENUM } from "/wh/services/ApiEnum";
import useModal from "/wh/common/modalHook/customModalHook";
import WHTable from "/wh/components/WHTable";
import {
	Button,
	Page,
	PageHeader,
	PageTitle,
	PageActions,
	H1,
	Text,
	Modal,
} from "/wh/components/WhComponent/index";
import ProductFamilyForm from "./ProductFamilyForm";
import ApiService from "/wh/services/apiSearch/apiSearch";
import { SubmissionError } from "redux-form";
import * as productFamilyService from "../../services/product_families";

const ProductFamilyList = () => {
	const [dataProduct, setDataProduct] = useState([]);
	const [pages, setPages] = useState(0);

	// const { data, load } = useSearch(APIENUM.PRODUCT);
	const { isShowing, toggle } = useModal();

	const [editProductId, setEditProductId] = useState(null);

	const onLoad = async (state) => {
		try {
			const fullData = await productFamilyService.search(state);
			const data = fullData["hydra:member"];
			setDataProduct(data);
			setPages(
				state
					? Math.ceil(
							fullData["hydra:totalItems"] / state.pageSize || 1
					  )
					: null
			);
		} catch (error) {
			console.error({ error });
		}
	};
	const onRowEdit = (productId) => () => {
		console.log(productId)
		setEditProductId(productId);
		toggle();
	};

	const columns = [
		{
			Header: "",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			maxWidth: 100,
			Cell: (row) => (
				<Button small color="info" onClick={onRowEdit(row.original.id)}>
					<i className="fa fa-edit" />
				</Button>
			),
		},
		{
			Header: "Reference",
			accessor: "reference",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
		},
		{
			Header: "Famille",
			accessor: "name",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
			Cell: (row) => (
				<div>
					<span>{row.value}</span>
				</div>
			),
		},
		{
			Header: "Famille parente",
			accessor: "parent.name",
			className: "intervention_col_container",
			headerClassName: "intervention_col_header",
		},
	];

	const onProductFamilyFormSubmit = (data) => {
		console.log(data)
		const finalData =
			editProductId !== null ? { ...data, id: editProductId } : data;

		const service = new ApiService(`/api/${APIENUM.PRODUCT_FAMILY}`);

		const finalService =
			editProductId !== null ? service.update : service.create;

		finalData.parent.value === null ? finalData.parent = null : null;

		return finalService(finalData)
			.then(() => {
				console.log("flag ?");
				onLoad();
				toggle();
			})
			.catch((error) => {
				console.log({ error });
				throw new SubmissionError(error);
			});
	};

	const productFormInitialValues = useMemo(() => {
		if (editProductId !== null) {
			const { name, parent } = dataProduct.find(
				(product) => product.id === editProductId
			);

			const parentUri = parent ? parent["@id"] : null;
			const parentName = parent ? parent.name : null;
			const parentId = parent ? parent.id : null;

			return {
				name,
				parent: {value: parentUri, label: parentName, id: parentId}
			};
		} else {
			return null;
		}
	}, [editProductId, dataProduct]);

	return (
		<Page>
			<PageHeader>
				<PageTitle>
					<H1>Liste des familles de produits</H1>
					<PageActions>
						{/*<Button outline onClick={onCreateProductClick}>*/}
						{/*	{" "}*/}
						{/*	<i className="fa fa-plus" /> Créer un produit*/}
						{/*</Button>*/}
						{/*<Button outline onClick={synchronise}>*/}
						{/*	{" "}*/}
						{/*	<i className="fa fa-download" /> Synchroniser les*/}
						{/*	produits*/}
						{/*</Button>*/}
					</PageActions>
				</PageTitle>

				{/*<Text>*/}
				{/*	"Synhcronisez" les produits pour l'importer depuis la*/}
				{/*	tablette.*/}
				{/*</Text>*/}
			</PageHeader>

			<Modal
				visible={isShowing}
				onClose={toggle}
				title={
					editProductId !== null
						? "Modifier une famille produit"
						: "Créer un produit"
				}
			>
				{isShowing && (
					<ProductFamilyForm
						toggle={toggle}
						onSubmit={onProductFamilyFormSubmit}
						initialValues={productFormInitialValues}
					/>
				)}
			</Modal>

			<WHTable
				key="table"
				onFetchData={onLoad}
				pages={pages}
				data={dataProduct}
				columns={columns}
				className="intervention_table"
			/>
		</Page>
	);
};

export default ProductFamilyList;
