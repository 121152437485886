import React, { Component, createRef } from 'react';
import Loading from '@whatsonweb/loading';
import ApiConfig from '../../constants/ApiConfig';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { blank } from 'react-friends';

import {
	Card,
	CardBody,
	CardHeader,
	Button,
	Jumbotron,
	ButtonGroup,
	NavItem,
	Nav,
	Collapse,
	NavbarToggler,
	Navbar,
} from 'reactstrap';

import * as contractService from '../../services/contract';
import Delay from './Popup/ContractPopup/TabContent/Delay/';
import Vt from './Popup/ContractPopup/TabContent/Vt';
import EcoHabitant from './Popup/ContractPopup/TabContent/EcoHabitant';
import Financement from './Popup/ContractPopup/TabContent/Financement';
import Installation from './Popup/ContractPopup/TabContent/Installation';
import CreateEventPopup from './Popup/CreateEventPopup';
import CreateMessagePopup from './Popup/CreateMessagePopup';

const RowField = ({ label, value }) => (
	<div className="row">
		<div className="col-md-4">{label} : </div>
		<div className="col-md-8">
			<strong>{value}</strong>
		</div>
	</div>
);

RowField.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
};

class Contract extends Component {
	state = {
		loaded: false,
		data: {},
	};

	eventPopup = createRef();
	messagePopup = createRef();

	componentDidMount() {
		this.load();
	}

	load = () => {
		contractService.view(this.props.match.params.id).then(data => {
			console.warn({ data });
			this.setState({
				data,
				loaded: true,
			});
		});
	};

	render() {
		const { match } = this.props;
		const { data } = this.state;

		if (!this.state.loaded) return <Loading />;

		return (
			<div className="animated fadeIn">
				<CreateEventPopup ref={this.eventPopup} />
				<CreateMessagePopup ref={this.messagePopup} />

				<Jumbotron>
					<div className="row">
						<div className="col-md-8">
							<h1 className="display-4">{data.customer.name}</h1>
						</div>
						<div className="col-md-4 text-center">
							<h1 className="display-4">Commande</h1>
						</div>
					</div>
				</Jumbotron>

				<div className="row">
					<div className="col-md-8">
						<Card>
							<CardHeader>
								<i className="fa fa-black-tie" /> par {data.senior.firstname}{' '}
								{data.senior.lastname}
								<div className="card-actions">

								</div>
							</CardHeader>

							<CardBody>
								<div className="row">
									<div className="col-md-6">
										<RowField label="Rue" value={data.customer.street} />
										<RowField
											label="Ville"
											value={`${data.customer.zipcode} ${data.customer.city}`}
										/>
										<RowField label="Téléphone" value={data.customer.phone} />
										<RowField label="Email" value={data.customer.email} />
									</div>

									<div className="col-md-6">
										{data.junior && (
											<RowField label="Junior" value={data.junior.lastname} />
										)}
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className="col-md-4" style={{ display: 'flex', flexDirection: 'column' }}>
						<Card color={data.statusColor} style={{ flex: 1 }}>
							<CardBody
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}>
								<h1 style={{ textAlign: 'center' }}>
									{data.statusName}
									<small style={{ display: 'block' }}>{data.subStatusName}</small>
								</h1>
							</CardBody>

							<ButtonGroup>
								<Button
									style={{ flex: 1 }}
									onClick={() => this.messagePopup.current.handleOpen(data)}>
									<i className="fa fa-send" /> Envoyer un message
								</Button>
								<Button
									style={{ flex: 1 }}
									onClick={() => this.eventPopup.current.handleOpen(data)}>
									<i className="fa fa-plus" /> Créer un évenement
								</Button>
							</ButtonGroup>
						</Card>
					</div>
				</div>

				<Card>
					<Navbar color="faded" light expand="md" style={{ padding: 0 }}>
						<NavbarToggler
							onClick={() => this.setState({ isOpen: !this.state.isOpen })}
							style={{ width: '100%' }}
						/>
						<Collapse isOpen={this.state.isOpen} navbar>
							<Nav navbar style={{ flex: 1 }}>
								<NavItem style={{ flexGrow: 1 }}>
									<Button
										block
										color={this.state.data.statusDelayColor}
										href={`#${match.url}/delay`}>
										<i className="fa fa-check fa-fw" /> Delais retractation
									</Button>
								</NavItem>
								<NavItem style={{ flexGrow: 1 }}>
									<Button
										block
										color={this.state.data.statusBankColor}
										href={`#${match.url}/financement`}>
										<i className="fa fa-check fa-fw" /> Financement
									</Button>
								</NavItem>
								<NavItem style={{ flexGrow: 1 }}>
									<Button
										block
										color={this.state.data.statusEcoColor}
										href={`#${match.url}/eco-habitant`}>
										<i className="fa fa-check fa-fw" /> Eco Habitant
									</Button>
								</NavItem>
								<NavItem style={{ flexGrow: 1 }}>
									<Button
										block
										color={this.state.data.statusVtColor}
										href={`#${match.url}/vt`}>
										<i className="fa fa-clock-o fa-fw" /> Visite technique
									</Button>
								</NavItem>
								<NavItem style={{ flexGrow: 1 }}>
									<Button
										block
										color={this.state.data.statusColor}
										href={`#${match.url}/installation`}>
										<i className="fa fa-clock-o fa-fw" /> Installation
									</Button>
								</NavItem>
							</Nav>
						</Collapse>
					</Navbar>
				</Card>

				<Switch>
					<Route
						path={`${match.url}/delay`}
						name="Delay retractation"
						component={() => (
							<Delay reload={this.load} data={this.state.data} {...this.props} />
						)}
					/>
					<Route
						path={`${match.url}/eco-habitant`}
						name="Eco habitant"
						component={() => (
							<EcoHabitant
								reload={this.load}
								data={this.state.data}
								{...this.props}
							/>
						)}
					/>
					<Route
						path={`${match.url}/vt`}
						name="Visite technique"
						component={() => (
							<Vt reload={this.load} data={this.state.data} {...this.props} />
						)}
					/>
					<Route
						path={`${match.url}/installation`}
						name="Installation"
						component={() => (
							<Installation
								reload={this.load}
								data={this.state.data}
								{...this.props}
							/>
						)}
					/>
					<Route
						path={`${match.url}/financement`}
						name="Financement"
						component={() => (
							<Financement
								reload={this.load}
								data={this.state.data}
								{...this.props}
							/>
						)}
					/>
					<Redirect from={`${match.url}`} to={`${match.url}/financement`} />
				</Switch>
			</div>
		);
	}
}

export default Contract;
