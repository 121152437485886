import React, { Component } from "react";
import { SubmissionError } from "redux-form";
import FormLogin from "./formLogin";
import * as session from "../../../services/session";
import { Card, Text } from "/wh/components/WhComponent/index";

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: {},
		};

		this.submit = this.submit.bind(this);
	}

	submit = async (values) => {
		try {
			await session.login(values.username, values.password);
			this.props.history.push("/dashboard");
		} catch (error) {
			console.log(error);
			throw new SubmissionError({ _error: error });
		}
	};

	render() {
		return (
			<div className="flex h-full flex-col items-center justify-center bg-gray-100">
				<img
					src={"img/logo.png"}
					style={{ width: "250px" }}
					className="mb-10"
					alt=""
				/>
				<Card className="w-1/5">
					<Text>Merci de vous identifier</Text>

					<FormLogin onSubmit={this.submit} />
				</Card>
			</div>
		);
	}
}

export default Login;
