import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { FieldTxt } from '../../../../components/Form/wh-field';
import { CardBody, CardFooter, Form, Alert } from 'reactstrap';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import Loading from '@whatsonweb/loading';

import PropTypes from 'prop-types';

const FormEditUser = ({ error, handleSubmit, submitting }) => (
	<Form onSubmit={handleSubmit} className="form-horizontal">
		<CardBody>
			{submitting && <Loading />}

			<div className="row">
				<div className="col-md-6">
					<Field label="Nom : " name="socialReason" component={FieldTxt} type="text" />

					<Field label="Code : " name="code" component={FieldTxt} type="text" />
					{error && <Alert color="danger">{error}</Alert>}
				</div>
			</div>

			{error && (
				<Alert color="danger">
					<div dangerouslySetInnerHTML={{ __html: error }} />
				</Alert>
			)}
		</CardBody>

		<CardFooter>
			<LaddaButton
				className="btn btn-success btn-ladda"
				loading={submitting}
				data-style={EXPAND_LEFT}
				type="submit">
				<i className="fa fa-save" /> Enregistrer
			</LaddaButton>
		</CardFooter>
	</Form>
);

FormEditUser.defaultProps = {
	error: '',
};

FormEditUser.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	submitting: PropTypes.bool.isRequired,
	error: PropTypes.string,
};

export default reduxForm({ form: 'FormEditUser' })(FormEditUser);
