import React from 'react';
import {Field, reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import {FieldTxt} from '../../components/Form/wh-field';
import {Card, CardHeader, CardBody, CardFooter, Form, Alert, Button} from 'reactstrap';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';



class FormAccount extends React.Component {

    constructor(props) {

        super(props);

    }


    render() {


        const {error, handleSubmit, pristine, reset, submitting} = this.props;


        return (

            <Card>
                <Form onSubmit={handleSubmit} className="form-horizontal">
                <CardHeader>Mon compte</CardHeader>
                <CardBody>


                        <Field
                            label="Prénom : "
                            name="firstname"
                            component={FieldTxt}
                            type="text"
                        />

                        <Field
                            label="Nom : "
                            name="lastname"
                            component={FieldTxt}
                            type="text"
                        />

                        <Field
                            label="Email : "
                            name="email"
                            component={FieldTxt}
                            type="text"
                        />

                        <Field
                            label="Entreprise : "
                            name="company"
                            component={FieldTxt}
                            type="text"
                        />

                        <Field
                            label="Mobile : "
                            name="mobile"
                            component={FieldTxt}
                            type="text"
                        />

                        <Field
                            label="Job : "
                            name="job"
                            component={FieldTxt}
                            type="text"
                        />


                    {error && <Alert color="danger">{error}</Alert>}

                        <button type="submit" style={{position:'absolute', 'left' : '-100000px'}} />


                </CardBody>

                <CardFooter>

                    <LaddaButton
                        className="btn btn-success btn-ladda"
                        loading={submitting}
                        data-style={EXPAND_LEFT}
                        type={'submit'}
                    >
                        <i className="fa fa-save"></i> Enregistrer </LaddaButton>


                </CardFooter>
                </Form>

            </Card>


        );

    }

};


FormAccount = reduxForm({
    form: 'FormAccount'
})(FormAccount);

export default FormAccount

