import React, { useState, useMemo } from 'react'
import { APIENUM } from '/wh/services/ApiEnum'
import useSearch from '../../services/apiSearch/searchHook'
import { Link, Redirect } from 'react-router-dom';
import Survey, { QuestionType } from './Survey'
import RapportSurvey from './RapportSurvey'
import ApiService from '/wh/services/apiSearch/apiSearch'
import { Col, Row } from 'reactstrap'

const RapportView = ({ match }) => {
  const { data: rapport } = useSearch(APIENUM.RAPPORT, [], true, match.params.rapportId);
  const { data: contract } = useSearch(APIENUM.CONTRACT, [], true, match.params.bdcId);
  const [updateRapportPending, setUpdateRapportPending] = useState(false)

  const [redirection, setRedirect] = useState(false);

  const redirect = (e) => {
		e.stopPropagation();
		e.preventDefault();
		setRedirect(true);
  }

  // Prefills the image survey question with the already saved pictures
  const assignPictureQuestionDefaultValue = (
    remoteImageCollection
  ) => {
    let remotelyStoredImages = []

    if (remoteImageCollection) {
      remotelyStoredImages = remoteImageCollection.files.map(
        file => ({
          'uri': file['@id'],
          content: file.url
        })
      )
    }

    return [...remotelyStoredImages]
  }

  const formatQuestionnaireWithImages = async (questions, reponses) => {
    const imageTypeIdxs = []

    questions.forEach((question) => {
      if (question.type === QuestionType.IMAGE) {
        imageTypeIdxs.push(question.idx)
      }
    })

    const newReponses = [...reponses]
    
    await Promise.all(
      reponses.filter((reponse) => imageTypeIdxs.includes(reponse.idx) )
      .map(async (reponse) => {
        const newValues = []

        const service = new ApiService(`/api/${APIENUM.UPLOAD_FILE}`)

        await Promise.all(
          reponse.value.map(async (imageObject) => {
            if (imageObject.file) {
              const result = await service.uploadFile(imageObject.file)

              newValues.push({ content: result.url, uri: result.uri })
            } else {

              newValues.push(imageObject)
            }
          })
        )

        const reponseIndex = newReponses.findIndex((item) => item.idx === reponse.idx)
        newReponses[reponseIndex] = { idx: reponse.idx, value: newValues }
      })
    )


    return newReponses
  }

  const updateRapport = async (newRapport) => {

    const service = new ApiService(`/api/${APIENUM.RAPPORT}`)

    setUpdateRapportPending(true)

    try {
      await service.update(newRapport)

      setUpdateRapportPending(false)
    } catch (error) {
      setUpdateRapportPending(false)
    }
  }

  const onProductSurveySave = async (questions, reponses) => {
    const formattedReponses = await formatQuestionnaireWithImages(questions, reponses)

    const newRapport = {
      id: rapport.id,
      qcmResponse: rapport.qcmResponse
    }

    newRapport.qcmResponse.question = JSON.stringify(questions)
    newRapport.qcmResponse.response = JSON.stringify(formattedReponses)

    try {
      await updateRapport(newRapport)
      return true
    } catch (error) {
      return false
    }
  }

  // Seul les visites techniques ont un questionnaire d'intervention
  const isVisiteTechniqueType = rapport.intervention ? 
    rapport.intervention.type === '/api/intervention_types/1'
  : 
    false

  return (
    <div className='rapport-container'>
      { rapport.id && contract.products &&
        <div>
          <div>
            <div onClick={redirect}><a href='#'>{"< Retour à la liste des contrats"}</a></div>
            <div className={'title'}><span>{`Rapport de l'intervention ${rapport.intervention.id}`}</span></div>
          </div>

          <Row>

          { isVisiteTechniqueType && 
            <Col xs={12} lg={6}>
              <div className='survey-frame'>
                <div className='survey-title'>Questionnaire Visite Technique</div>
                  <RapportSurvey
                    rapport={rapport}
                    contractProducts={contract.products}
                    formatQuestionnaireWithImages={formatQuestionnaireWithImages}
                    updateRapport={updateRapport}
                    assignPictureQuestionDefaultValue={assignPictureQuestionDefaultValue}
                  />
              </div>
            </Col>
          }
              
          <Col xs={12} lg={isVisiteTechniqueType ? 6 : 12}>
            <div className='survey-frame'>
              <div className='survey-title'>Questionnaire Produit</div>
              {<Survey
                questions={JSON.parse(rapport.qcmResponse.question)}
                defaultAnswers={JSON.parse(rapport.qcmResponse.response)}
                onSave={onProductSurveySave}
              />}
            </div>
          </Col>

          </Row>

        </div>
      }
      {redirection &&
				<Redirect to={`/customer_contracts/${match.params.id}`} />
			}
    </div>
  )
}

export default RapportView