import React, { useState, useEffect } from "react";
import useModal from "/wh/common/modalHook/customModalHook";
import { Table, Input } from "reactstrap";
import { toast } from "react-toastify";
import ApiService from "/wh/services/apiSearch/apiSearch";
import { APIENUM } from "/wh/services/ApiEnum";
import { useMemo } from "react";
import {
	Card,
	Modal,
	CardHeader,
	FormActions,
	Text,
	CardTitle,
	Button,
	Select,
	Label,
	Value,
	H3,
} from "/wh/components/WhComponent/index";

const Document = ({ contract, updateContract }) => {
	const { isShowing, toggle: modalToggle } = useModal();

	// If the index of a document is in there, the "name" field is invalid
	// and therefore should be bordered with red to alert the user
	const [
		invalidNameDocumentIndexes,
		setInvalidNameDocumentIndexes,
	] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	const newDocument = {
		name: "",
		type: null,
		file: null,
	};

	const [documents, setDocuments] = useState(contract.documents);

	const toggle = () => {
		setDocuments(contract.documents);
		modalToggle();
	};

	useEffect(() => {
		setDocuments(contract.documents);
	}, [contract.documents]);

	const checkIfRowsValid = () => {
		if (documents.length !== 0) {
			const lastDocument = documents[documents.length - 1];

			if (!lastDocument.name || invalidNameDocumentIndexes.length > 0) {
				toast.error(
					"Vous devez donner un nom à chacun de vos dossiers"
				);

				if (invalidNameDocumentIndexes.length === 0) {
					const newList = [...invalidNameDocumentIndexes];

					newList.push(documents.length - 1);

					setInvalidNameDocumentIndexes(newList);
				}

				return false;
			}

			if (!lastDocument.type) {
				toast.error(
					"Ajouter un type pour le dernier document que vous avez ajouté"
				);
				return false;
			}

			if (!lastDocument.file) {
				toast.error(
					"Ajouter un fichier pour le dernier document que vous avez ajouté"
				);
				return false;
			}
		}

		return true;
	};

	const addDocument = () => {
		if (checkIfRowsValid()) {
			setDocuments([...documents, newDocument]);
		}
	};

	const updateDocument = (updatedDocument, documentIndex) => {
		const newDocuments = [...documents];

		newDocuments[documentIndex] = updatedDocument;

		setDocuments(newDocuments);
	};

	const deleteDocument = (documentIndex) => () => {
		const newNameErrorIndexes = [...invalidNameDocumentIndexes];

		const selfIndex = newNameErrorIndexes.indexOf(documentIndex);
		if (selfIndex !== -1) {
			newNameErrorIndexes.splice(selfIndex, 1);

			setInvalidNameDocumentIndexes(newNameErrorIndexes);
		}

		const newDocuments = [...documents];

		newDocuments.splice(documentIndex, 1);

		setDocuments(newDocuments);
	};

	const documentTypes = useMemo(
		() =>
			contract.documentTypes.map((documentType) => ({
				label: documentType,
				value: documentType,
			})),
		[contract]
	);

	const onTypeChange = (documentIndex) => ({ value }) =>
		updateDocument(
			{ ...documents[documentIndex], type: value },
			documentIndex
		);

	const onNameChange = (documentIndex) => (event) => {
		// error management
		const newInvalidNameDocumentIndexes = [...invalidNameDocumentIndexes];
		const selfErrorIndex = invalidNameDocumentIndexes.indexOf(
			documentIndex
		);

		if (event.target.value === "") {
			newInvalidNameDocumentIndexes.push(documentIndex);

			setInvalidNameDocumentIndexes(newInvalidNameDocumentIndexes);
		} else if (selfErrorIndex !== -1) {
			newInvalidNameDocumentIndexes.splice(selfErrorIndex, 1);

			setInvalidNameDocumentIndexes(newInvalidNameDocumentIndexes);
		}

		return updateDocument(
			{ ...documents[documentIndex], name: event.target.value },
			documentIndex
		);
	};

	const onDocumentFileChange = (documentIndex) => (event) => {
		const file = event.target.files[0];

		let reader = new FileReader();

		reader.onload = () => {
			updateDocument(
				{ ...documents[documentIndex], file },
				documentIndex
			);
		};

		reader.readAsDataURL(file);
	};

	const onListSubmit = async () => {
		const lastRowValid = checkIfRowsValid();

		if (!lastRowValid) {
			return false;
		}

		setIsLoading(true);

		const service = new ApiService(`/api/${APIENUM.UPLOAD_FILE}`);

		const newDocuments = [];

		await Promise.all(
			documents.map(async (doc) => {
				// If the document already exists, we only edit name and types, otherwise we create it
				if (doc.id) {
					newDocuments.push({
						id: doc.id,
						name: doc.name,
						type: doc.type,
					});
				} else {
					try {
						const result = await service.uploadFile(doc.file);

						newDocuments.push({
							type: doc.type,
							name: doc.name,
							file: result.uri,
						});
					} catch (e) {
						toast.error("Une erreur est survenue");
						setIsLoading(false);
					}
				}
			})
		);

		setIsLoading(false);

		updateContract({
			documents: newDocuments,
		});

		toggle();
	};

	console.log({ documents });

	return (
		<Card small>
			<CardHeader>
				<CardTitle>
					<H3>Documents</H3>
					<Button outline onClick={toggle}>
						<i className="fa fa-edit" />
						Modifier
					</Button>
				</CardTitle>
			</CardHeader>
			{documents.map((document, index) => {
				return (
					<div className="flex" key={index}>
						<Label className="w-1/3">{document.type} : </Label>
						<Value className="w-2/3">
							{document.file && (
								<a href={document.file.url} target="_blank">
									<i className={"fa fa-external-link"} />{" "}
									{document.name}
								</a>
							)}
						</Value>
					</div>
				);
			})}

			<Modal
				visible={isShowing}
				onClose={toggle}
				title="Ajouter un document"
				customStyles={{
					height: "auto",
					maxHeight: "80%",
					overflow: "auto",
				}}
			>
				{documents.length === 0 && (
					<Text>Aucun document lié à ce contrat</Text>
				)}
				{documents.length > 0 &&
					documents.map((document, index) => (
						<Card small className="shadow relative" key={index}>
							<div className="flex mb-2">
								<Label className="w-1/3">
									Type de document :
								</Label>
								<div className="w-2/3">
									<Select
										value={
											document.type !== null
												? {
														label: document.type,
														value: document.type,
												  }
												: null
										}
										onChange={onTypeChange(index)}
										options={documentTypes}
										placeholder="Sélectionner un type"
										isSearchable={false}
									/>
								</div>
							</div>
							<div className="flex mb-2">
								<Label className="w-1/3">
									Nom du document :{" "}
								</Label>
								<div className="w-2/3">
									<Input
										type="text"
										name="name"
										onChange={onNameChange(index)}
										className="mb-2 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
										value={document.name}
										invalid={
											invalidNameDocumentIndexes.indexOf(
												index
											) !== -1
										}
									/>
								</div>
							</div>
							<div className="flex mb-2">
								<Label className="w-1/3">Fichier : </Label>
								<div className="w-2/3">
									{document.id ? (
										<a
											href={document.file.url}
											target="_blank"
										>
											<i
												className={
													"fa fa-external-link"
												}
											/>{" "}
											Télécharger
										</a>
									) : (
										<Input
											type="file"
											name="file"
											onChange={onDocumentFileChange(
												index
											)}
											className="mb-2 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
										/>
									)}
								</div>
							</div>
							<Button
								small
								block
								color="info"
								onClick={deleteDocument(index)}
							>
								<i className="fa fa-trash" /> Supprimer
							</Button>
						</Card>
					))}

				<Button color="warning" block onClick={addDocument}>
					<i className="fa fa-plus" /> Ajouter un document
				</Button>
				<FormActions>
					<Button
						color="transparent"
						onClick={(e) => {
							toggle();
						}}
					>
						&nbsp;Annuler
					</Button>
					<Button
						loading={isLoading}
						type={"submit"}
						onClick={onListSubmit}
					>
						&nbsp;Valider
					</Button>
				</FormActions>
			</Modal>
		</Card>
	);
};

export default Document;
