import React from 'react';
import { compose, lifecycle, withHandlers } from 'recompose';
import { withTabHandler, withOpen, withActiveTab, withLoaded } from 'react-friends';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';

import {
	ModalBody,
	Nav,
	NavLink,
	NavItem,
	TabContent,
	TabPane,
	Button,
	Modal,
	ModalHeader,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
} from 'reactstrap';
import Loading from '@whatsonweb/loading';
import DelayTabContent from './TabContent/Delay';
import FinancementTabContent from './TabContent/Financement';
import EcoHabitantTabContent from './TabContent/EcoHabitant';
import TechnicalVisitTabContent from './TabContent/Vt';
import EnvoyerMessage from './TabContent/EnvoyerMessage'
import InstallationTabContent from './TabContent/Installation';

import {
	TABS,
	DELAY_TAB,
	FINANCIAL_TAB,
	ECO_HAB_TAB,
	VISIT_TAB,
	ENVOYER_MESSAGE,
} from './constants';

import { fetchContract, getCurrentContract } from '../../redux';
import Rodal from 'rodal'

const getCustomerName = contract => contract && contract.customer && contract.customer.name;

const ContractPopup = ({
	className,
	isOpen,
	handleClose,
	contract,
	onStyleTab,
	onToggle,
	activeTab,
	onReload,
	loaded,
	customStyles = { height: "auto", maxHeight: "80%", overflowY: "scroll" },
}) =>
	isOpen ? (
		contract ? (
			loaded ? (
				<Modal
					isOpen={isOpen}
					className={`${className} contractPopup modal-dialog`}
					fade={false}
					size="lg"
				>

					<ModalHeader toggle={handleClose}>{getCustomerName(contract)}</ModalHeader>
					<div>
						<ModalBody>
							<Row>
								<Col md={3}>
									<Card outline color={contract.statusColor}>
										<CardHeader>{contract.statusName}</CardHeader>
										<CardBody>
											<Row>
												<Col md={4}>ID : </Col>
												<Col><strong>#{contract.id}</strong></Col>
											</Row>
											<Row>
												<Col md={4}>Date : </Col>
												<Col>
													<strong>
														<Moment fromNow locale="fr">
															{contract.date}
														</Moment>
													</strong>
												</Col>
											</Row>
											<Row>
												<Col md={4}>Nom : </Col>
												<Col>
													<strong>{contract.customer.name}</strong>
												</Col>
											</Row>
											<Row>
												<Col md={4}>Ville : </Col>
												<Col>
													<strong>{contract.customer.city}</strong>
												</Col>
											</Row>
											<Row>
												<Col md={4}>Tel : </Col>
												<Col>
													<strong>{contract.customer.phone}</strong>
												</Col>
											</Row>
											<Row>
												<Col md={4}>Mobile : </Col>
												<Col>
													<strong>{contract.customer.mobile}</strong>
												</Col>
											</Row>
											<Row>
												<Col md={4}>Email : </Col>
												<Col>
													<strong>{contract.customer.email}</strong>
												</Col>
											</Row>
											<Row>
												<Col md={4}>Montant : </Col>
												<Col>
													<strong><NumberFormat value={contract.amount} displayType="text" thousandSeparator prefix="€" /></strong>
												</Col>
											</Row>
											<Row>
												<Col md={4}>Date VT : </Col>
												<Col>
													<strong>{contract.dateVt && <Moment format="DD/MM/YYYY">{contract.dateVt}</Moment>}</strong>
												</Col>
											</Row>
											<Row>
												<Col md={4}>Technicien : </Col>
												<Col>
													<strong>{contract.technicienVt && contract.technicienVt.name}</strong>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Col>
								<Col md={9}>
									<Nav tabs>
										{TABS.map(({ tabName, label, colorKey }) => (
											<NavItem key={tabName}>
												<NavLink
													className={onStyleTab(tabName)}
													onClick={onToggle(tabName)}
													style={{ padding: '0' }}>
													<Button
														block
														color={contract[colorKey] || 'success'}>
														<i className="fa fa-check fa-fw" /> {label}
													</Button>
												</NavLink>
											</NavItem>
										))}
									</Nav>

									<TabContent activeTab={activeTab}>
										<TabPane tabId={DELAY_TAB}>
											<DelayTabContent onReload={onReload} data={contract} />
										</TabPane>
										<TabPane tabId={ECO_HAB_TAB}>
											<EcoHabitantTabContent
												onReload={onReload}
												data={contract}
											/>
										</TabPane>
										<TabPane tabId={FINANCIAL_TAB}>
											<FinancementTabContent
												onReload={onReload}
												data={contract}
											/>
										</TabPane>

										<TabPane tabId={VISIT_TAB}>
											<TechnicalVisitTabContent
												onReload={onReload}
												data={contract}
											/>
										</TabPane>

										<TabPane tabId={ENVOYER_MESSAGE}>
											<EnvoyerMessage
												data={contract}
												onReload={onReload}
											/>

										</TabPane>

									</TabContent>
								</Col>
							</Row>
						</ModalBody>
					</div>
				</Modal>
			) : (
				<Loading />
			)
		) : (
			<Loading />
		)
	) : null;

ContractPopup.defaultProps = {
	className: '',
	contract: null,
};

ContractPopup.propTypes = {
	className: PropTypes.string,
	handleClose: PropTypes.func.isRequired,
	onStyleTab: PropTypes.func.isRequired,
	onReload: PropTypes.func.isRequired,
	onToggle: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	loaded: PropTypes.bool.isRequired,
	activeTab: PropTypes.string.isRequired,
	contract: PropTypes.shape({}),
};

const withLifeCycle = lifecycle({
	componentDidMount() {
		this.props.setMethods({
			handleOpen: () => {
				this.props.setIsOpen(true);
				this.props.setLoaded(false);
				setTimeout(() => {
					this.props.setLoaded(true);
				}, 500);
			},
		});
	},
	componentWillUnmount() {
		this.props.setMethods(null);
	},
});

export const mapStateToProps = state => ({
	contract: getCurrentContract(state),
});

export const mapDispatchToProps = (dispatch, props) =>
	bindActionCreators({ onReload: () => fetchContract(props.contract.id) }, dispatch);

const withMapState = connect(mapStateToProps);
const withDispatch = connect(
	null,
	mapDispatchToProps
);

const withClosePopupHandler = withHandlers({
	handleClose: props => () => {
		props.setIsOpen(false);
		props.onSetCurrentContract(null);
	},
});

export default compose(
	withActiveTab(DELAY_TAB),
	withOpen,
	withLoaded,
	withTabHandler,
	withLifeCycle,
	withMapState,
	withDispatch,
	withClosePopupHandler
)(ContractPopup);
