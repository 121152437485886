import React from "react";
import { reduxForm, Field } from "redux-form";
import * as validator from "/wh/components/Form/wh-validator";
import {
	FieldSelect,
	FieldTxt,
	Form,
	FormActions,
	Button,
} from "/wh/components/WhComponent/index";

const SectorForm = ({ handleSubmit, toggle }) => {
	const onCancelClick = (event) => {
		event.preventDefault();
		toggle();
	};

	return (
		<Form onSubmit={handleSubmit}>
			<Field
				label="Nom : "
				name="name"
				component={FieldTxt}
				type="text"
				validate={[validator.required]}
			/>

			<FormActions>
				<Button color="transparent" onClick={onCancelClick}>
					Annuler
				</Button>
				<Button onClick={handleSubmit}>Valider</Button>
			</FormActions>
		</Form>
	);
};

export default reduxForm({ form: "SectorForm" })(SectorForm);
