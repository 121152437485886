import isEmpty from "lodash.isempty";

const QueryBuilder = (state) => {
	if (!state || isEmpty(state)) return {};

	const { pageSize, page, sorted = [], filtered } = state;

	const params = {};

	if (pageSize) params.itemsPerPage = pageSize;
	if (page) params.page = page + 1;

	/**
	 * Order
	 */
	params.order = {};

	sorted.map((el) => {
		params.order[el.id] = el.desc ? "desc" : "asc";
	});

	/**
	 * Filter
	 */
	filtered.map((el) => {
		switch (el.id) {
			case "statusName":
				params["status"] = el.value;
				break;
			default:
				params[el.id] = el.value;
				break;
		}
	});

	return params;
};

export default QueryBuilder;
