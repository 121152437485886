import moment from 'moment';

export const parseDate = value => {
	if (value === undefined) return undefined;
	if (value === '') return '';
	return moment(value).format('YYYY-MM-DD');

}

/**
 * Todo La fonction doit formater la date au bon formet (moment() ? )
 * @param {*} value
 */
export const formatDate = value => {
	if (value === undefined) return undefined;
	if (value === '') return '';
	return moment(value).format('YYYY-MM-DD');
}

export default { parseDate, formatDate };
