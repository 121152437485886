import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Loading from '@whatsonweb/loading';
import { SubmissionError } from 'redux-form';

import * as contractEventService from '/wh/services/contract-event';
import * as bankFolderService from '/wh/services/bank-folder';
import * as bankFolderFileService from '/wh/services/bank-folder-file';

import { Modal, ModalHeader, ModalFooter, ModalBody, Table, Button } from 'reactstrap';

class PreAccordPopup extends Component {
	state = {
		loaded: true,
		open: false,
		bank: {},
	};

	componentDidMount() {
		this.load();
	}

	load = () => {
		let payment = null;

		this.props.data.payments.map(el => {
			if (el.type == 1) payment = el;
		});


	};

	handleOpen = () => this.setState({ open: true });

	handleClose = () => this.setState({ loaded: true, open: false });

	onDecide = status => {
		const data = { status, fixed: false, statusFamily: 3 };

		this.setState({ loaded: false });

		return contractEventService
			.create(data)
			.then(() => {
				toast.success('Enregistrement réussi');
				this.handleClose();
			})
			.catch(error => {
				throw new SubmissionError(error);
			});
	};

	missingField = el => {
		this.setState({ loaded: false });

		return bankFolderFileService
			.update(el.id, { status: bankFolderFileService.STATUS_MISSING })
			.then(data => {
				this.setState({ loading: false, data });

				toast.success('Enregistrement réussi');
				this.load();
			});
	};

	render() {
		const { className } = this.props;

		return (
			<Modal isOpen={this.state.open} className={className} fade={false}>
				<ModalHeader toggle={() => this.handleClose()}>
					Préaccord de financement
				</ModalHeader>

				<ModalBody>
					<Table responsive striped hover>
						<thead className="thead-light">
							<tr>
								<th>Pièce</th>
								<th className="text-center">Statut</th>
							</tr>
						</thead>
						<tbody>
							{this.state.bank.bankFolderFiles &&
								this.state.bank.bankFolderFiles.map((el, index) => (
									<tr key={index}>
										<td>{el.name}</td>
										<td className="text-center">
											{el.status === 5 ? (
												<Button color="danger" style={{ flex: 1 }}>
													<i className="fa fa-exclamation-circle" /> pièce
													manquante{' '}
													<i className="fa fa-exclamation-circle" />
												</Button>
											) : (
												<Button
													color="info"
													id={el.id}
													style={{ flex: 1 }}
													onClick={() => {
														this.missingField(el);
													}}>
													pièce manquante ?
												</Button>
											)}
										</td>
									</tr>
								))}
						</tbody>
					</Table>
				</ModalBody>
				<ModalFooter style={{ display: 'flex' }}>
					{!this.state.loaded && <Loading />}
					<Button color="success" style={{ flex: 1 }} onClick={() => this.onDecide(13)}>
						<i className="fa fa-thumbs-up" /> Accepté
					</Button>
					<Button color="danger" style={{ flex: 1 }} onClick={() => this.onDecide(14)}>
						<i className="fa fa-thumbs-down" /> Refusé
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

export default PreAccordPopup;
