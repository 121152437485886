import { SET_CUSTOMERS, TYPES } from './constants';
import { createAction } from 'redux-friends';
import { formatDataFromSymfony } from '@whatsonweb/react-utils';
import * as costumerService from '../../../services/customer';

export const setCustomers = createAction(TYPES[SET_CUSTOMERS]);

export const fetchCustomers = (state = {}) => async dispatch => {
	try {
		const data = await costumerService.search(state);
		dispatch(setCustomers(formatDataFromSymfony(data)));
	} catch (error) {
		console.warn(error);
	}
};
