import React, { Component } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { SubmissionError } from 'redux-form';
import Loading from '@whatsonweb/loading';
import * as contractEventService from '/wh/services/contract-event';
import FormEventPopup from './Form';

import { connect } from 'react-redux';
import { getCurrentContract } from '../../redux';

class CreateEventPopup extends Component {
	state = { open: false, loaded: true };

	handleOpen = () => {
		this.setState({ open: true, loaded: false }, () => {
			setTimeout(() => {
				this.setState({ loaded: true });
			}, 300);
		});
	};

	handleClose = () => this.setState({ open: false });

	onSubmit = async data => {
		try {
			data.status = parseInt(data.status);
			await contractEventService.create(data);
			this.props.onSubmit && (await this.props.onSubmit());
			toast.success('Enregistrement réussi');
			this.handleClose();
		} catch (error) {
			throw new SubmissionError(error);
		}
	};

	render() {
		const { type, className, status, contract, technicians } = this.props;
		const { loaded, open } = this.state;

		const data = {
			contract: contract['@id'],
			statusFamily: type,
			fixed: false,
		};

		return loaded ? (
			<Modal isOpen={open} className={className} fade={false}>
				<ModalHeader toggle={this.handleClose}>Créer</ModalHeader>
				<FormEventPopup
					status={status}
					technicians={technicians}
					initialValues={data}
					onSubmit={this.onSubmit}
					handleClose={this.handleClose}
				/>
			</Modal>
		) : (
			<Loading />
		);
	}
}

CreateEventPopup.defaultProps = {
	className: '',
	status: null,
	onSubmit: () => {},
};

CreateEventPopup.propTypes = {
	type: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired])
		.isRequired,
	className: PropTypes.string,
	status: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
	contract: PropTypes.shape({}).isRequired,
	onSubmit: PropTypes.func,
};
export const mapStateToProps = state => ({
	contract: getCurrentContract(state),
});

export default connect(
	mapStateToProps,
	null,
	null,
	{ forwardRef: true }
)(CreateEventPopup);
