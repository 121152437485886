import React, {Component} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {Container} from "reactstrap";
import {connect} from "react-redux";
import {ToastContainer} from "react-toastify";
import Loading from "@whatsonweb/loading";
import {bindActionCreators} from "redux";

import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import Dashboard from "../../modules/Dashboard/Dashboard";
import MyPassword from "../../modules/Account/MyPassword";
import Account from "../../modules/Account/Account";
import Teams from "../../modules/Teams/";
import Sectors from "../../modules/Sectors/";
import Members from "../../modules/Members/";
import Member from "../../modules/Members/Member";
import Customers from "../../modules/Customers/";
import Customer from "../../modules/Customers/Customer";
import Contracts from "../../modules/Contracts";
import Contract from "../../modules/Contracts/Contract";
import InterventionManagement from "../../modules/Intervention/InterventionManagement";

import * as session from "../../services/session";
import {fetchStatus} from "../../redux/status/";
import {setUser} from "../../redux/user/";
import CustomersContractList from "/wh/modules/CustomersContractList";
import Questionnaires from "/wh/modules/Questionnaires/Questionnaires";
import QuestionnaireEdition from "/wh/modules/Questionnaires/QuestionnairesEdition";
import QuestionnaireView from "/wh/modules/Questionnaires/QuestionnaireView";
import Planning from "/wh/modules/Planning/Planning";
import ReduxLoader from "/wh/ReduxLoader";
import ProductList from "/wh/modules/Products/ProductList";
import ProductFamilyList from "/wh/modules/ProductFamily/ProductFamilyList";

class Full extends Component {
	state = { loaded: true };

	componentDidMount() {
		this.checkAuthentication();
	}

	redirectToLogin = () => this.props.history.push("/login");

	checkAuthentication = async () => {
		this.setState({ loaded: false });
		try {
			this.props.onFetchStatus();
			const data = await session.isAuthenticated();
			if (data === "null") {
				this.redirectToLogin();
			}
			this.props.onSetUser(data);
		} catch (error) {
			console.warn(error);
			this.redirectToLogin();
		} finally {
			this.setState({ loaded: true });
		}
	};

	render() {
		const { loaded } = this.state;

		return loaded ? (
			<div className="flex w-full bg-gray-100">
				<Sidebar {...this.props} />
				<div className="w-full">
					<Header />
					<div className="bg-gray-100">
						<ToastContainer
							position="top-center"
							hideProgressBar
							autoClose={3000}
						/>
						<ReduxLoader>
							<main className="main">
								<Container fluid>
									<Switch>
										<Route
											path="/dashboard"
											name="Dashboard"
											component={Dashboard}
										/>
										<Route
											path="/account/password"
											name="Changer mon mot de passe"
											component={MyPassword}
										/>
										<Route
											path="/account"
											name="Mon compte"
											component={Account}
										/>
										{session.checkRole("ROLE_ADMIN") && (
											<Route
												path="/members/:id"
												name="Edition d’un membre"
												component={Member}
											/>
										)}
										{session.checkRole("ROLE_ADMIN") && (
											<Route
												path="/members"
												name="Gestion des membres"
												component={Members}
											/>
										)}
										{session.checkRole("ROLE_ADMIN") && (
											<Route
												path="/teams"
												name="Gestion des équipes"
												component={Teams}
											/>
										)}
										{session.checkRole("ROLE_ADMIN") && (
											<Route
												path="/sectors"
												name="Gestion des secteurs"
												component={Sectors}
											/>
										)}
										{session.checkRole("ROLE_ADMIN") && (
											<Route
												path="/intervention_management"
												name="Gestion intervention"
												component={
													InterventionManagement
												}
											/>
										)}

										{session.checkRole("ROLE_ADMIN") && (
											<Route
												path="/customer/:id"
												name="Gestion des clients"
												component={Customer}
											/>
										)}
										{session.checkRole("ROLE_ADMIN") && (
											<Route
												path="/customers"
												name="Gestion des clients"
												component={Customers}
											/>
										)}

										{session.checkRole("ROLE_ADMIN") && (
											<Route
												path="/contracts/:id"
												name="Gestion des contrats"
												component={Contract}
											/>
										)}
										{session.checkRole("ROLE_ADMIN") && (
											<Route
												path="/contracts"
												name="Gestion des contrats"
												component={Contracts}
											/>
										)}
										{session.checkRole("ROLE_ADMIN") && (
											<Route
												path="/customer_contracts/:id"
												name="Gestion des interventions"
												component={
													CustomersContractList
												}
											></Route>
										)}
										{session.checkRole("ROLE_ADMIN") && (
											<Route
												path="/questionnaires"
												exact
												name="Gestion des questionnaires"
												component={Questionnaires}
											></Route>
										)}
										{session.checkRole("ROLE_ADMIN") && (
											<Route
												path="/questionnaires/:id"
												exact
												name="Edition Questionnaire"
												component={QuestionnaireEdition}
											></Route>
										)}
										{session.checkRole("ROLE_ADMIN") && (
											<Route
												path="/questionnaires/:id/view"
												name="Edition Questionnaire"
												component={QuestionnaireView}
											></Route>
										)}
										{session.checkRole("ROLE_ADMIN") && (
											<Route
												path="/dp"
												name="Planning"
												component={Planning}
											></Route>
										)}

										{session.checkRole("ROLE_ADMIN") && (
											<Route
											path="/products"
											name="Products"
											component={ProductList}
											></Route>
										)}

										{session.checkRole("ROLE_ADMIN") && (
											<Route
												path="/product_families"
												name="Famille de produits"
												component={ProductFamilyList}
											></Route>
										)}

										<Redirect from="/" to="/dashboard" />
									</Switch>
								</Container>
							</main>
						</ReduxLoader>
					</div>
				</div>
			</div>
		) : (
			<Loading />
		);
	}
}

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) => ({
	onFetchStatus: bindActionCreators(fetchStatus, dispatch),
	onSetUser: bindActionCreators(setUser, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Full);
