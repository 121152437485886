import React, { useMemo, useState } from "react";
import useModal from "/wh/common/modalHook/customModalHook";
import useSearch from "../../services/apiSearch/searchHook";
import { APIENUM } from "../../services/ApiEnum";
import ApiService from "/wh/services/apiSearch/apiSearch";
import WHTable from "../../components/WHTable";
import SectorForm from "./Form";
import { toast } from "react-toastify";

import {
	Button,
	Page,
	PageHeader,
	PageTitle,
	H1,
	Text,
	Modal,
} from "/wh/components/WhComponent/index";

const Sectors = () => {
	const { isShowing, toggle } = useModal();
	const { data, load, setFilters } = useSearch(APIENUM.SECTOR);
	const [selectedSector, setSelectedSector] = useState(null);

	const sectorService = new ApiService(`/api/${APIENUM.SECTOR}`);

	const deleteSector = async (id) => {
		if (confirm("Etes vous sûre de vouloir supprimer ce secteur ?")) {
			try {
				await sectorService.remove(id);

				toast.success("Secteur supprimé");
				load();
			} catch (e) {
				toast.error("Une erreur est survenue");
			}
		}
	};

	const openEditModal = (sector) => () => {
		setSelectedSector(sector);
		toggle();
	};

	const columns = useMemo(
		() => [
			{
				Header: "",
				accessor: "id",
				filterable: false,
				Cell: (row) => (
					<div>
						<Button
							color="info"
							small
							className="mr-2"
							onClick={openEditModal(row.original)}
						>
							<i className="fa fa-edit" />
						</Button>
						<Button
							color="danger"
							small
							onClick={() => deleteSector(row.value)}
						>
							<i className="fa fa-trash" />
						</Button>
					</div>
				),
				maxWidth: 120,
			},
			{
				Header: "Nom",
				accessor: "name",
				filterable: true,
			},
		],
		[]
	);

	const onSubmit = async (data) => {
		const service = !data.id ? sectorService.create : sectorService.update;

		try {
			await service(data);

			toggle();
			load();

			toast.success("Secteur créé");
		} catch (e) {
			toast.error("Une erreur est survenue");
		}
	};

	const openCreateModal = () => {
		setSelectedSector(null);
		toggle();
	};

	const initialValues = useMemo(() => {
		return selectedSector
			? {
					id: selectedSector.id,
					name: selectedSector.name,
			  }
			: null;
	}, [selectedSector]);

	return (
		<Page>
			<PageHeader>
				<PageTitle>
					<H1>Gestion des secteurs</H1>
					<Button onClick={openCreateModal}>
						<i className="fa fa-plus" /> Nouveau
					</Button>
				</PageTitle>

				<Text>Gérer les secteurs</Text>
			</PageHeader>
			<WHTable
				data={data}
				columns={columns}
				filterable
				onFetchData={(state) => {
					console.log("flag i", state.filtered.length);
					setFilters(state.filtered);
				}}
			/>
			<Modal
				visible={isShowing}
				onClose={toggle}
				title="Editer un secteur"
			>
				{isShowing && (
					<SectorForm
						onSubmit={onSubmit}
						toggle={toggle}
						initialValues={initialValues}
					/>
				)}
			</Modal>
		</Page>
	);
};

export default Sectors;
