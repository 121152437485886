import { SET_STATUS, SET_STATUS_TYPE } from './constants';
import {handleActions} from 'redux-actions';

const defaultState = null;


const reducer = handleActions({
	[SET_STATUS]: (state, action) => {
		return action.payload
	},
	[SET_STATUS_TYPE]: (state, action) => {
		return {...state, interventionTypes:action.payload}
	},
}, defaultState)

export default reducer;
