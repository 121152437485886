import React, { Component, createRef } from 'react';
import Loading from '@whatsonweb/loading';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, CardHeader, Table, Button } from 'reactstrap';

import * as eventService from '/wh/services/event';
import CreateEventPopup from '../../../CreateEventPopup';

class Delay extends Component {
	state = {
		loaded: true,
		events: [],
	};

	eventPopup = createRef();

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const { data, status } = this.props;
		try {
			const fetchedData = await eventService.search({
				'contract.id': data['id'],
				statusFamily: status.statusFamily['DELAY'],
			});
			this.setState({ events: fetchedData['hydra:member'], loaded: true });
		} catch (error) {
			console.error(error);
		}
	};

	submit = async () => {
		await this.load();
		await this.props.onReload();
	};

	render() {
		const { status } = this.props;
		const { loaded } = this.state;

		return loaded ? (
			<Card>
				<CreateEventPopup
					ref={this.eventPopup}
					type={status.statusFamily.DELAY}
					status={status.delayStatus}
					onSubmit={this.submit}
				/>
				<CardHeader>
					<i className="fa fa-edit" /> Delais de retractation
					<div className="card-actions">
						<Button
							onClick={() =>
								this.eventPopup.current
									// .getWrappedInstance()
									.handleOpen(this.props.data)
							}>
							<i className="fa fa-plus" /> Ajouter un évènement
						</Button>
					</div>
				</CardHeader>

				<Table responsive striped hover>
					<thead className="thead-light">
						<tr>
							<th />
							<th>Date</th>
							<th>Type</th>
							<th>Commentaire</th>
						</tr>
					</thead>
					<tbody>
						{this.state.events.map((el, index) => (
							<tr key={index}>
								<td>#{el.id}</td>
								<td>
									<Moment format="DD/MM/YYYY">{el.date}</Moment>
								</td>
								<td>{el.statusName}</td>
								<td>{el.comment}</td>
							</tr>
						))}
					</tbody>
				</Table>
			</Card>
		) : (
			<Loading />
		);
	}
}

Delay.propTypes = {
	data: PropTypes.shape({ '@id': PropTypes.string.isRequired }).isRequired,
	status: PropTypes.shape({ statusFamily: PropTypes.shape({}).isRequired }).isRequired,
	onReload: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ status: state.status });

export default connect(mapStateToProps)(Delay);
