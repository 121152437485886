import React from "react";
import classNames from "classnames";
import Label from "./Label";
import { useMemo } from "react";

const FieldSelect = ({
	valueField,
	labelField,
	nullValue,
	required = true,
	help,
	options,
	input,
	multiple = false,
	label,
	inline,
	meta: { touched, error },
}) => {
	if (!valueField) valueField = "value";
	if (!labelField) labelField = "name";

	return (
		<div className={classNames({ flex: inline }, "mb-2")}>
			<Label className={classNames({ "w-1/4": inline })}>{label}</Label>
			<div
				className={classNames({ "w-3/4": inline }, "flex", "flex-col")}
			>
				<div className="flex-1">
					<select
						defaultValue={input.value}
						type="select"
						multiple={multiple}
						{...input}
						className={classNames(
							{
								"is-invalid": touched && error,
							},
							"appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
						)}
					>
						{!required && <option value="">{nullValue}</option>}
						{options.map((option, index) => (
							<option value={option[valueField]} key={index}>
								{option[labelField]}
							</option>
						))}
					</select>
				</div>
				{touched && error && (
					<div className="text-xs text-red-600">{error}</div>
				)}
				{help && <div className="text-xs ">{help}</div>}
			</div>
		</div>
	);
};

export default FieldSelect;
