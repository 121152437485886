import React, { Component } from 'react';
import Loading from '@whatsonweb/loading';
import ApiConfig from '../../../../constants/ApiConfig';
import { SubmissionError } from 'redux-form';
import { toast } from 'react-toastify';

import { Card, CardHeader } from 'reactstrap';

import * as costumerService from '../../../../services/customer';

import FormEdit from './Form';

class Edit extends Component {
	state = {
		loaded: true,
	};

	submit = data =>
		costumerService
			.update(this.props.data.id, data)
			.then(() => {
				toast.success('Enregistrement réussi');
			})
			.catch(error => {
				throw new SubmissionError(error);
			});

	render() {
		return this.state.loaded ? (
			<Card>
				<CardHeader>
					<i className="fa fa-edit" /> Edition
					<div className="card-actions">

					</div>
				</CardHeader>

				<FormEdit onSubmit={this.submit} initialValues={this.props.data} />
			</Card>
		) : (
			<Loading />
		);
	}
}

export default Edit;
