import React from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import {
	FieldTxt,
	Form,
	Alert,
	FormActions,
	Button,
} from "/wh/components/WhComponent/index";

const InterventionTypeForm = ({ error, handleSubmit, submitting, toggle }) => {
	const onCancelClick = (event) => {
		event.preventDefault();
		toggle();
	};

	return (
		<Form onSubmit={handleSubmit}>
			<Field
				label="Label :"
				name="label"
				component={FieldTxt}
				type="text"
			/>

			{error && (
				<Alert color="danger">
					<div dangerouslySetInnerHTML={{ __html: error }} />
				</Alert>
			)}

			<FormActions>
				<Button color="transparent" onClick={onCancelClick}>
					Annuler
				</Button>
				<Button loading={submitting} onClick={handleSubmit}>
					Valider
				</Button>
			</FormActions>
		</Form>
	);
};

InterventionTypeForm.defaultProps = {
	error: "",
};

InterventionTypeForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	submitting: PropTypes.bool.isRequired,
	error: PropTypes.string,
};

export default reduxForm({ form: "InterventionTypeForm" })(
	InterventionTypeForm
);
