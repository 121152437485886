import React, { Component } from "react";
import { Page } from "../../components/WhComponent/index";

class Dashboard extends Component {
	render() {
		return <Page>Hello World</Page>;
	}
}

export default Dashboard;
