import React from "react";
import classNames from "classnames";
import Label from "./Label";
import { useMemo } from "react";
import Select from "react-select";

const FieldSelectMultiple = ({
	valueField,
	labelField,
	nullValue,
	required = true,
	help,
	options,
	input,
	label,
	inline,
	meta: { touched, error },
}) => {
	if (!valueField) valueField = "value";
	if (!labelField) labelField = "name";

	console.log({ input, options });

	const styles = useMemo(
		() => ({
			control: (base) => ({
				...base,
				border: "none",
				boxShadow: "none",
				/*         borderRadius: '0.125rem',
        backgroundColor: getColor().bgColor,
        color: getColor().txtColor,
        fontSize: '0.75rem',
        height: 'auto',
        minHeight: '2rem' */
			}),
			/* indicatorSeparator: base => ({
        display: 'none'
      }),
      dropdownIndicator: base => ({
        ...base,
        padding: 4,
        color: getColor().txtColor
      }),
      placeholder: base => ({ ...base, color: getColor().txtColor, whiteSpace: whitespace }),
      menu: base => ({ ...base, zIndex: 999, fontSize: '0.75rem' }),
      singleValue: base => ({ ...base, color: getColor().txtColor, fontWeight: 500 }),
      clearIndicator: base => ({ ...base, cursor: 'pointer' }) */
		}),
		[]
	);

	const value = useMemo(() => {
		console.log("???????");

		const valueToMap = input.value ? input.value : [];

		return valueToMap.map((item) => {
			const currentOption = options.find(
				(option) => option.value === item
			);

			return currentOption;
		});
	}, [options, input.value]);

	console.log({ value });

	const onChange = (newValue) => {
		input.onChange(newValue.map((item) => item.value));
	};

	return (
		<div className={classNames({ flex: inline }, "mb-2")}>
			<Label className={classNames({ "w-1/4": inline })}>{label}</Label>
			<div
				className={classNames({ "w-3/4": inline }, "flex", "flex-col")}
			>
				<div className="flex-1">
					<div className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
						<Select
							options={options}
							value={value}
							onChange={onChange}
							styles={styles}
							isMulti
						/>
					</div>
				</div>
				{touched && error && (
					<div className="text-xs text-red-600">{error}</div>
				)}
				{help && <div className="text-xs ">{help}</div>}
			</div>
		</div>
	);
};

export default FieldSelectMultiple;
