export const APIENUM = {
	INTERVENTION: "interventions",
	INTERVENTION_TYPES: "intervention_types",
	CUSTOMER: "prospect",
	CONTRACT: "contracts",
	FACTURATION: "bank_folders",
	QCM: "qcms",
	PRODUCT: "products",
	PRODUCT_FAMILY: "product_families",
	SECTOR: "sectors",
	USER: "users",
	TEAM: "teams",
	SALER: "salers",
	RAPPORT: "rapports",
	UPLOAD_FILE: "files/upload",
};
