import React, { Fragment } from 'react'
import moment from 'moment'
import { DatePicker as AntdDatePicker } from 'antd'
import "antd/dist/antd.css";

const DatePicker = (props) => {
  const { meta } = props
  const touched = meta ? meta.touched : false
  const error = meta ? meta.error : false
  const { input } = props

  const value = input.value !== '' ? moment(input.value) : null
  const onChange = input.onChange

  const onDateChange = (event) => {
    if (event) {
      onChange(event.format('YYYY-MM-DD HH:mm:ss'))
    } else {
      onChange(null)
    }
  }

  return (
    <Fragment>

      <AntdDatePicker
        format={props.dateFormat ? props.dateFormat : "DD/MM/YYYY HH:mm"}
        value={value}
        showTime={{ defaultValue: moment('00:00', 'HH:mm'), format: 'HH:mm' }}
        onChange={onDateChange}
        placeholder='Sélectionner une date'
      />
      { touched && error && 
        <div className='error'>
        {error}
      </div>}

    </Fragment>
  )
}

export default DatePicker