import React, { useState, useMemo } from "react";
import InterventionList from "/wh/modules/CustomersContractList/InterventionList";
import { APIENUM } from "/wh/services/ApiEnum";
import useSearch from "../../services/apiSearch/searchHook";
import { Route } from "react-router-dom";
import Product from "./InterventionComponent/Product";
import Contact from "/wh/modules/Intervention/InterventionComponent/Contact";
import Facturation from "/wh/modules/Intervention/InterventionComponent/Facturation";
import HisInterventions from "/wh/modules/Intervention/InterventionComponent/HistoriqueIntervention";
import SAV from "/wh/modules/Intervention/InterventionComponent/SAV";
import Document from "/wh/modules/Intervention/InterventionComponent/Documents";
import RapportView from "../Rapport/RapportView";
import { useSelector } from "react-redux";
import { SubmissionError } from "redux-form";
import ApiService from "/wh/services/apiSearch/apiSearch";
import { toast } from "react-toastify";
import {
	Card,
	CardHeader,
	Button,
	CardTitle,
	Value,
	H3,
	Select,
	Label,
} from "../../components/WhComponent/index";

const Contract = ({ match, history }) => {
	const { data, load } = useSearch(
		APIENUM.CONTRACT,
		[],
		true,
		match.params.bdcId
	);
	const { data: interventionTypesData } = useSearch(
		APIENUM.INTERVENTION_TYPES
	);
	const { data: commercialDatas } = useSearch(APIENUM.USER, [
		{ id: "roles", value: "ROLE_SALER" },
	]);

	const contract = useMemo(() => {
		if (!Array.isArray(data)) {
			return data;
		} else {
			return null;
		}
	}, [data]);

	const defaultFilterOption = { label: "Toutes", value: "" };

	const [filter, setFilter] = useState(defaultFilterOption);
	const status = useSelector((state) => state.status);

	const interventionTypesOptions = useMemo(() => {
		const typesOptions = interventionTypesData.map((interventionType) => ({
			value: interventionType["@id"],
			label: interventionType.label,
		}));

		return [defaultFilterOption, ...typesOptions];
	}, [interventionTypesData]);

	const products = useMemo(() => {
		if (contract !== null) {
			return contract.products;
		} else {
			return [];
		}
	}, [contract]);

	const updateFilter = (newType) => {
		const updatedFilter = newType;
		setFilter(updatedFilter);
	};

	//@todo move to contact for error handling
	const update = (data) => {
		const contractService = new ApiService(`/api/${APIENUM.CONTRACT}`);
		return contractService
			.update({ id: match.params.bdcId, ...data })
			.then((d) => {
				load();
				toast.success("Enregistrement réussi");
			})
			.catch((e) => {
				console.error(e);
				toast.error("Une erreur est survenue");
				throw new SubmissionError(e);
			});
	};

	if (!contract) return <div></div>;

	return (
		<div>
			<Card small>
				<CardHeader>
					<CardTitle>
						<H3>Contrat n° {contract.id}</H3>
					</CardTitle>
				</CardHeader>
				{/*
				<div className="flex">
					<div className="w-1/2">
						<div className="flex">
							<Label className="w-1/3">Date</Label>
							<Value className="w-2/3">: </Value>
						</div>
						<div className="flex">
							<Label className="w-1/3">Senior</Label>
							<Value className="w-2/3">: </Value>
						</div>
						<div className="flex">
							<Label className="w-1/3">Eco Habitant</Label>
							<Value className="w-2/3">: </Value>
						</div>
					</div>

					<div className="w-1/2">
						<div className="flex">
							<Label className="w-1/3">Montant</Label>
							<Value className="w-2/3">: </Value>
						</div>
					</div>
				</div>
				*/}
			</Card>

			<div className="flex my-5">
				<Button
					block
					color="white"
					className="mr-1"
					to={`${match.url}/interventions`}
				>
					Interventions
				</Button>
				<Button
					block
					color="white"
					className="mr-1"
					to={`${match.url}/products`}
				>
					Produits
				</Button>
				<Button
					block
					color="white"
					className="mr-1"
					to={`${match.url}/bills`}
				>
					Facturation
				</Button>
				<Button
					block
					color="white"
					className="mr-1"
					to={`${match.url}/documents`}
				>
					Documents
				</Button>
				<Button
					block
					color="white"
					className="mr-1"
					to={`${match.url}/history`}
				>
					Historique
				</Button>
				<Button
					block
					color="white"
					className="mr-1"
					to={`${match.url}/sav`}
				>
					Sav
				</Button>
				<Button
					block
					color="white"
					className="mr-1"
					to={`${match.url}/contacts`}
				>
					Contacts
				</Button>
			</div>

			<Route
				path={`${match.path}/interventions`}
				exact
				name="Interventions"
				component={(props) => (
					<Card small>
						<CardHeader>
							<CardTitle>
								<H3>Liste des interventions</H3>
							</CardTitle>
						</CardHeader>

						<div>
							<Label>Filtre selon le type d'intervention: </Label>
							<Select
								value={filter}
								onChange={updateFilter}
								options={interventionTypesOptions}
								isSearchable={false}
							/>
						</div>

						<InterventionList
							endPoint={APIENUM.INTERVENTION}
							filter={[
								{
									id: "contract.id",
									value: match.params.bdcId,
								},
							].concat(
								filter.value !== ""
									? [{ id: "type", value: filter.value }]
									: []
							)}
							match={match}
							bdcId={match.params.bdcId}
							filterable={false}
							history={history}
							products={products}
							withCreate
						/>
					</Card>
				)}
			/>

			<Route
				path={`${match.path}/rapports/:rapportId`}
				exact
				name="Rapport"
				component={(props) => <RapportView {...props} />}
			/>

			<Route
				path={`${match.path}/products`}
				exact
				name="Produits"
				component={(props) => (
					<Product
						products={contract.products ? contract.products : []}
						infos={
							contract.infos ? JSON.parse(contract.infos) : null
						}
					/>
				)}
			/>

			<Route
				path={`${match.path}/bills`}
				exact
				name="Factures"
				component={(props) => (
					<Facturation
						reload={load}
						amount={contract.amount}
						bankFolder={contract.bankFolder}
						infos={
							contract.infos ? JSON.parse(contract.infos) : null
						}
					/>
				)}
			/>

			<Route
				path={`${match.path}/documents`}
				exact
				name="documents"
				component={(props) => (
					<Document contract={contract} updateContract={update} />
				)}
			/>

			<Route
				path={`${match.path}/history`}
				exact
				name="historique"
				component={(props) => (
					<HisInterventions contractId={contract.id} match={match} />
				)}
			/>

			<Route
				path={`${match.path}/sav`}
				exact
				name="sav"
				component={(props) => (
					<SAV sav={contract.sav} onUpdate={update} />
				)}
			/>

			<Route
				path={`${match.path}/contacts`}
				exact
				name="contacts"
				component={(props) => (
					<Contact
						commercial={
							contract.senior ? contract.senior.user : null
						}
						eco_habitant={contract.ecoHabitant}
						commercialDatas={commercialDatas}
						onUpdate={update}
					/>
				)}
			/>
			{/*
			<Card small>
				<CardHeader>
					<CardTitle>
						<H3>Données contrat brut</H3>
					</CardTitle>
				</CardHeader>
				<pre>
					{contract &&
						contract.infos &&
						JSON.stringify(JSON.parse(contract.infos), null, 4)}
				</pre>
			</Card>
			 */}
		</div>
	);
};

export default Contract;
