import React, { Component } from "react";
import { connect } from "react-redux";

class SidebarHeader extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		if (this.props.user) {
			return (
				<div className="sidebar-header">
					<a href="/" className="block px-10 h-24 flex items-center">
						<img src="/img/logo.png" />
					</a>
					<div>
						<strong>{this.props.user.name}</strong>
					</div>
					<div className="">
						<small>{this.props.user.job}</small>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
});

const mapDispatchToProps = (dispatch) => ({});

module.exports = connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
